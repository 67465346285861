import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { resetPassword } from '../../api/userFunctions';
import { getTitle } from '../../helper/general';

const ResetPassword = () => {
  const history = useHistory();
  const { search } = useLocation();
  const [firstPasswordEntry, setFirstPasswordEntry] = useState('');
  const [secondPasswordEntry, setSecondPasswordEntry] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handlePasswordReset = async () => {
    document.getElementById('passwords-do-not-match').classList.add('d-none');
    document.getElementById('password-error').classList.add('d-none');
    const token = search.split('=')[1];
    if (firstPasswordEntry === secondPasswordEntry) {
      const response = await resetPassword(token, firstPasswordEntry);
      if (response.status) {
        history.push('/reset-password-success');
      } else {
        setErrorMessage(response.password);
        document.getElementById('password-error').classList.remove('d-none');
      }
    } else document.getElementById('passwords-do-not-match').classList.remove('d-none');
  };

  return (
    <div className='container'>
      <div className='row justify-content-center'>
        <div className='col-xl-10 col-lg-12 col-md-9'>
          <div className='card o-hidden border-0 shadow-lg my-5'>
            <div className='card-body p-5'>
              <div className='row'>
                <div className='col-lg-6 d-none d-lg-block bg-login-image'></div>
                <div className='col-lg-6 mb-4'>
                  <div className='p-5'>
                    <div className='text-center'>
                      <h1 className='h4  mb-4 text-ibis'>{getTitle()}</h1>
                    </div>
                    <form
                      className='user'
                      onSubmit={(e) => {
                        e.preventDefault();
                        handlePasswordReset();
                      }}
                    >
                      <div className='form-group'>
                        <input
                          type='password'
                          className='form-control form-control-user'
                          id='login_password_one'
                          placeholder='New password'
                          value={firstPasswordEntry}
                          onChange={(e) => {
                            setFirstPasswordEntry(e.target.value);
                          }}
                        />
                      </div>
                      <div className='form-group'>
                        <input
                          type='password'
                          className='form-control form-control-user'
                          id='login_password_two'
                          placeholder='Re-enter new password'
                          value={secondPasswordEntry}
                          onChange={(e) => {
                            setSecondPasswordEntry(e.target.value);
                          }}
                        />
                      </div>

                      <button className='btn btn-ibis btn-user btn-block' style={{ borderRadius: '0.35rem' }} type='submit'>
                        Reset Your Password
                      </button>
                      <div className='alert alert-warning d-none login-alert' id='password-error' role='alert'>
                        {errorMessage}
                      </div>
                      <div className='alert alert-warning d-none login-alert' id='passwords-do-not-match' role='alert'>
                        Passwords do not match
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
