import { useState } from 'react';

const useSort = (initialSortField, initialSortDirection) => {
  const [sortField, setSortField] = useState(initialSortField);
  const [sortDirection, setSortDirection] = useState(initialSortDirection);

  const handleSort = (column, direction) => {
    setSortField(column.sortField);
    setSortDirection(direction);
  };

  const resetSort = () => {
    setSortField(initialSortField);
    setSortDirection(initialSortDirection);
  };

  return {
    sortField,
    sortDirection,
    handleSort,
    resetSort,
  };
};

export default useSort;
