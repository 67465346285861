import { IbisPatient } from '../helper/IbisUser';
import { postData } from './apiFunctions';
import { fetchObjects, detailObject, populateFieldsValues } from './crudFunctions';

const getApiUrl = () => {
  return '/api/password_reset/';
};

const fetchUsers = async (ibisUserType) => {
  return await fetchObjects(ibisUserType.getApiEndpoint());
};

export const getUsers = async (ibisUserType) => {
  const dataFromServer = await fetchUsers(ibisUserType);
  const usersRecords = dataFromServer.results || [];

  return usersRecords;
};

export const getDashboard = async () => {
  const dataFromServer = await fetchObjects('/api/administrators/dashboard/');
  const usersRecords = dataFromServer || {
    patients: 0,
    opticians: 0,
    appointments: 0,
  };

  return usersRecords;
};

export const editUser = async (ibisUserType, userId) => {
  Array.from(document.querySelectorAll('.is-invalid')).forEach((el) => el.classList.remove('is-invalid'));
  const res = await detailObject(ibisUserType.getApiEndpoint(userId), 'GET');
  const userObject = await res.json();
  populateFieldsValues(userObject);
  return userObject;
};

export const sendResetPasswordEmail = async (email) => {
  const response = await postData(getApiUrl(), { email: email }, true);
  return response;
};

export const resetPassword = async (token, password) => {
  const response = await postData(`${getApiUrl()}confirm/`, { token: token, password: password }, true);
  return response;
};

export const getPatient = async (userId) => {
  const ibisUserType = new IbisPatient();
  Array.from(document.querySelectorAll('.is-invalid')).forEach((el) => el.classList.remove('is-invalid'));
  const res = await detailObject(ibisUserType.getApiEndpoint(userId), 'GET');
  const userObject = await res.json();
  return userObject;
};
