const ColorSelector = ({ value, onChange, title }) => {
  return (
    <div className='card-body'>
      <div className='card shadow-sm'>
        <div className='list-group-item border border-dark'>
          <div className='d-flex justify-content-between'>
            <div className='d-flex flex-column'>
              <h4 className='m-0 text-capitalize'>{title}</h4>
              <p className='text-muted'>{`Click the coloured box to select a new ${title} colour.`}</p>
            </div>

            <div className='d-flex' style={{ gap: '2rem' }}>
              <div className='d-flex flex-column'>
                <p className='m-0'>Select</p>
                <input
                  type='color'
                  value={value}
                  onChange={onChange}
                  style={{ padding: 0, border: 'none', outline: 'none', height: '50px', width: '100px', verticalAlign: 'middle' }}
                />
              </div>
              <div className='d-flex flex-column'>
                <p className='m-0'>Preview</p>
                <div className='d-flex align-items-center' style={{ height: '50px' }}>
                  <div
                    className='btn btn-ibis'
                    style={{
                      backgroundColor: value,
                      borderColor: value,
                    }}
                  >
                    Button
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ColorSelector;
