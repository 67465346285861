import { useEffect, useState } from 'react';

const VisualFieldTestResult = ({ results, testInfo, print }) => {
  const [generatedMatrix, setGeneratedMatrix] = useState();

  const getPrintDotResponse = (color) => {
    switch (color) {
      case 'white':
      case '#F2E6E6':
      case 'grey':
        return '';
      case '#6DC89D':
        return '\u2713';
      case 'black':
        return '\u25CF';
      case '#870000':
        return 'x';
    }
    return color;
  };

  const dotElement = (color) => {
    return (
      <div className='d-flex justify-content-center align-items-center' style={{ height: '40px' }}>
        {print ? (
          getPrintDotResponse(color)
        ) : (
          <div style={{ display: 'flex', borderRadius: '50%', height: '20px', width: '20px', backgroundColor: color ? color : 'grey' }} />
        )}
      </div>
    );
  };

  const generateMatrix = (resultsData, title) => {
    let className = 'd-flex justify-content-center p-2 my-2';
    if (!print) {
      className = className + ' bg-primary-ibis';
    }
    return (
      <div style={{ width: '100%' }}>
        <div className={className}>
          {!print ? (
            <h5>
              <strong>{title}</strong>
            </h5>
          ) : (
            title
          )}
        </div>
        <div className='d-flex p-2'>
          <div className='d-flex flex-column'>
            <div className='d-flex flex-column' style={!print ? { marginTop: '10px' } : {}}>
              <table>
                <tbody>
                  <tr>
                    <td className='one'>
                      <strong>Fixation:</strong>
                    </td>
                    <td>{typeof resultsData.fixationType === 'object' ? resultsData.fixationType.label : resultsData.fixationType}</td>
                  </tr>
                  <tr>
                    <td className='one'>
                      <strong>Response:</strong>
                    </td>
                    <td>{resultsData.responseType}</td>
                  </tr>
                  <tr>
                    <td className='one'>
                      <strong>Greyscale:</strong>
                    </td>
                    <td>{resultsData.contrast}</td>
                  </tr>
                </tbody>
              </table>
              <table>
                <tbody>
                  <tr>
                    <td className='two'>
                      <strong>Target duration:</strong>
                    </td>
                    <td>{resultsData.targetDuration / 1000}s </td>
                  </tr>
                  <tr>
                    <td className='one'>
                      <strong>Confidence level:</strong>
                    </td>
                    <td>{resultsData.testInfo ? resultsData.testInfo.confidenceLevel : 0}%</td>
                  </tr>
                  <tr>
                    <td className='one'>
                      <strong>Seen count:</strong>
                    </td>
                    <td>{resultsData.testInfo ? resultsData.testInfo.seenDots : 0}</td>
                  </tr>
                  <tr>
                    <td className='one'>
                      <strong>Not seen count:</strong>
                    </td>
                    <td>{resultsData.testInfo ? resultsData.testInfo.notSeenDots : 0}</td>
                  </tr>
                  <tr>
                    <td className='one'>
                      <strong>Not seen once count:</strong>
                    </td>
                    <td>{resultsData.testInfo ? resultsData.testInfo.notSeenOnceDots : 0}</td>
                  </tr>
                  <tr>
                    <td className='one'>
                      <strong>Patient distance:</strong>
                    </td>
                    <td>{resultsData.testInfo ? Math.round(resultsData.testInfo.patientDistance) : 0}mm</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className='d-flex flex-column' style={{ width: '450px' }}>
            <div className='d-flex ' style={{ position: 'relative', width: '100%' }}>
              <table style={{ borderRight: '2px solid black', borderBottom: '2px solid black', width: '50%' }}>
                <tbody>
                  {resultsData.matrix[0].map((column) => (
                    <tr>
                      {column.map((x) => (
                        <td>{dotElement(x.color)}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
              <table style={{ borderLeft: '2px solid black', borderBottom: '2px solid black', width: '50%' }}>
                <tbody>
                  {resultsData.matrix[1].map((column) => (
                    <tr>
                      {column.map((x) => (
                        <td>{dotElement(x.color)}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className='d-flex justify-content-center align-items-center ' style={{ position: 'relative', width: '100%' }}>
              <table style={{ borderRight: '2px solid black', borderTop: '2px solid black', width: '50%' }}>
                <tbody>
                  {resultsData.matrix[2].map((column) => (
                    <tr>
                      {column.map((x) => (
                        <td>{dotElement(x.color)}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
              <table style={{ borderLeft: '2px solid black', borderTop: '2px solid black', width: '50%' }}>
                <tbody>
                  {resultsData.matrix[3].map((column) => (
                    <tr>
                      {column.map((x) => (
                        <td>{dotElement(x.color)}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const displayTestResults = () => {
    const resultsMatrix = [];
    let title = null;
    const resultsData = results;
    if (resultsData.bothEyes && resultsData.bothEyes.matrix) {
      title = 'Binocular';
      resultsMatrix.push(generateMatrix(resultsData.bothEyes, title));
    } else {
      if (resultsData.rightEye && resultsData.rightEye.matrix) {
        title = 'Right Eye';
        resultsMatrix.push(generateMatrix(resultsData.rightEye, title));
      }

      if (resultsData.leftEye && resultsData.leftEye.matrix) {
        title = 'Left Eye';
        resultsMatrix.push(generateMatrix(resultsData.leftEye, title));
      }
    }

    return (
      <div className='d-flex flex-column p-1 m-2' style={{ width: '100%' }}>
        <table>
          <tbody>
            {!print ? (
              <tr>
                <td className='two'>
                  <strong>Appointment date & time:</strong>
                </td>
                <td>{testInfo.appointment_date}</td>
              </tr>
            ) : (
              ''
            )}

            <tr>
              <td className='two'>
                <strong>Patient name:</strong>
              </td>
              <td>{testInfo.patient}</td>
            </tr>
            <tr>
              <td className='two'>
                <strong>Clinician name:</strong>
              </td>
              <td>{testInfo.optician}</td>
            </tr>
            <tr>
              <td className='two'>
                <strong>Submission date & time:</strong>
              </td>
              <td>{testInfo.submission_date}</td>
            </tr>
          </tbody>
        </table>
        {resultsMatrix}
      </div>
    );
  };

  useEffect(() => {
    if (results) {
      setGeneratedMatrix(displayTestResults());
    }
  }, [results]);

  return <>{generatedMatrix}</>;

  //   return (
  //     <div className='d-flex flex-column' style={{ minWidth: '350px', margin: '10px' }}>
  //       <div
  //         style={{ width: '100%', height: '50px', backgroundColor: 'var(--primary-color)', color: 'white' }}
  //         className='d-flex justify-content-center align-items-center'
  //       >
  //         <span>fsfdfsd</span>
  //       </div>
  //       <div style={{ width: '100%', height: '80px' }} className='d-flex justify-content-center align-items-center'>
  //         <span style={{ fontSize: '18px', padding: '15px' }}></span>
  //       </div>
  //       <div
  //         style={{ backgroundColor: '#c1eded', width: '100%', height: '110px' }}
  //         className='d-flex flex-column justify-content-center align-items-center'
  //       >
  //         <span style={{ marginBottom: '10px', fontSize: '18px', padding: '15px' }}></span>
  //       </div>
  //     </div>
  //   );
};

export default VisualFieldTestResult;
