import { useEffect, useState } from 'react';
import { getDashboard } from '../api/userFunctions';
import { Link } from 'react-router-dom';
import { useCompany } from '../context/companyContext';

const AdminDashboard = () => {
  const { currentCompany } = useCompany();
  const [dashboardData, setDashboardData] = useState({
    patients: null,
    opticians: null,
    appointments: null,
    recheck_sessions: null,
  });

  useEffect(() => {
    getDashboard().then((response) => setDashboardData(response));
  }, []);

  return (
    <div className='container-fluid'>
      <div className='d-sm-flex align-items-center justify-content-between mb-4'>
        <h1 className='h3 mb-0 text-gray-800'>Dashboard</h1>
      </div>
      <div className='row'>
        <InfoCard title={'patients'} data={dashboardData.patients} icon={'user'} />
        {currentCompany.engage_enabled && <InfoCard title={'clinicians'} data={dashboardData.opticians} icon={'user-md'} />}
        {currentCompany.engage_enabled && <InfoCard title={'appointments'} data={dashboardData.appointments} icon={'calendar-check'} />}
        {currentCompany.recheck_enabled && <InfoCard title={'recheck sessions'} data={dashboardData.recheck_sessions} icon={'calendar-alt'} />}
      </div>
    </div>
  );
};

export default AdminDashboard;

const InfoCard = ({ title, data, icon }) => {
  const [hovered, setHovered] = useState(false);
  const link = title === 'clinicians' ? 'opticians' : title.includes('recheck') ? 'recheck/sessions' : title;
  return (
    <div className='col-xl-3 mb-4'>
      <div className={`card border-left-ibis h-100 py-2 ${hovered ? 'shadow-lg' : 'shadow'}`} style={{ transform: `scale(${hovered ? 1.05 : 1})` }}>
        <Link className='text-decoration-none' to={link} onMouseOver={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
          <div className='card-body'>
            <div className='row no-gutters align-items-center'>
              <div className='col mr-2'>
                <div className='text-xs font-weight-bold text-ibis text-uppercase mb-1'>{title}</div>
                <div className='h5 mb-0 font-weight-bold text-gray-800'>{data ?? 'Loading...'}</div>
              </div>
              <div className='col-auto'>
                <i className={`fas fa-${icon} fa-2x text-gray-300`} />
              </div>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};
