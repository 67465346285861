import { useEffect, useState, useReducer } from 'react';
import './EyeMotilityStyles.css';

const initialState = { translateX: 0, translateY: 0, prevMouseX: 0, prevMouseY: 0 };

const reducer = (currentPosition, action) => {
  switch (action.type) {
    case 'beginDrag':
      return { ...currentPosition, prevMouseX: action.clientX, prevMouseY: action.clientY };
    case 'executeDrag':
      const deltaMouseX = action.clientX - currentPosition.prevMouseX;
      const deltaMouseY = action.clientY - currentPosition.prevMouseY;
      return {
        ...currentPosition,
        translateX: currentPosition.translateX + deltaMouseX,
        translateY: currentPosition.translateY + deltaMouseY,
        prevMouseX: action.clientX,
        prevMouseY: action.clientY,
      };
    default:
      return currentPosition;
  }
};

const useImageDrag = () => {
  const [currentPosition, moveImage] = useReducer(reducer, initialState);

  const onMouseMove = (event) => {
    event.preventDefault();
    moveImage({ type: 'executeDrag', clientX: event.clientX, clientY: event.clientY });
  };
  const onMouseUp = () => {
    window.removeEventListener('mouseup', onMouseUp);
    window.removeEventListener('mousemove', onMouseMove);
  };

  const beginDrag = (event) => {
    moveImage({ type: 'beginDrag', clientX: event.clientX, clientY: event.clientY });
    window.addEventListener('mouseup', onMouseUp);
    window.addEventListener('mousemove', onMouseMove);
  };

  return { ...currentPosition, beginDrag };
};

const ZoomedImageContainer = ({ children }) => {
  return <div style={{ width: '300px', height: '200px', margin: '1rem 2rem', overflow: 'hidden', backgroundColor: '#C1EDED' }}>{children}</div>;
};

const ImageZoomSlider = ({ value, onChange, id }) => {
  return <input type='range' className='ibis-slider' id={id} value={value} min={1} max={3} step={0.1} onChange={onChange} />;
};

const EyeMotilityResults = ({ showImages, showVideo, testData, oldImages }) => {
  const [imageZoomValues, setImageZoomValues] = useState({ img0: 1.0, img1: 1.0, img2: 1.0, img3: 1.0 });
  const [imageDragValues, setImageDragValues] = useState({ img0: { x: 0, y: 0 }, img1: { x: 0, y: 0 }, img2: { x: 0, y: 0 }, img3: { x: 0, y: 0 } });
  const [imageArray, setImageArray] = useState([]);
  const [usingOldImages, setUsingOldImages] = useState(false);
  const [imageBeingDragged, setImageBeingDragged] = useState(null);
  const [motilityVideo, setMotilityVideo] = useState({});
  const { translateX, translateY, beginDrag } = useImageDrag();

  const getImgAltTextFromCornerNumber = (number) => {
    switch (number) {
      case 0:
        return 'top-left';
      case 1:
        return 'top-right';
      case 2:
        return 'bottom-left';
      case 3:
        return 'bottom-right';
      default:
        break;
    }
  };

  useEffect(() => {
    const cornerFixationImages = testData.filter((file) => file.type.includes('image'));
    const eyeMotilityVideo = testData.find((file) => file.type.includes('video'));

    const desiredIndexOrder = [1, 0, 3, 2];

    if (cornerFixationImages) {
      const sortedArray = desiredIndexOrder.map((i) => {
        return cornerFixationImages[i];
      });

      setImageArray(sortedArray);
      setMotilityVideo(eyeMotilityVideo);
    }
  }, [testData]);

  useEffect(() => {
    if (oldImages?.length) {
      setImageArray(oldImages);
      setUsingOldImages(true);
    }
  }, [oldImages]);

  useEffect(() => {
    let finalX = translateX;
    let finalY = translateY;
    const maximumX = 150;
    const maximumY = 100;
    if (translateX > maximumX) finalX = maximumX;
    if (translateX < 0 && translateX < maximumX * -1) finalX = maximumX * -1;
    if (translateY > maximumY) finalY = maximumY;
    if (translateY < 0 && translateY < maximumX * -1) finalY = maximumY * -1;
    setImageDragValues((existingValues) => {
      return { ...existingValues, [imageBeingDragged]: { x: finalX, y: finalY } };
    });
  }, [imageBeingDragged, translateX, translateY]);

  return (
    <div className='d-flex align-items-center justify-content-center' style={{ height: '500px', width: '730px' }}>
      {showImages && (
        <div className='d-flex align-items-center justify-content-center' style={{ height: '400px' }}>
          {imageArray?.length ? (
            <div className='d-flex align-items-center justify-content-center' style={{ flexWrap: 'wrap' }}>
              {[0, 1, 2, 3].map((cornerNumber) => {
                return (
                  <div className='d-flex align-items-center justify-content-center flex-column' key={`eye-motility-column-${cornerNumber}`}>
                    <ZoomedImageContainer>
                      <img
                        onMouseDown={(e) => {
                          setImageBeingDragged(`img${cornerNumber}`);
                          beginDrag(e);
                        }}
                        src={usingOldImages ? imageArray[cornerNumber] : imageArray[cornerNumber]?.url}
                        alt={getImgAltTextFromCornerNumber(cornerNumber) + '-corner'}
                        id={`eye-motility-img-${cornerNumber}`}
                        width='100%'
                        style={{
                          cursor: 'pointer',
                          transition: 'transform 100ms',
                          transform: `scale(${imageZoomValues[`img${cornerNumber}`]}) ${`translate(${imageDragValues[`img${cornerNumber}`].x}px, ${
                            imageDragValues[`img${cornerNumber}`].y
                          }px)`}`,
                        }}
                      />
                    </ZoomedImageContainer>
                    <ImageZoomSlider
                      id={`eye-motility-slider-${cornerNumber}`}
                      value={imageZoomValues[`img${cornerNumber}`]}
                      onChange={(e) => {
                        setImageZoomValues({ ...imageZoomValues, [`img${cornerNumber}`]: e.target.value });
                      }}
                    />
                  </div>
                );
              })}
            </div>
          ) : (
            <span>No images could be found.</span>
          )}
        </div>
      )}
      {showVideo && (
        <div className='d-flex align-items-center justify-content-center flex-column' style={{ height: '100%', width: '100%' }}>
          {motilityVideo ? (
            <video height='80%' width='100%' controls>
              <source src={motilityVideo.url} type='video/mp4' />
            </video>
          ) : (
            <span>Video recording cannot be displayed.</span>
          )}
        </div>
      )}
    </div>
  );
};

export default EyeMotilityResults;
