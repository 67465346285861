import { useState } from 'react';
import { useQuestionnaire } from '../../context/questionnaireContext';

const QuestionPathForm = ({ questionnaireQuestions, originQuestionnaireQuestion, updateQuestionsOrder }) => {
  const { addQuestionPath, deleteQuestionPath } = useQuestionnaire();
  const [destinationQuestionnaireQuestion, setDestinationQuestionnaireQuestion] = useState('');
  const [destinationQuestionnaireQuestionTrue, setDestinationQuestionnaireQuestionTrue] = useState('');
  const [destinationQuestionnaireQuestionFalse, setDestinationQuestionnaireQuestionFalse] = useState('');

  const textQuestionPathObject = () => {
    let pathElement = document.getElementById('field_destination_questionnaire_text_question');
    let existentDestinationQuestionnaireQuestion = null;
    if (pathElement) {
      existentDestinationQuestionnaireQuestion = pathElement.value;
    }
    return {
      origin_questionnaire_question: originQuestionnaireQuestion.id,
      destination_questionnaire_question: existentDestinationQuestionnaireQuestion
        ? existentDestinationQuestionnaireQuestion
        : destinationQuestionnaireQuestion,
    };
  };

  const booleanQuestionPathObject = (answer) => {
    let booleanDestinationQuestionnaireQuestion;
    let pathElement = document.getElementById('field_destination_questionnaire_boolean_false_question');
    if (pathElement) {
      booleanDestinationQuestionnaireQuestion = pathElement.value;
    }

    if (answer === true) {
      pathElement = document.getElementById('field_destination_questionnaire_boolean_true_question');
      if (pathElement) {
        booleanDestinationQuestionnaireQuestion = pathElement.value;
      }
    }

    return {
      origin_questionnaire_question: originQuestionnaireQuestion.id,
      destination_questionnaire_question: booleanDestinationQuestionnaireQuestion,
      answer: answer,
    };
  };

  const addSaveQuestionPath = async () => {
    let buttonElement = document.getElementById('saveButton');
    buttonElement.disabled = true;
    document.getElementById('modal_general_error').classList.add('d-none');
    await deleteQuestionPath(originQuestionnaireQuestion);
    if (originQuestionnaireQuestion.question_type === 'boolean') {
      if (booleanQuestionPathObject(true).destination_questionnaire_question !== '') {
        await addQuestionPath(booleanQuestionPathObject(true), 'boolean');
      }

      if (booleanQuestionPathObject(false).destination_questionnaire_question !== '') {
        await addQuestionPath(booleanQuestionPathObject(false), 'boolean');
      }
    } else if (originQuestionnaireQuestion.question_type === 'text') {
      if (textQuestionPathObject().destination_questionnaire_question !== '') {
        await addQuestionPath(textQuestionPathObject(), 'text');
      }
    } else if (originQuestionnaireQuestion.question_type === 'checkbox') {
      if (textQuestionPathObject().destination_questionnaire_question !== '') {
        await addQuestionPath(textQuestionPathObject(), 'checkbox');
      }
    }

    const questionsOrderResponse = await updateQuestionsOrder(originQuestionnaireQuestion.questionnaire);

    if (questionsOrderResponse.status === 200) {
      document.querySelector('#editQuestionPathModal .close').click();
    }
    buttonElement.disabled = false;
  };

  const getFieldOptions = () => {
    let fieldOption = (
      <div className='col mb-3 mb-sm-0'>
        <select
          className='form-control form-control-question-path form-select'
          aria-label='Select a question'
          id='field_destination_questionnaire_text_question'
          onChange={(e) => setDestinationQuestionnaireQuestion(e.target.value)}
        >
          <option value=''>Select a question</option>
          {questionnaireQuestions?.length > 0
            ? questionnaireQuestions
                .filter(
                  (questionnaire_question) => questionnaire_question.id !== originQuestionnaireQuestion.id && questionnaire_question.initial === false
                )
                .map((questionnaire_question) => (
                  <option id={`available_question_${questionnaire_question.id}`} value={questionnaire_question.id}>
                    {questionnaire_question.question_text}
                  </option>
                ))
            : ''}
        </select>
        <div id='validationfield_destination_questionnaire_question' className='invalid-feedback'>
          <span id='invalid_destination_questionnaire_question_message'></span>
        </div>
      </div>
    );

    if (originQuestionnaireQuestion !== undefined && originQuestionnaireQuestion.question_type === 'boolean') {
      fieldOption = (
        <>
          <div className='col mb-3 mb-sm-0 col-6'>
            <div className='card shadow mb-4'>
              <div className='card-header py-3'>
                <h6 className='m-0 font-weight-bold text-ibis'>Next question if answer is "Yes"</h6>
              </div>
              <div className='card-body'>
                <select
                  className='form-control form-control-question-path form-select'
                  aria-label='Select a question'
                  id='field_destination_questionnaire_boolean_true_question'
                  onChange={(e) => setDestinationQuestionnaireQuestionTrue(e.target.value)}
                >
                  <option value=''>Select a question</option>
                  {questionnaireQuestions.length > 0
                    ? questionnaireQuestions
                        .filter(
                          (questionnaire_question) =>
                            questionnaire_question.id !== originQuestionnaireQuestion.id && questionnaire_question.initial === false
                        )
                        .map((questionnaire_question) => (
                          <option id={`boolean_true_question_option_${questionnaire_question.id}`} value={questionnaire_question.id}>
                            {questionnaire_question.question_text}
                          </option>
                        ))
                    : ''}
                </select>
                <div id='validationfield_destination_questionnaire_boolean_true_question' className='invalid-feedback'>
                  <span id='invalid_destination_questionnaire_boolean_true_question_message'></span>
                </div>
              </div>
            </div>
          </div>
          <div className='col mb-3 mb-sm-0 col-6'>
            <div className='card shadow mb-4'>
              <div className='card-header py-3'>
                <h6 className='m-0 font-weight-bold text-ibis'>Next question if answer is "No"</h6>
              </div>
              <div className='card-body'>
                <select
                  className='form-control form-control-question-path form-select'
                  aria-label='Select a question'
                  id='field_destination_questionnaire_boolean_false_question'
                  onChange={(e) => setDestinationQuestionnaireQuestionFalse(e.target.value)}
                >
                  <option value=''>Select a question</option>
                  {questionnaireQuestions?.length > 0
                    ? questionnaireQuestions
                        .filter(
                          (questionnaire_question) =>
                            questionnaire_question.id !== originQuestionnaireQuestion.id && questionnaire_question.initial === false
                        )
                        .map((questionnaire_question) => (
                          <option id={`boolean_false_question_option_${questionnaire_question.id}`} value={questionnaire_question.id}>
                            {questionnaire_question.question_text}
                          </option>
                        ))
                    : ''}
                </select>
                <div id='validationfield_destination_questionnaire_boolean_false_question' className='invalid-feedback'>
                  <span id='invalid_destination_questionnaire_boolean_false_question_message'></span>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }

    return fieldOption;
  };

  return (
    <div className='modal fade' id='editQuestionPathModal' tabIndex='-1' role='dialog' aria-labelledby='editQuestionPathModal' aria-hidden='true'>
      <div className='modal-dialog modal-lg' role='document'>
        <div className='modal-content'>
          <div className='modal-header bg-primary-ibis'>
            <h5 className='modal-title' id='editQuestionPathModalLabel'>
              Set the next questions according to the response
            </h5>
            <button className='close' type='button' data-dismiss='modal' aria-label='Close'>
              <span aria-hidden='true'>&times;</span>
            </button>
          </div>
          <div className='modal-body'>
            <div className='alert alert-danger d-none' role='alert' id='modal_general_error'>
              <span id='modal_general_error_message'></span>
            </div>
            <p>
              <strong>Question:</strong> {originQuestionnaireQuestion.question_text}
            </p>
            <form className='' name='question-path-form'>
              <div className='form-group row'>{getFieldOptions()}</div>
            </form>
          </div>
          <div className='modal-footer'>
            <button className='btn btn-secondary' type='button' data-dismiss='modal'>
              Cancel
            </button>
            <button
              className='btn btn-ibis'
              type='button'
              onClick={() => {
                addSaveQuestionPath();
              }}
              id='saveButton'
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestionPathForm;
