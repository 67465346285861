import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { LineChart, Line, Legend, Tooltip, CartesianGrid, XAxis, YAxis } from 'recharts';

const StyledTable = styled.table`
  th {
    padding: 10px;
    background-color: var(--primary-color);
    color: #fff;
    font-size: 1.25rem;
  }

  td {
    text-align: center;
    font-size: 1.25rem;
    padding: 10px;
  }

  tbody tr:nth-of-type(even) {
    background-color: lightgrey;
  }
`;

const ContrastSensitivityResults = ({ results }) => {
  const [showGraph, setShowGraph] = useState(false);
  const [graphResults, setGraphResults] = useState([]);
  const [offerGraphOption, setOfferGraphOption] = useState(false);

  useEffect(() => {
    let parsedResults = [];
    let filteredResults = [];
    results?.forEach((result) => {
      const { logmarTested, leftEye, rightEye, bothEyes } = result;
      parsedResults.push({
        name: logmarTested,
        Left: parseFloat(leftEye?.toFixed(2)),
        Right: parseFloat(rightEye?.toFixed(2)),
        Binocular: parseFloat(bothEyes?.toFixed(2)),
      });
    });

    parsedResults.forEach((result) => {
      filteredResults.push(
        Object.entries(result).reduce((resultObj, [eyeTested, value]) => (value ? ((resultObj[eyeTested] = value), resultObj) : resultObj), {})
      );
    });

    setGraphResults(filteredResults);
  }, [results]);

  useEffect(() => {
    if (
      results.filter((result) => {
        return result.hasOwnProperty('leftEye');
      }).length >= 2
    ) {
      setOfferGraphOption(true);
    }
    if (
      results.filter((result) => {
        return result.hasOwnProperty('rightEye');
      }).length >= 2
    ) {
      setOfferGraphOption(true);
    }
    if (
      results.filter((result) => {
        return result.hasOwnProperty('bothEyes');
      }).length >= 2
    ) {
      setOfferGraphOption(true);
    }
  }, [results]);

  return results?.length ? (
    <div className='d-flex flex-column align-items-center m-3'>
      {!showGraph ? (
        <StyledTable style={{ marginBottom: '30px', border: '1px solid black' }}>
          <thead>
            <tr>
              <th>Logmar</th>
              <th>Right Eye</th>
              <th>Left Eye</th>
              <th>Binocular</th>
            </tr>
          </thead>
          <tbody>
            {results?.map((result) => {
              const { logmarTested, leftEye, rightEye, bothEyes } = result;
              return (
                <tr key={`${logmarTested}`}>
                  <td>{logmarTested}</td>
                  <td>{rightEye?.toFixed(2) || '-'}</td>
                  <td>{leftEye?.toFixed(2) || '-'}</td>
                  <td>{bothEyes?.toFixed(2) || '-'}</td>
                </tr>
              );
            })}
          </tbody>
        </StyledTable>
      ) : (
        <LineChart width={700} height={350} data={graphResults} margin={{ top: 5, right: 30, left: 10, bottom: 25 }}>
          <CartesianGrid vertical={false} />
          <XAxis dataKey='name' />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type='monotone' dataKey='Right' stroke='orange' strokeWidth={2} connectNulls />
          <Line type='monotone' dataKey='Left' stroke='blue' strokeWidth={2} connectNulls animationDuration={1000} />
          <Line type='monotone' dataKey='Binocular' stroke='teal' strokeWidth={2} connectNulls />
        </LineChart>
      )}
      {offerGraphOption ? (
        <button className='btn btn-ibis' style={{ width: '15rem' }} onClick={() => setShowGraph(!showGraph)}>
          {!showGraph ? 'Show Graph' : 'Show Table'}
        </button>
      ) : null}
    </div>
  ) : (
    'No test results to display.'
  );
};

export default ContrastSensitivityResults;
