import QuestionForm from './QuestionForm';
import QuestionPathForm from './QuestionPathForm';
import QuestionnaireQuestionList from './QuestionnaireQuestionList';
import { useQuestionnaire } from '../../context/questionnaireContext';

import { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';

const QuestionnaireQuestionContent = ({ addQuestionPath, deleteQuestionPath }) => {
  const {
    getQuestions,
    addQuestionnaireQuestion,
    getQuestionnaireQuestions,
    updateQuestionsOrder,
    setInitialQuestionnaireQuestion,
    getQuestionnaire,
  } = useQuestionnaire();
  const [pickedQuestion, setPickedQuestion] = useState([]);
  const [originQuestionnaireQuestion, setOriginQuestionnaireQuestion] = useState([]);
  const [questionPath, setQuestionPath] = useState([]);
  const [initialQuestion, setInitialQuestion] = useState({});
  const [questionnaire, setQuestionnaire] = useState(null);
  const [availableQuestions, setAvailableQuestions] = useState(null);
  const [questionnaireQuestions, setQuestionnaireQuestions] = useState(null);
  const loggedUser = JSON.parse(localStorage.getItem('loggedUser'));

  const { questionnaireId } = useParams();

  const addQuestionToQuestionnaire = async () => {
    await addQuestionnaireQuestion({
      questionnaire: questionnaire.id,
      question: pickedQuestion,
    });
    await updateQuestionsOrder(questionnaire.id);
    window.location.reload();
  };

  const saveInitialQuestion = async () => {
    const initQuestion = document.getElementById('field_initial_question').value;
    await setInitialQuestionnaireQuestion(initQuestion);
    await updateQuestionsOrder(questionnaire.id);
    window.location.reload();
  };

  const canEdit = (questionnaire_obj) => {
    if (!loggedUser.superuser && questionnaire_obj) {
      if (!questionnaire_obj.company) {
        return false;
      }
    }

    return true;
  };

  const loadQuestionnaire = useCallback(
    async (questionnaireId) => {
      const questionnaireFromServer = await getQuestionnaire(questionnaireId);
      setQuestionnaire(questionnaireFromServer);
    },
    [getQuestionnaire]
  );

  const loadAvailableQuestions = useCallback(
    async (questionnaire) => {
      const questionsFromServer = await getQuestions(questionnaire.id, true);
      setAvailableQuestions(questionsFromServer?.results);
    },
    [getQuestions]
  );

  const loadQuestionnaireQuestions = useCallback(
    async (questionnaire) => {
      const questionnaireQuestions = await getQuestionnaireQuestions('', questionnaire.id);
      setQuestionnaireQuestions(questionnaireQuestions?.results);
    },
    [getQuestionnaireQuestions]
  );

  useEffect(() => {
    if (questionnaireId && questionnaire === null) {
      loadQuestionnaire(questionnaireId);
    }
  }, [questionnaire, questionnaireId, loadQuestionnaire]);

  useEffect(() => {
    if (questionnaire) {
      if (availableQuestions === null) {
        loadAvailableQuestions(questionnaire);
      }

      if (questionnaireQuestions === null) {
        loadQuestionnaireQuestions(questionnaire);
      }
    }
  }, [questionnaire, loadAvailableQuestions, availableQuestions, questionnaireQuestions, loadQuestionnaireQuestions]);

  return (
    <>
      <div className='container-fluid'>
        <div className='d-sm-flex align-items-center justify-content-between mb-4'>
          <h2 className='h3 mb-0 text-ibis'>Questions for {questionnaire?.name}</h2>
        </div>
        {canEdit(questionnaire) ? (
          <div className='row'>
            <div className='col-xl-12 col-md-6 mb-4'>
              <div className='card shadow mb-4'>
                <div className='card-header bg-primary-ibis py-3'>
                  <h6 className='m-0 font-weight-bold text-white'>Available Questions</h6>
                </div>
                <div className='card-body'>
                  <div className='row'>
                    <div className='col-10'>
                      <select
                        className='form-control form-control-questions form-select'
                        aria-label='Select a question'
                        id='field_text_question'
                        onChange={(e) => setPickedQuestion(e.target.value)}
                      >
                        <option selected>Select a question</option>
                        {availableQuestions?.length > 0
                          ? availableQuestions.map((available_question) => (
                              <option
                                key={`available_question_${available_question.id}`}
                                id={`available_question_${available_question.id}`}
                                value={available_question.id}
                              >
                                {available_question.text}
                              </option>
                            ))
                          : ''}
                      </select>
                    </div>
                    <div className='col-2'>
                      <button
                        className='d-none d-sm-inline-block btn btn-sm btn-ibis shadow-sm'
                        type='button'
                        onClick={(e) => {
                          addQuestionToQuestionnaire();
                        }}
                      >
                        Add to the questionnaire
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
        {canEdit(questionnaire) ? (
          <div className='row'>
            <div className='col-xl-12 col-md-6 mb-4'>
              <div className='card shadow mb-4'>
                <div className='card-header bg-primary-ibis py-3'>
                  <h6 className='m-0 font-weight-bold text-white'>Initial Question</h6>
                </div>
                <div className='card-body'>
                  <div className='row'>
                    <div className='col-10'>
                      <select
                        className='form-control form-control-questions form-select'
                        aria-label='Select a question'
                        id='field_initial_question'
                        value={initialQuestion}
                        onChange={(e) => {
                          setInitialQuestion(e.value);
                        }}
                      >
                        {questionnaireQuestions?.length > 0
                          ? questionnaireQuestions.map((questionnaire_question) => (
                              <option
                                key={`question-${questionnaire_question.id}`}
                                selected={questionnaire_question.initial ? 'selected' : ''}
                                id={`initial_question_${questionnaire_question.id}`}
                                value={questionnaire_question.id}
                              >
                                {questionnaire_question.question_text}
                              </option>
                            ))
                          : ''}
                      </select>
                    </div>
                    <div className='col'>
                      <button
                        className='w-100 d-sm-inline-block btn btn-sm btn-ibis shadow-sm'
                        type='button'
                        onClick={(e) => {
                          saveInitialQuestion();
                        }}
                      >
                        Set initial question
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
        <QuestionnaireQuestionList
          setOriginQuestionnaireQuestion={setOriginQuestionnaireQuestion}
          setQuestionPath={setQuestionPath}
          questionnaire={questionnaire}
          loggedUser={loggedUser}
        />
      </div>
      <QuestionForm />
      <QuestionPathForm
        questionnaireQuestions={questionnaireQuestions}
        originQuestionnaireQuestion={originQuestionnaireQuestion}
        addQuestionPath={addQuestionPath}
        deleteQuestionPath={deleteQuestionPath}
        updateQuestionsOrder={updateQuestionsOrder}
      />
    </>
  );
};

export default QuestionnaireQuestionContent;
