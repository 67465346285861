const websocketsArray = {};
const wsUrl = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_WS_URL : process.env.REACT_APP_DEV_WS_URL;

const websocketManager = async (room_name, listenerFunction) => {
    if (websocketsArray[room_name]) {
        websocketsArray[room_name].connection.close()
        // return websocketsArray[room_name];
    }

    const websocketManagerConnector = {
        connection: null,
        init: async (room_name) => {
          return new Promise(function (resolve, reject) {
            var server = new WebSocket(`${wsUrl}/ws/chat/${room_name}/`);
            server.onopen = function () {
              websocketManagerConnector.connection = server;
              websocketManagerConnector.messageListener(listenerFunction);
              resolve(server);
            };
            server.onerror = function (err) {
              reject(err);
            };
          });
        },
        postMessage: (messageObj) => {
          var messageString = JSON.stringify({
            message: messageObj,
          });
          websocketManagerConnector.connection.send(messageString);
        },
        messageListener: function (listenerFunction) {
          websocketManagerConnector.connection.onmessage = function (e) {
            var messageData = JSON.parse(e.data);
            listenerFunction(messageData)
          };
        },
    }
    
    await websocketManagerConnector.init(room_name);
    websocketsArray[room_name] = websocketManagerConnector;
    return websocketManagerConnector;
    
};

export { websocketManager };