const apiUrl = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_API_URL : process.env.REACT_APP_DEV_API_URL;

export const getToken = () => {
  const sessionToken = localStorage.getItem('token');
  return sessionToken;
};

export const destroySession = () => {
  localStorage.removeItem('__endpoint__');
  localStorage.removeItem('token');
  localStorage.removeItem('loggedUser');
  localStorage.removeItem('__ibis_token__');
};

export const login = (requestOptions) => {
  return fetch(apiUrl + '/api-token-auth/', requestOptions)
    .then((response) => response.json())
    .then(async (result) => {
      if (result.token) {
        localStorage.setItem('token', result.token);
        localStorage.setItem('__ibis_token__', JSON.stringify({ token: result.token }));
        return true;
      } else {
        return false;
      }
    })
    .catch((error) => console.log('error', error));
};

export const validateTicket = (ticket) => {
  return fetch(apiUrl + '/api/ticket_system/ticket/validate/?ticket=' + ticket)
    .then((response) => response.json())
    .then(async (result) => {
      if (result.user_token) {
        localStorage.setItem('token', result.user_token);
        localStorage.setItem('__ibis_token__', JSON.stringify({ token: result.user_token }));
        return true;
      } else {
        return false;
      }
    })
    .catch((error) => console.log('error', error));
};

export const getLoggedUser = async () => {
  const options = {
    headers: {
      Authorization: 'Token ' + localStorage.getItem('token'),
    },
  };
  let response = await fetch(apiUrl + '/api/users/current', options);

  if (response.status === 401 && response.redirected) {
    response = await fetch(response.url, options);
  }

  if (response.status === 401) {
    destroySession();
    window.location.reload();
  }

  const loggedUserObject = await response.json();

  return loggedUserObject;
};
