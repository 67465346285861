import { useState } from 'react';
import { sendRecheckEmail, updateRecheckStatus } from './api/recheckFunctions';
import { useRecheck } from './context/recheckContext';

export const useModal = () => {
  const [modalIsOpen, toggleModalIsOpen] = useState(false);
  const toggle = () => toggleModalIsOpen(!modalIsOpen);
  return { modalIsOpen, toggle };
};

export const usePopover = () => {
  const [showPopover, setShowPopover] = useState(false);
  return { showPopover, setShowPopover };
};

export const useSnackbar = () => {
  const [snackbarState, setSnackbarState] = useState({
    show: false,
    message: '',
    variant: null,
  });

  const showSnackbar = (message, variant) => {
    if (message) {
      setSnackbarState({ show: true, message: message, variant: variant });
    } else setSnackbarState({ show: false, message: '' });
  };

  return { snackbarState, showSnackbar };
};

export const useRecheckActions = (showSnackbar) => {

  const { fetchSessions } = useRecheck();

  const handleStatusUpdate = async (id, status, reload) => {
    try {
      const res = await updateRecheckStatus(id, status);
      if (reload) await fetchSessions();
      return showSnackbar(res);
    } catch (err) {
      return showSnackbar(err.message, 'delete');
    }
  };

  const handleEmailSend = async (id, type, reload) => {
    try {
      const res = await sendRecheckEmail(id, type);
      if (reload) await fetchSessions();
      return showSnackbar(res);
    } catch (err) {
      return showSnackbar(err.message, 'delete');
    }
  };

  return { handleStatusUpdate, handleEmailSend };
};
