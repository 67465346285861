import ReactDOM from 'react-dom';

const overlayStyles = {
  position: 'fixed',
  display: 'none',
  width: '100vw',
  height: '100vh',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0,0,0,0.6)',
  zIndex: 2000,
  cursor: 'wait',
};

const LoadingSpinner = ({ message }) => {
  const loadingElement = (
    <div className='d-flex flex-column align-items-center justify-content-center' style={overlayStyles}>
      <i className='fas fa-circle-notch fa-spin fa-10x' style={{ opacity: 0.8 }} />
      <span style={{ fontSize: '1.25rem', marginTop: '2rem', color: '#fff' }}>{message}</span>
    </div>
  );

  return ReactDOM.createPortal(loadingElement, document.getElementById('wrapper'));
};

export default LoadingSpinner;
