import { useCompany } from '../../context/companyContext';
import IbisDataTable from '../DataTable/IbisDataTable';
import { usePopover } from '../../hooks';
import CompanyActionsPopover from './CompanyActionsPopover';

const CompaniesList = () => {
  const { companies } = useCompany();
  const { showPopover, setShowPopover } = usePopover();

  const getActionsButton = (company) => {
    return (
      <>
        <button
          type='button'
          id={`action-btn-${company.id}`}
          className='btn btn-md'
          onClick={() => {
            handlePopover(company.id);
          }}
          onBlur={() => setShowPopover(false)}
        >
          <i className='fas fa-ellipsis-v'></i>
        </button>
        <CompanyActionsPopover id={company.id} showPopover={showPopover === company.id} setShowPopover={setShowPopover} />
      </>
    );
  };

  const handlePopover = (id) => {
    if (showPopover === id) {
      setShowPopover(false);
    } else setShowPopover(id);
  };

  const getColumns = () => {
    return [
      {
        name: 'Name',
        selector: (company) => company.name,
        sortable: true,
        filter_field: 'name',
      },
      {
        name: 'Description',
        selector: (company) => company.description,
        sortable: true,
        filter_field: 'description',
        wrap: true,
      },
      { name: 'Contact Name', selector: (company) => company.contact_name, sortable: true, filter_field: 'contact_name' },
      { name: 'Contact Email', selector: (company) => company.contact_email, sortable: true, filter_field: 'contact_email' },

      {
        name: 'Edit',
        cell: (company) => getActionsButton(company),
        allowOverflow: true,
        button: true,
      },
    ];
  };

  return <IbisDataTable title='Companies' data={companies} columns={getColumns()} />;
};

export default CompaniesList;
