import { fetchObjects, detailObject } from './crudFunctions';

const getApiUrl = (companyId) => {
  if (companyId) {
    return `/api/companies/${companyId}/`;
  }

  return '/api/companies/';
};

const fetchCompanies = async () => {
  return await fetchObjects(getApiUrl());
};

export const getCompanies = async () => {
  const dataFromServer = await fetchCompanies();
  const companiesRecords = dataFromServer.results || [];

  return companiesRecords;
};

export const deleteCompany = async (companyId) => {
  if (window.confirm('Are you sure you want to delete this company?') === true) {
    await detailObject(getApiUrl(companyId), 'DELETE');
    await getCompanies();
    return true;
  } else {
    return false;
  }
};

export const getCurrentCompany = async () => {
  const response = await fetchObjects('/api/companies/current');
  return response;
};
