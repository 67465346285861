import { useEffect, useState } from 'react';
import { getInvitations, resendInvitation, deleteInvitation } from '../../api/invitationFunctions';
import { useSnackbar } from '../../hooks';
import IbisDataTable from '../DataTable/IbisDataTable';
import Snackbar from './Snackbar';
import parseDateAndSort from '../DataTable/parseDateAndSort';

const InvitationsList = ({ invitations, setInvitations, superuser, showAcceptedInvitations }) => {
  const [invitationsToDisplay, setInvitationsToDisplay] = useState(null);
  const { showSnackbar, snackbarState } = useSnackbar();

  useEffect(() => {
    if (!invitations) return;
    if (showAcceptedInvitations) {
      setInvitationsToDisplay(invitations);
    } else setInvitationsToDisplay(invitations.filter((inv) => inv.status === 'pending'));
  }, [showAcceptedInvitations, invitations]);

  useEffect(() => {
    const updateInvitations = async () => {
      setInvitations(await getInvitations());
    };
    updateInvitations();
  }, [setInvitations]);

  const handleResendInviation = async (invitationId) => {
    const response = await resendInvitation(invitationId);
    if (response.id) {
      showSnackbar('Invitation successfully resent.');
      setInvitations(await getInvitations());
    } else if (response === 'canceled') {
      return null;
    } else return alert('Error re-sending');
  };

  const handleDeleteInvitation = async (invitationId) => {
    if (await deleteInvitation(invitationId)) {
      showSnackbar('Invitation successfully deleted.', 'delete');
      setInvitations(await getInvitations());
    }
  };

  const getResendButton = (invitation) => {
    return invitation.status === 'pending' && invitation.send_count < 2 ? (
      <button className='btn btn-ibis btn-circle' onClick={() => handleResendInviation(invitation.id)}>
        <i className='fas fa-share'></i>
      </button>
    ) : (
      <button className='btn btn-ibis btn-circle' disabled style={{ cursor: 'not-allowed' }}>
        <i className='fas fa-ban'></i>
      </button>
    );
  };

  const getDeleteButton = (invitation) => {
    return invitation.status === 'pending' ? (
      <button className='btn btn-danger btn-circle' onClick={() => handleDeleteInvitation(invitation.id)}>
        <i className='fas fa-trash'></i>
      </button>
    ) : (
      <button className='btn btn-danger btn-circle' disabled style={{ cursor: 'not-allowed' }}>
        <i className='fas fa-ban'></i>
      </button>
    );
  };

  const customDateSort = (invitationOne, invitationTwo) => {
    return parseDateAndSort(invitationOne.latest_invitation_date_timestamp, invitationTwo.latest_invitation_date_timestamp);
  };

  const getColumns = () => {
    return [
      {
        name: 'Email',
        selector: (invitation) => invitation.invited_user_email,
        sortable: true,
        filter_field: 'invited_user_email',
      },
      {
        name: 'User Role',
        selector: (invitation) => invitation.invited_user_role.charAt(0).toUpperCase() + invitation.invited_user_role.slice(1),
        sortable: true,
        filter_field: 'invited_user_role',
      },

      {
        name: 'Company',
        selector: (invitation) => invitation.company_name,
        sortable: true,
        filter_field: 'company_name',
        omit: !superuser,
      },
      {
        name: 'Sent On',
        selector: (invitation) => invitation.latest_invitation_date,
        sortable: true,
        sortFunction: customDateSort,
        filter_field: 'latest_invitation_date',
      },
      {
        name: 'Status',
        selector: (invitation) => invitation.status.charAt(0).toUpperCase() + invitation.status.slice(1),
        sortable: true,
        omit: !showAcceptedInvitations,
        maxWidth: '105px',
        filter_field: 'status',
        center: true,
      },
      {
        name: 'Resend',
        selector: (invitation) => getResendButton(invitation),
        button: true,
        center: true,
      },
      {
        name: 'Cancel',
        selector: (invitation) => getDeleteButton(invitation),
        button: true,
        center: true,
      },
    ];
  };

  return (
    <>
      <Snackbar showSnackbar={showSnackbar} snackbarState={snackbarState} />
      <IbisDataTable title='Invitations' data={invitationsToDisplay} columns={getColumns()} conditionalRowStyles={acceptedInviteStyles} />
    </>
  );
};

export default InvitationsList;

const acceptedInviteStyles = [
  {
    when: (row) => row.status !== 'pending',
    style: {
      backgroundColor: 'rgba(0, 255, 160, 0.2)',
    },
  },
];
