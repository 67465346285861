import { useState } from 'react';
import { useModal, useSnackbar } from '../../hooks';
import InvitationsList from './InvitationsList';
import InvitationsForm from './InvitiationsForm';
import Modal from 'react-modal';
import Snackbar from './Snackbar';

const InvitationsContent = () => {
  const [invitations, setInvitations] = useState(null);
  const [showAcceptedInvitations, setShowAcceptedInvitations] = useState(false);
  const { modalIsOpen, toggle } = useModal();
  const { showSnackbar, snackbarState } = useSnackbar();
  const loggedUser = JSON.parse(localStorage.getItem('loggedUser'));

  return (
    <>
      <Snackbar showSnackbar={showSnackbar} snackbarState={snackbarState} />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={toggle}
        role='document'
        appElement={document.getElementById('wrapper')}
        closeTimeoutMS={300}
        className='Modal__Bootstrap modal-dialog'
      >
        <InvitationsForm
          userType={loggedUser?.role}
          superuser={loggedUser?.superuser}
          setInvitations={setInvitations}
          hide={toggle}
          showSnackbar={() => showSnackbar('Invitation successfully sent.')}
        />
      </Modal>
      <div className='container-fluid'>
        <div className='d-flex justify-content-between mb-3'>
          <div className='custom-control custom-switch'>
            <input
              type='checkbox'
              className='custom-control-input'
              id='showAcceptedInvitationsSwitch'
              checked={showAcceptedInvitations}
              onChange={() => setShowAcceptedInvitations(!showAcceptedInvitations)}
            />
            <label className='custom-control-label' style={{ color: 'black' }} htmlFor='showAcceptedInvitationsSwitch'>
              <h4 className='mb-0'>Include Accepted Invitations</h4>
            </label>
          </div>
          <button className='d-none d-sm-inline-block btn btn-sm btn-ibis shadow-sm' type='button' onClick={toggle}>
            <i className='fas fa-plus fa-sm text-white-50'></i>&nbsp;New Invite
          </button>
        </div>
        <InvitationsList
          invitations={invitations}
          setInvitations={setInvitations}
          superuser={loggedUser?.superuser}
          showAcceptedInvitations={showAcceptedInvitations}
        />
      </div>
    </>
  );
};

export default InvitationsContent;
