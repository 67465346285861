import { useEffect, useState } from 'react';

const GraphLine = ({ horizontal }) => {
  return (
    <div
      style={{
        width: horizontal ? '100%' : '1px',
        backgroundColor: '#000',
        height: horizontal ? '1px' : '100%',
        position: 'absolute',
        left: horizontal ? 0 : '50%',
        top: horizontal ? '50%' : 0,
        transform: horizontal ? 'translateY(-50%)' : 'translateX(-50%)',
      }}
    ></div>
  );
};

const VisualFieldMatrix = ({
  size: lineLength,
  testProgressData,
  dotMatrixFromResults,
  outOfBoundariesDots,
  setOutOfBoundariesDots,
  handleCoordHover,
}) => {
  const rawDotDataset = dotMatrixFromResults;
  const [allDots, setAllDots] = useState([]);
  const [usingOldData, setUsingOldData] = useState(false);
  const { missedOnceDots, missedTwiceDots, orientationIncorrect, orientationCorrect } = testProgressData;

  useEffect(() => {
    if (rawDotDataset.some((dot) => dot.id.includes('stimulus'))) setUsingOldData(true);
  }, [rawDotDataset]);

  const getDotColor = (dot) => {
    switch (true) {
      case missedOnceDots?.some((missedDot) => missedDot.id === dot.id):
        return '#F0B729';
      case missedTwiceDots?.some((missedDot) => missedDot.id === dot.id):
        return 'red';
      case orientationCorrect?.some((seenDot) => seenDot.id === dot.id):
        return '#6DC89D';
      case orientationIncorrect?.some((seenDot) => seenDot.id === dot.id):
        return '#870000';
      case outOfBoundariesDots?.some((outOfBoundsDot) => outOfBoundsDot.id === dot.id):
        return '#F2E6E6';
      case dot.id === 'coord15-1.5' || dot.id === 'coord-15-1.5':
        //blindspot
        return '#000';
      default:
        return '#AAB2B2';
    }
  };

  useEffect(() => {
    const getActualPointOnGraph = (degreeX, degreeY) => {
      const getMaxValue = () => {
        const allCoords = rawDotDataset.flatMap((coordObj) => {
          return Object.values(coordObj).filter((coord) => {
            return Math.abs(coord);
          });
        });
        return Math.max(...allCoords);
      };
      const maximumDegree = getMaxValue();
      const halfLineLength = lineLength / 2;
      const sections = halfLineLength / maximumDegree;
      let x = Math.floor(Math.abs((maximumDegree + degreeX) * sections));
      let y = Math.floor(Math.abs((maximumDegree + degreeY) * sections));

      [x, y] = [x, y].map((coord) => {
        //add padding to extreme coordinates
        if (coord === 0) return (coord += lineLength * 0.024);
        if (coord === lineLength) return (coord -= lineLength * 0.024);
        return coord;
      });

      return { x, y };
    };

    const renderCoords = () => {
      const parsedDots = rawDotDataset?.map((coordSet) => {
        const legacyId = `stimulus-point-coordinate_${coordSet.degreeX}-${coordSet.degreeY}`;
        const newMatrixId = `coord${coordSet.degreeX}${coordSet.degreeY}`;
        const invertedY = coordSet.degreeY * -1;
        //invert because CSS Y positions & actual graph Y positions are opposite
        const { x, y } = getActualPointOnGraph(coordSet.degreeX, invertedY);
        if (coordSet.outOfBoundaries)
          setOutOfBoundariesDots((existingDots) => {
            return [...existingDots, coordSet];
          });
        return { id: usingOldData ? legacyId : newMatrixId, left: x, top: y };
      });

      return parsedDots;
    };
    setAllDots(renderCoords());
  }, [rawDotDataset, lineLength, setOutOfBoundariesDots, usingOldData]);

  return (
    <div
      className='d-flex align-items-center justify-content-center'
      style={{
        height: `${lineLength + lineLength * 0.05}px`,
        width: `${lineLength + lineLength * 0.05}px`,
        //this is a container so slightly bigger than the actual matrix
        border: '3px solid grey',
        borderRadius: '12px',
      }}
    >
      <div
        className='d-flex align-items-center justify-content-center'
        style={{ height: `${lineLength}px`, width: `${lineLength}px`, position: 'relative' }}
      >
        <GraphLine horizontal />
        <GraphLine vertical />
        {allDots?.map((dot) => {
          return (
            <div
              key={dot.id}
              id={dot.id}
              onMouseOver={() => handleCoordHover(dot)}
              onMouseLeave={() => handleCoordHover()}
              style={{
                top: dot.top,
                left: dot.left,
                height: `${lineLength * 0.05}px`,
                width: `${lineLength * 0.05}px`,
                borderRadius: '50%',
                position: 'absolute',
                transform: 'translate(-50%, -50%)',
                backgroundColor: getDotColor(dot),
                cursor: 'pointer',
              }}
            ></div>
          );
        })}
      </div>
    </div>
  );
};

export default VisualFieldMatrix;
