import { useEffect, useState } from 'react';

const GraphLine = ({ horizontal }) => {
  return (
    <div
      style={{
        width: horizontal ? '100%' : '1px',
        backgroundColor: '#000',
        height: horizontal ? '1px' : '100%',
        position: 'absolute',
        left: horizontal ? 0 : '50%',
        top: horizontal ? '50%' : 0,
        transform: horizontal ? 'translateY(-50%)' : 'translateX(-50%)',
      }}
    ></div>
  );
};

const VF2Matrix = ({ size: lineLength, rawDotDataSet, testProgressData }) => {
  const [allDots, setAllDots] = useState([]);
  const { seenDots, missedOnceDots, notSeenDots, outOfBoundariesDots } = testProgressData || {};

  const getDotColor = (dot) => {
    switch (true) {
      case dot.id === 'coord15-1.5' || dot.id === 'coord-15-1.5':
        //blindspot
        return '#000';
      case missedOnceDots?.some((missedDot) => missedDot.id === dot.id):
        return '#F0B729';
      case notSeenDots?.some((missedDot) => missedDot.id === dot.id):
        return '#870000';
      case seenDots?.some((seenDot) => seenDot.id === dot.id):
        return '#6DC89D';
      case outOfBoundariesDots?.some((outOfBoundsDot) => outOfBoundsDot.id === dot.id):
        return '#F2E6E6';
      default:
        return '#AAB2B2';
    }
  };

  useEffect(() => {
    const getActualPointOnGraph = (degreeX, degreeY) => {
      const getMaxValue = () => {
        const allCoords = rawDotDataSet.flatMap((coordObj) => {
          return Object.values(coordObj).filter((coord) => {
            return Math.abs(coord);
          });
        });
        return Math.max(...allCoords);
      };
      const maximumDegree = getMaxValue();
      const halfLineLength = lineLength / 2;
      const sections = halfLineLength / maximumDegree;
      let x = Math.round(Math.abs((maximumDegree + degreeX) * sections));
      let y = Math.round(Math.abs((maximumDegree + degreeY) * sections));
      //on stroke test, this needs to be "Math.floor()". Investigate off-by-one errors.

      [x, y] = [x, y].map((coord) => {
        //add padding to extreme coordinates
        if (coord === 0) return (coord += lineLength * 0.01);
        if (coord === lineLength) return (coord -= lineLength * 0.01);
        return coord;
      });
      return { x, y };
    };

    const renderCoords = () => {
      const withoutDisabledBlindSpot = rawDotDataSet.filter((dot) => !dot.blindspot || (dot.blindspot && !dot.disabled));
      const parsedDots = withoutDisabledBlindSpot?.map((coordSet) => {
        const newMatrixId = `coord${coordSet.degreeX}${coordSet.degreeY}`;
        const invertedY = coordSet.degreeY * -1;
        //invert because CSS Y positions & actual graph Y positions are opposite
        const { x, y } = getActualPointOnGraph(coordSet.degreeX, invertedY);
        return { id: newMatrixId, left: x, top: y };
      });
      return parsedDots;
    };
    setAllDots(renderCoords());
  }, [rawDotDataSet, lineLength]);

  return (
    <div
      className='d-flex align-items-center justify-content-center'
      style={{
        height: `${lineLength * 1.05}px`,
        width: `${lineLength * 1.05}px`,
        //this is a container so slightly bigger than the actual matrix
        border: '3px solid grey',
        borderRadius: '12px',
      }}
    >
      <div
        className='d-flex align-items-center justify-content-center'
        style={{ height: `${lineLength}px`, width: `${lineLength}px`, position: 'relative' }}
      >
        <GraphLine horizontal />
        <GraphLine vertical />
        {allDots?.map((dot) => {
          return (
            <div
              key={dot.id}
              id={dot.id}
              style={{
                top: dot.top,
                left: dot.left,
                height: `${lineLength * 0.03}px`,
                width: `${lineLength * 0.03}px`,
                borderRadius: '50%',
                position: 'absolute',
                transform: 'translate(-50%, -50%)',
                backgroundColor: getDotColor(dot),
              }}
            ></div>
          );
        })}
      </div>
    </div>
  );
};

export default VF2Matrix;
