import { useEffect, useState } from 'react';
import PrintMatrix from './PrintMatrix';
import PrintKey from './PrintKey';

const PrintVisualField = ({ results: allResults }) => {
  const [eyesTested, setEyesTested] = useState([]);

  useEffect(() => {
    for (const key in allResults) {
      if (key !== 'corrected')
        setEyesTested((prev) => [
          ...prev,
          { ...allResults[key], eyeTested: key.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/^\w/, (c) => c.toUpperCase()) },
        ]);
    }
  }, [allResults]);

  const filterBlindspotByEye = (eyeTested) => {
    const leftEyeBlindSpot = { degreeX: -15, degreeY: -1.5, blindspot: true, disabled: eyeTested !== 'Left Eye' };
    const rightEyeBlindSpot = { degreeX: 15, degreeY: -1.5, blindspot: true, disabled: eyeTested !== 'Right Eye' };

    const allDots = [3, 9, 15, 21].flatMap((degree1, outerIndex, arr) =>
      arr.flatMap((degree2, innerIndex) => {
        if (outerIndex === 2 && innerIndex === 3) return [];
        if (outerIndex === 3 && innerIndex > 1) return [];
        return [
          { degreeX: degree1, degreeY: degree2 },
          { degreeX: degree1, degreeY: -degree2 },
          { degreeX: -degree1, degreeY: degree2 },
          { degreeX: -degree1, degreeY: -degree2 },
        ];
      })
    );
    return [...allDots, leftEyeBlindSpot, rightEyeBlindSpot];
  };

  return (
    <div className='d-flex flex-column bg-white' width='750px' height='90%'>
      <table className='table table-bordered'>
        <thead>
          <tr>
            <th style={{ width: '45%', verticalAlign: 'middle' }}>Visual Field</th>
            <th>
              <PrintKey />
            </th>
          </tr>
        </thead>
      </table>
      {eyesTested.map((eyeResults) => {
        const rawDotDataSet = filterBlindspotByEye(eyeResults.eyeTested);
        return (
          <div className='d-flex justify-content-between p-3' height='100%' key={`vf-results-${eyeResults.eyeTested}`}>
            <div className='d-flex flex-column justify-content-between' width='35%'>
              <p className='h4 mb-4 text-uppercase font-weight-bold'>{eyeResults.eyeTested}</p>
              <div className='d-flex flex-column'>
                <TestInfoText category={'fixation'}>{eyeResults?.fixationType?.label || 'Not found'}</TestInfoText>
                <TestInfoText category={'response'}>{'Patient Mouse Click'}</TestInfoText>
                <TestInfoText category={'greyscale'}>{'Maxiumum Contrast'}</TestInfoText>
                <TestInfoText category={'target duration'}>{`${eyeResults?.targetDuration / 1000 || '--'}s`}</TestInfoText>
                <TestInfoText category={'confidence level'}>{`${eyeResults?.testConfidence || 0}%`}</TestInfoText>
                <TestInfoText category={'seen'}>{`${eyeResults?.seenDots?.length || 0}`}</TestInfoText>
                <TestInfoText category={'not seen'}>{`${eyeResults?.notSeenDots?.length || 0}`}</TestInfoText>
                <TestInfoText category={'patient distance'}>{`${eyeResults?.patientDistance?.toFixed() || '--'}mm`}</TestInfoText>
              </div>
            </div>
            <PrintMatrix size={375} rawDotDataSet={rawDotDataSet} testProgressData={eyeResults} />
          </div>
        );
      })}
    </div>
  );
};

export default PrintVisualField;

const TestInfoText = ({ category, children }) => {
  return (
    <div className='d-flex'>
      <p className='font-weight-bold mr-1 text-capitalize'>{`${category}:`}</p>
      <p>{children}</p>
    </div>
  );
};
