const ToggleSwitch = ({ id, checked, onChange, label }) => {
	return (
		<div className='d-sm-flex align-items-center justify-content-between mb-3'>
			<div className='custom-control custom-switch'>
				<input
					type='checkbox'
					className='custom-control-input'
					id={id}
					checked={checked}
					onChange={onChange}
				/>
				<label
					className='custom-control-label'
					style={{ color: '#000' }}
					htmlFor={id}
				>
					<h5 className='m-0'>{label}</h5>
				</label>
			</div>
		</div>
	);
};

export default ToggleSwitch;
