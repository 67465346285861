import { createContext, useContext, useState } from 'react';

import { useCrud } from '../context/crudContext';

import { IbisPatient, IbisOptician, IbisAdministrator } from '../helper/IbisUser';

const UserContext = createContext();

export const useUser = () => {
  return useContext(UserContext);
};

export const UserProvider = ({ children }) => {
  const { fetchObjects, addObject, saveObject, detailObject, populateFieldsValues } = useCrud();
  const [opticians, setOpticians] = useState(null);
  const [patients, setPatients] = useState(null);
  const [administrators, setAdministrators] = useState(null);

  const fetchUsers = async (ibisUserType) => {
    return await fetchObjects(ibisUserType.getApiEndpoint());
  };

  const updateUserState = (ibisUserType, usersRecords, newRecord) => {
    let ibisUserState;

    switch (ibisUserType.constructor) {
      case IbisPatient:
        ibisUserState = patients;
        break;
      case IbisOptician:
        ibisUserState = opticians;
        break;
      case IbisAdministrator:
        ibisUserState = administrators;
        break;
      default:
        break;
    }

    if (newRecord) {
      setUser(ibisUserType)([...ibisUserState, newRecord]);
    } else {
      setUser(ibisUserType)(usersRecords);
    }
  };

  const setUser = (ibisUserType) => {
    switch (ibisUserType.constructor) {
      case IbisPatient:
        return setPatients;
      case IbisOptician:
        return setOpticians;
      case IbisAdministrator:
        return setAdministrators;
      default:
        break;
    }
  };

  const getUsers = async (ibisUserType) => {
    const dataFromServer = await fetchUsers(ibisUserType);
    const usersRecords = dataFromServer.results || [];

    updateUserState(ibisUserType, usersRecords);

    return dataFromServer;
  };

  const addUser = async (ibisUserType, userData, unauthorised) => {
    const newUser = await addObject(ibisUserType.getApiEndpoint(), userData, unauthorised);
    if (typeof newUser == 'object') {
      updateUserState(ibisUserType, null, newUser);
      return newUser;
    }
  };

  const saveUser = async (ibisUserType, userId, userData) => {
    delete userData.id;
    const existentUser = await saveObject(ibisUserType.getApiEndpoint(userId), userData);
    if (typeof existentUser == 'object') {
      getUsers(ibisUserType);
      return existentUser;
    }
  };

  const editUser = async (ibisUserType, userId) => {
    Array.from(document.querySelectorAll('.is-invalid')).forEach((el) => el.classList.remove('is-invalid'));
    const res = await detailObject(ibisUserType.getApiEndpoint(userId), 'GET');
    const userObject = await res.json();
    populateFieldsValues(userObject);
    return userObject;
  };

  const deleteUser = async (ibisUserType, userId) => {
    if (window.confirm('Are you sure you want to delete this user?') === true) {
      await detailObject(ibisUserType.getApiEndpoint(userId), 'DELETE');
      await getUsers(ibisUserType);
      return true;
    } else {
      return false;
    }
  };

  const addInvitedUser = async (userData, unauthorised) => {
    const newInvitedUser = await addObject('/api/invitations/accept/', userData, unauthorised);
    if (typeof newInvitedUser == 'object') {
      return newInvitedUser;
    }
    return false;
  };

  return (
    <UserContext.Provider
      value={{ opticians, setOpticians, patients, setPatients, administrators, getUsers, addUser, saveUser, deleteUser, editUser, addInvitedUser }}
    >
      {children}
    </UserContext.Provider>
  );
};
