import { ClinicianRefusalCopy, PatientRefusalCopy } from '../../helper/consent-helpers';

const RefusedConsent = () => {
  const ticket = localStorage.getItem('ticket');
  const role = localStorage.getItem('role');

  const handleBackButton = () => {
    if (ticket) return (window.location.href = `${process.env.PUBLIC_URL}/appointments/?ticket=${ticket}`);
    return window.history.back();
  };
  return (
    <div className='container d-flex justify-content-center align-items-center' style={{ height: '100vh' }}>
      <div className='card o-hidden border-0 shadow-lg my-5'>
        <div className='card-body p-5'>
          <div className='row'>
            <div className='col-lg-5 d-lg-block bg-login-image' />
            <div className='col-lg-7 d-flex flex-column justify-content-center' style={{ height: '24.5rem' }}>
              {role === 'patient' ? <PatientRefusalCopy /> : <ClinicianRefusalCopy />}
              <button className='btn btn-ibis mt-5' onClick={() => handleBackButton()}>
                Go Back
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RefusedConsent;
