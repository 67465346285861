import { useState } from 'react';
import { useQuestionnaire } from '../../context/questionnaireContext';
import { useModal } from '../../hooks';
import IbisDataTable from '../DataTable/IbisDataTable';
import Modal from 'react-modal';
import EditQuestionnaireForm from './EditQuestionnaireForm';

const loggedUser = JSON.parse(localStorage.getItem('loggedUser'));

const QuestionnairesList = () => {
  const { questionnaires, deleteQuestionnaire } = useQuestionnaire();
  const { toggle, modalIsOpen } = useModal();
  const [questionnaireToEdit, setQuestionnaireToEdit] = useState(null);

  const hasEditPrivileges = (questionnaire) => {
    if (!loggedUser.superuser) {
      if (!questionnaire.company) {
        return false;
      }
    }
    return true;
  };

  const getQuestionsBtn = (questionnaire) => {
    return (
      <a className='btn btn-ibis btn-circle' href={`questions/${questionnaire.id}`}>
        <i className='fas fa-list' />
      </a>
    );
  };

  const getEditBtn = (questionnaire) => {
    if (!hasEditPrivileges(questionnaire)) return;
    return (
      <button
        className='btn btn-ibis btn-circle'
        onClick={() => {
          setQuestionnaireToEdit(questionnaire.id);
          toggle();
        }}
      >
        <i className='fas fa-edit' />
      </button>
    );
  };

  const getDeleteBtn = (questionnaire) => {
    if (!hasEditPrivileges(questionnaire)) return;
    return (
      <button className='btn btn-danger btn-circle' onClick={() => deleteQuestionnaire(questionnaire.id)}>
        <i className='fas fa-trash' />
      </button>
    );
  };

  const questionnaireTableColumns = [
    {
      name: 'ID',
      selector: (questionnaire) => questionnaire.id,
      sortable: true,
      filter_field: 'id',
      maxWidth: '100px',
    },
    {
      name: 'Name',
      selector: (questionnaire) => {
        return (
          <span>
            <QuestionnaireCompanyBadge questionnaire={questionnaire} />
            {questionnaire.name}
          </span>
        );
      },
      sortable: true,
      filter_field: 'name',
    },
    {
      name: 'Questions',
      selector: (questionnaire) => getQuestionsBtn(questionnaire),
      width: '125px',
      button: true,
    },
    {
      name: 'Edit',
      selector: (questionnaire) => getEditBtn(questionnaire),
      button: true,
    },
    {
      name: 'Delete',
      selector: (questionnaire) => getDeleteBtn(questionnaire),
      button: true,
    },
  ];

  return (
    <div className='container-fluid'>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={toggle}
        role='document'
        appElement={document.getElementById('wrapper')}
        closeTimeoutMS={300}
        className='Modal__Bootstrap modal-dialog'
      >
        <EditQuestionnaireForm hide={toggle} questionnaireId={questionnaireToEdit} />
      </Modal>
      <IbisDataTable title='Questionnaires' data={questionnaires} columns={questionnaireTableColumns} />
    </div>
  );
};

export default QuestionnairesList;

const QuestionnaireCompanyBadge = ({ questionnaire }) => {
  if (loggedUser.superuser) {
    if (questionnaire.company) {
      return <span className='badge badge-ibis mr-1'>{questionnaire.company}</span>;
    }
  } else {
    if (!questionnaire.company) {
      return <span className='badge badge-ibis mr-1'>IbisVision</span>;
    }
  }
  return null;
};
