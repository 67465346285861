import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getAppointment } from '../../api/appointmentFunctions';

const Ishihara = ({ apptId }) => {
  const [results, setResults] = useState({});
  const loggedUser = JSON.parse(localStorage.getItem('loggedUser'));
  const history = useHistory();

  useEffect(() => {
    getAppointment(apptId).then((response) => {
      const { tests_results } = response;
      const { result_data } = tests_results?.find((result) => result.test_key === 'ishihara') || {};
      const ishiharaResults = result_data ? JSON.parse(result_data) : {};
      const resultsArr = Object.entries(ishiharaResults).sort();
      setResults(resultsArr);
    });
  }, [apptId]);

  return (
    <div className='border border-dark rounded p-3 mb-4 bg-white'>
      <div className='d-sm-flex align-items-center justify-content-between mb-4'>
        <div className='col-sm-6' style={{ paddingLeft: '0.3rem' }}>
          <h3 className='h3 mb-0 text-gray-800'>{`Ishihara Colourblindness Test`}</h3>
          <i className='fas fa-clock' style={{ marginRight: '0.35rem', color: 'rgb(0, 136, 161)' }} />
          {`5 minutes`}
        </div>
        {loggedUser.role === 'patient' ? (
          <div className='d-flex justify-content-center'>
            <button
              className='btn-ibis btn-lg shadow-sm'
              style={{ minWidth: '188px' }}
              onClick={() => history.push(`${process.env.PUBLIC_URL}/${apptId}/ishihara-test`)}
              disabled={results.length}
            >
              {results.length ? 'Test Completed' : 'Complete Test'}
            </button>
          </div>
        ) : null}
      </div>
      {results.length && loggedUser?.role !== 'patient' ? (
        <div className='row'>
          <div className='col-xl-12 col-md-6 mb-4'>
            <div className='card shadow mb-4'>
              <a
                href='#collapsePatientIshihara'
                className='d-block card-header bg-primary-ibis py-3'
                data-toggle='collapse'
                role='button'
                aria-expanded='false'
                aria-controls='collapsePatientIshihara'
              >
                <h6 className='m-0 font-weight-bold text-white'>{`Patient Answers`}</h6>
              </a>
              <div className='collapse' id='collapsePatientIshihara'>
                <div className='card-body'>
                  <table className='table table-bordered' id='dataTable' width='100%' cellSpacing='0'>
                    <tbody>
                      <tr>
                        <th>Plate</th>
                        <th>Normal vision response</th>
                        <th>Colour deficit response</th>
                        <th>Patient response</th>
                      </tr>
                      {results.map((result) => {
                        return (
                          <tr key={`ishihara-${result[0]}`}>
                            <td>
                              <img src={`/img/ishihara/testplate_${result[0]}.png`} alt='ishihara plate' style={{ width: '100px' }} />
                            </td>
                            <td>{result[1]?.plate?.answer === 'unclear' ? 'Unclear/nothing' : result[1]?.plate?.answer || ''}</td>
                            <td>{result[1]?.plate?.penalty_answer === 'unclear' ? 'Unclear/nothing' : result[1]?.plate?.penalty_answer || ''}</td>
                            <td
                              className={
                                result[1]?.result === 'Correct'
                                  ? 'bg-success text-white'
                                  : result[1]?.result === 'Incorrect'
                                  ? 'bg-danger text-white'
                                  : '' || ''
                              }
                            >
                              {result[1]?.selected === 'unclear' ? 'Unclear/nothing' : result[1]?.selected || ''}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Ishihara;
