import { useState, Fragment } from 'react';
import { EyeMotilityResults, StrokeAcuityResults, StrokeVisualFieldResults } from '.';

const Container = ({ children }) => {
  return (
    <div className='d-flex flex-column align-items-center' style={{ width: '100%', height: '100%' }}>
      {children}
    </div>
  );
};

const OptionSeparator = () => {
  return <div style={{ height: '50%', background: 'rgba(60, 60, 67, 0.36)', borderRadius: '0.5px', width: '1px' }}></div>;
};

const SegmentedPicker = ({ options, selectedOption, setSelectedOption }) => {
  const selectedOptionProps = {
    borderRadius: '7px',
    boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.12), 0px 3px 1px rgba(0, 0, 0, 0.04)',
    backgroundColor: 'var(--primary-color)',
    color: '#fff',
  };

  const renderOptions = (option, index) => {
    const nextOption = options[index + 1];
    const optionIsSelected = selectedOption === option;
    const isLastOption = index === options.length - 1;

    const shouldRenderSeparator = () => {
      if (!isLastOption && !optionIsSelected && nextOption !== selectedOption) {
        return true;
      }
      return false;
    };

    return (
      <Fragment key={`segmented-picker-option-${option}`}>
        <div
          className='d-flex align-items-center justify-content-center'
          style={{
            padding: '1.25rem 1.5rem',
            flexGrow: '1',
            height: '2rem',
            cursor: 'pointer',
            ...(optionIsSelected && { ...selectedOptionProps }),
            ...(!shouldRenderSeparator() && { borderRight: '0.5px solid transparent' }),
          }}
          onClick={() => setSelectedOption(option)}
        >
          <span>{option}</span>
        </div>
        {shouldRenderSeparator() && <OptionSeparator />}
      </Fragment>
    );
  };
  return (
    <div className='d-flex align-items-center' style={{ minWidth: '150px', height: '2rem', borderRadius: '8px', backgroundColor: '#fbfbfb' }}>
      {options?.map(renderOptions)}
    </div>
  );
};

const StrokeTestResults = ({
  testInfo,
  results: {
    visualFieldForthValley: { results },
  },
}) => {
  const [selectedResultsView, setSelectedResultsView] = useState('Eye Motility (Images)');

  const renderSwitch = () => {
    switch (selectedResultsView) {
      case 'Eye Motility (Images)':
        return <EyeMotilityResults showImages testData={testInfo?.media || []} oldImages={results?.motilityImages || []} />;
      case 'Visual Acuity':
        return <StrokeAcuityResults testData={results || {}} />;
      case 'Visual Field':
        return <StrokeVisualFieldResults testData={results || {}} />;
      case 'Eye Motility (Video)':
        return <EyeMotilityResults showVideo testData={testInfo?.media || []} />;
      default:
        return <EyeMotilityResults showImages testData={testInfo?.media || []} oldImages={results?.motilityImages || []} />;
    }
  };

  return (
    <Container>
      <SegmentedPicker
        options={['Eye Motility (Images)', 'Visual Acuity', 'Visual Field', 'Eye Motility (Video)']}
        selectedOption={selectedResultsView}
        setSelectedOption={setSelectedResultsView}
      />
      {renderSwitch()}
    </Container>
  );
};

export default StrokeTestResults;
