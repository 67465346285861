import { useEffect, useState } from 'react';
import { getLoggedUser } from '../../../api/authFunctions';
import { dateFormatMap } from '../../../helper/IbisUser';
import { detailObject } from '../../../api/crudFunctions';

const Prescription = ({ prescription }) => {
  const [dateFormat, setDateFormat] = useState(null);
  const formatDate = (date) => {
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    switch (dateFormat) {
      case 'MM/dd/yyyy':
        return [month, day, year].join('/');
      case 'yyyy/MM/dd':
        return [year, month, day].join('/');
      case 'yyyy/dd/MM':
        return [year, day, month].join('/');
      default:
        return [day, month, year].join('/');
    }
  };

  const deletePrescriptionFile = async (fileId) => {
    if (window.confirm('Are you sure you want to delete this file? This action cannot be undone.') === true) {
      const response = await detailObject(`/api/user-prescription-media/${fileId}`, 'DELETE');
      if (response.ok) {
        window.location.reload();
      } else {
        document.getElementById('general_error').classList.add('show');
        document.getElementById('general_error_message').innerHTML = 'There was a problem deleting these test results.';
      }
    }
  };

  useEffect(() => {
    if (dateFormat) return;
    getLoggedUser().then((res) => {
      setDateFormat(dateFormatMap[res.company_info.dateformat] || 'dd/MM/yyyy');
    });
  }, [dateFormat]);
  return (
    <table className='table table-bordered w-75'>
      <thead>
        <tr>
          <th colSpan='2' className='text-center bg-primary-ibis'>
            Prescription
          </th>
        </tr>
      </thead>
      <tbody>
        {!prescription?.data ? (
          <tr>
            <td>No prescription found.</td>
          </tr>
        ) : (
          <>
            <tr>
              <td className='font-weight-bold w-25'>Date</td>
              <td>{prescription.date && dateFormat ? formatDate(new Date(prescription.date)) : 'Not informed'}</td>
            </tr>
            <tr>
              <td>{/* intentionally blank */}</td>
              <td>
                <PrescriptionTable prescription={prescription} />
              </td>
            </tr>
            {prescription.file?.url ? (
              <tr>
                <td className='font-weight-bold'>Prescription File</td>
                <td>
                  <a className='text-ibis' href={prescription.file?.url} target='_blank' rel='noreferrer'>
                    {prescription.file?.file_name}
                  </a>{' '}
                  <button
                    type='button'
                    id={`delete-file`}
                    className='btn btn-danger btn-circle'
                    onClick={() => deletePrescriptionFile(prescription.file.id)}
                  >
                    <i className='fas fa-trash'></i>
                  </button>
                </td>
              </tr>
            ) : null}
          </>
        )}
      </tbody>
    </table>
  );
};

export default Prescription;

const PrescriptionTable = ({ prescription }) => {
  const getLabel = (type) => {
    switch (type) {
      case 'add':
        return 'near addition';
      case 'pd':
        return 'PD';
      case 'curve':
        return 'Curve';
      case 'diameter':
        return 'Diameter';
      case 'brand':
        return 'Brand';
      default:
        return type;
    }
  };
  const data = prescription.data;
  const eyeParameters = ['brand', 'curve', 'diameter', 'sphere', 'cylinder', 'axis', 'add', 'pd'];
  return (
    <table className='table table-bordered'>
      <thead>
        <tr>
          <th colSpan={'2'}>Right Eye</th>
          <th colSpan={'2'}>Left Eye</th>
        </tr>
      </thead>
      <tbody>
        {eyeParameters.map((param) => {
          return (
            <tr key={param}>
              <td className='font-weight-bold text-capitalize'>{getLabel(param)}</td>
              <td>{data['right-eye'][param] || '--'}</td>
              <td className='font-weight-bold text-capitalize'>{getLabel(param)}</td>
              <td>{data['left-eye'][param] || '--'}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
