import { useEffect, useState } from 'react';

const logmarToSnellen = {
  '1.0': '6/60',
  '0.90': '6/48',
  '0.80': '6/38',
  '0.70': '6/30',
  '0.60': '6/24',
  '0.50': '6/19',
  '0.40': '6/15',
  '0.30': '6/12',
  '0.20': '6/9',
  '0.10': '6/7.5',
  '0.0': '6/6',
  '-0.1': '6/5',
  '-0.2': '6/4',
};

const Flex = ({ children, style }) => {
  return (
    <div className='d-flex align-items-center justify-content-center flex-column' style={style}>
      {children}
    </div>
  );
};

const StrokeAcuityResults = ({ testData }) => {
  const [finalScore, setFinalScore] = useState(null);
  const { logmarSize, finalLogmarSize, orientationCorrect } = testData;

  useEffect(() => {
    const getFinalLogmarScore = () => {
      return (finalLogmarSize * 10 - 1) * 0.1;
    };

    if (finalLogmarSize) setFinalScore(getFinalLogmarScore().toFixed(1));
    if (!orientationCorrect?.length) setFinalScore('>1.0');
    if (!finalLogmarSize && orientationCorrect?.length) setFinalScore(logmarSize.toFixed(1));
  }, [finalLogmarSize, logmarSize, orientationCorrect]);

  return (
    <Flex style={{ height: '500px', width: '400px' }}>
      {!Object.keys(testData).length ? (
        'Cannot display acuity results at this time.'
      ) : (
        <>
          <Flex style={{ flexDirection: 'row', backgroundColor: 'var(--primary-color)', padding: '0.75rem' }}>
            <Flex>
              <span style={{ color: 'white', marginBottom: '.25rem', fontSize: '1.25rem' }}>Final logMAR score</span>
              <span style={{ color: 'white', fontWeight: '700', fontSize: '1.5rem' }}>{finalScore}</span>
            </Flex>
          </Flex>
          <Flex padding='1rem' backgroundColor={'#fafafa'} width='60%' centered>
            <Flex>
              <span style={{ marginBottom: '.25rem', fontSize: '1.25rem' }}>Snellen</span>
              <span style={{ fontWeight: '700', fontSize: '1.5rem' }}>{logmarToSnellen[finalScore] || 'N/A'}</span>
            </Flex>
          </Flex>
        </>
      )}
    </Flex>
  );
};

export default StrokeAcuityResults;
