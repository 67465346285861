import { useEffect, useState } from 'react';

const PrintStrokeAcuity = ({ results }) => {
  const [finalAcuityScore, setFinalAcuityScore] = useState('');
  const { finalLogmarSize, logmarSize, orientationCorrect } = results;

  useEffect(() => {
    const getFinalLogmarScore = () => {
      return (finalLogmarSize * 10 - 1) * 0.1;
    };

    if (finalLogmarSize) setFinalAcuityScore(getFinalLogmarScore().toFixed(2));
    if (!orientationCorrect?.length) setFinalAcuityScore('>1.0');
    if (!finalLogmarSize && orientationCorrect?.length) setFinalAcuityScore(logmarSize.toFixed(2));
  }, [finalLogmarSize, logmarSize, orientationCorrect]);

  return (
    <li className='list-group-item'>
      <p className='h4 mb-3 text-uppercase font-weight-bold'>
        <u>{'Visual Acuity'}</u>
      </p>
      <div className='d-flex flex-column'>
        <div className='d-flex'>
          <p className='font-weight-bold mr-1'>{`Final logMAR Score:`}</p>
          <p>{finalAcuityScore}</p>
        </div>
        <TestInfoText category={'snellen'}>{logmarToSnellen[finalAcuityScore]}</TestInfoText>
      </div>
    </li>
  );
};

export default PrintStrokeAcuity;

const TestInfoText = ({ category, children }) => {
  return (
    <div className='d-flex'>
      <p className='font-weight-bold mr-1 text-capitalize'>{`${category}:`}</p>
      <p>{children}</p>
    </div>
  );
};

const logmarToSnellen = {
  '1.0': '6/60',
  '0.90': '6/48',
  '0.80': '6/38',
  '0.70': '6/30',
  '0.60': '6/24',
  '0.50': '6/19',
  '0.40': '6/15',
  '0.30': '6/12',
  '0.20': '6/9',
  '0.10': '6/7.5',
  '0.0': '6/6',
  '-0.1': '6/5',
  '-0.2': '6/4',
};
