import { useEffect, useState } from 'react';

const logmarToSnellen = {
  '1.0': '6/60',
  '0.90': '6/48',
  '0.80': '6/38',
  '0.70': '6/30',
  '0.60': '6/24',
  '0.50': '6/19',
  '0.40': '6/15',
  '0.30': '6/12',
  '0.20': '6/9',
  '0.10': '6/7.5',
  '0.0': '6/6',
  '-0.1': '6/5',
  '-0.2': '6/4',
};

const BasicEyeScore = ({ eye, results }) => {
  const [score, setScore] = useState();
  const [snellen, setSnellen] = useState();
  const title = eye.charAt(0).toUpperCase() + eye.slice(1) + `${eye !== 'binocular' ? ' Eye' : ''}`;

  useEffect(() => {
    if (eye !== 'binocular') {
      setScore(results[`${eye}Eye`]);
      setSnellen(results[`${eye}EyeSnellen`]);
    } else {
      setScore(results.bothEyes);
      setSnellen(results.bothEyesSnellen);
    }
  }, [eye, results]);

  return (
    <div className='d-flex flex-column' style={{ width: '300px', margin: '10px' }}>
      <div
        style={{ width: '100%', height: '50px', backgroundColor: 'var(--primary-color)', color: 'white' }}
        className='d-flex justify-content-center align-items-center'
      >
        <span>{title}</span>
      </div>
      <div style={{ width: '100%', height: '80px' }} className='d-flex justify-content-center align-items-center'>
        <span style={{ fontWeight: 'bold', fontSize: '30px' }}>{typeof score === 'number' ? score.toFixed(2) : score}</span>
      </div>
      <div
        style={{ backgroundColor: '#e3e3e3', width: '100%', height: '110px' }}
        className='d-flex flex-column justify-content-center align-items-center'
      >
        <span style={{ marginBottom: '10px', fontWeight: 'bold', fontSize: '30px' }}>
          {snellen ? snellen : typeof score === 'number' ? logmarToSnellen[score.toFixed(1)] : score}
        </span>
        <span color={{ backgroundColor: '#075e5d' }}>Snellen</span>
      </div>
    </div>
  );
};

export default BasicEyeScore;
