import UserForm from './UserForm';
import EditUserForm from './EditUserForm';
import PatientsList from './PatientsList';
import { useUser } from '../../context/userContext';
import { IbisPatient } from '../../helper/IbisUser';
import { useState } from 'react';

const PatientContent = () => {
  const [showInactivePatients, setShowInactivePatients] = useState(false);
  const { addUser, saveUser } = useUser();
  const loggedUser = JSON.parse(localStorage.getItem('loggedUser'));

  const clearFormErrors = () => {
    Array.from(document.querySelectorAll('.is-invalid')).forEach((el) => el.classList.remove('is-invalid'));
  };
  return (
    <>
      <div className='container-fluid'>
        <div className='d-sm-flex align-items-center justify-content-between'>
          <div className='custom-control custom-switch'>
            <input
              type='checkbox'
              className='custom-control-input'
              id='showInactivePatientsSwitch'
              checked={showInactivePatients}
              onChange={() => setShowInactivePatients(!showInactivePatients)}
            />
            <label className='custom-control-label' style={{ color: 'black' }} htmlFor='showInactivePatientsSwitch'>
              <h4>Include Inactive Patients</h4>
            </label>
          </div>
          {loggedUser?.superuser && (
            <button
              className='d-none d-sm-inline-block btn btn-sm btn-ibis shadow-sm'
              type='button'
              data-toggle='modal'
              data-target='#addPatientModal'
              onClick={clearFormErrors}
            >
              <i className='fas fa-plus fa-sm text-white-50'></i>&nbsp;Add
            </button>
          )}
        </div>
        <div className='row'>
          <div className='col-xl-12 col-md-6 '>
            <PatientsList showInactivePatients={showInactivePatients} />
          </div>
        </div>
      </div>
      <UserForm userType='Patient' addUser={addUser} ibisUserType={new IbisPatient()} />
      <EditUserForm userType='Patient' saveUser={saveUser} ibisUserType={new IbisPatient()} />
    </>
  );
};

export default PatientContent;
