import { useEffect } from 'react';
import { createPopper } from '@popperjs/core';
import './CompanyActionsPopover.css';
import { Link } from 'react-router-dom';

const CompanyActionsPopover = ({ id, showPopover }) => {
  useEffect(() => {
    const popover = document.querySelector(`#action-panel-${id}`);
    if (showPopover) {
      const actionsBtn = document.querySelector(`#action-btn-${id}`);
      const popover = document.querySelector(`#action-panel-${id}`);
      createPopper(actionsBtn, popover, {
        placement: 'left',
      });

      popover.style.display = 'block';

      popover.addEventListener('mousedown', function preventActionBtnBlur(e) {
        return e.preventDefault();
      });
    } else {
      popover.style.display = 'none';
    }
  }, [id, showPopover]);

  return (
    <dialog id={`action-panel-${id}`} className='popover' style={{ padding: 0, width: '15vw' }}>
      <ul>
        <div className='popover-list-container'>
          <Link to={`/edit-company-info/${id}`} className='popover-link'>
            <li>
              <i className='fas fa-fw fa-edit' style={{ marginRight: '0.65rem' }} />
              Edit Company Info
            </li>
          </Link>

          <hr />
          <Link to={`/edit-company-features/${id}`} className='popover-link'>
            <li>
              <i className='fas fa-fw fa-poll-h' style={{ marginRight: '0.65rem' }} />
              Edit Company Features
            </li>
          </Link>
        </div>
      </ul>
    </dialog>
  );
};

export default CompanyActionsPopover;
