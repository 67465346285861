import { useEffect, useState } from 'react';
import { useCompany } from '../../context/companyContext';
import CompanyCountrySelect from './CompanyCountrySelect';
import CompanyTimeZoneSelect from './CompanyTimeZoneSelect';
import EditCompanyFeatures from './EditCompanyFeatures';
import LoadingSpinner from '../Misc/LoadingSpinner';
import moment from 'moment-timezone';
import DateFormatSelect from './DateFormatSelect';

const CompanyForm = ({ toggle }) => {
  const { addCompany } = useCompany();
  const [companyObject, setCompanyObject] = useState({});
  const [featuresModalOpen, setFeaturesModalOpen] = useState(false);
  const [newCompanyId, setNewCompanyId] = useState(null);
  const [companyCreationPending, setCompanyCreationPending] = useState(false);
  const [timezoneOptions, setTimezoneOptions] = useState([]);

  const {
    name = '',
    description = '',
    contact_email = '',
    contact_name = '',
    country = '',
    address_1 = '',
    address_2 = '',
    city = '',
    postcode = '',
    phone = '',
    vat = '',
    timezone = '',
    timezone_offset = '0',
    dateformat = '',
  } = companyObject;

  const handleCompanyFormSubmit = () => {
    const delay = setInterval(() => setCompanyCreationPending(true), 300);
    addCompany(companyObject).then((response) => {
      clearInterval(delay);
      if (typeof response === 'object') {
        setFeaturesModalOpen(true);
        setNewCompanyId(response.id);
      }
    });
  };

  useEffect(() => {
    if (companyObject.country) {
      setTimezoneOptions(
        moment.tz.zonesForCountry(companyObject.country)?.map((country_zone) => {
          return {
            value: country_zone + '|' + moment.tz(country_zone).utcOffset(),
            label: country_zone,
          };
        })
      );
    }
  }, [companyObject]);

  return (
    <>
      {companyCreationPending ? <LoadingSpinner message={'Creating company...'} /> : null}
      {featuresModalOpen ? (
        <div className='d-flex flex-column align-items-center p-3 container-fluid'>
          <EditCompanyFeatures closeModal={toggle} newCompanyId={newCompanyId} />
        </div>
      ) : (
        <>
          <form>
            <div className='form-group row'>
              <div className='col mb-3 mb-sm-0'>
                <input
                  type='text'
                  className='form-control form-control-company name'
                  id='field_name'
                  placeholder='Company Name'
                  value={name}
                  onChange={(e) =>
                    setCompanyObject((obj) => {
                      return { ...obj, name: e.target.value };
                    })
                  }
                />
                <div id='validationfield_name' className='invalid-feedback'>
                  <span id='invalid_name_message'></span>
                </div>
              </div>
            </div>
            <div className='form-group row'>
              <div className='col mb-3 mb-sm-0'>
                <input
                  type='text'
                  className='form-control form-control-company description'
                  id='field_description'
                  placeholder='Description'
                  value={description}
                  onChange={(e) =>
                    setCompanyObject((obj) => {
                      return { ...obj, description: e.target.value };
                    })
                  }
                />
                <div id='validationfield_description' className='invalid-feedback'>
                  <span id='invalid_description_message'></span>
                </div>
              </div>
            </div>
            <div className='form-group row'>
              <div className='col-sm-6'>
                <input
                  type='email'
                  className='form-control form-control-company contact_email'
                  id='field_contact_email'
                  placeholder='Contact Email'
                  value={contact_email}
                  onChange={(e) =>
                    setCompanyObject((obj) => {
                      return { ...obj, contact_email: e.target.value };
                    })
                  }
                />
                <div id='validationfield_contact_email' className='invalid-feedback'>
                  <span id='invalid_contact_email_message'></span>
                </div>
              </div>

              <div className='col mb-3 mb-sm-0'>
                <input
                  type='text'
                  className='form-control form-control-company contact_name'
                  id='field_contact_name'
                  placeholder='Contact Name'
                  value={contact_name}
                  onChange={(e) =>
                    setCompanyObject((obj) => {
                      return { ...obj, contact_name: e.target.value };
                    })
                  }
                />
                <div id='validationfield_contact_name' className='invalid-feedback'>
                  <span id='invalid_contact_name_message'></span>
                </div>
              </div>
            </div>
            <div className='form-group row'>
              <div className='col mb-3 mb-sm-0'>
                <input
                  type='text'
                  className='form-control form-control-company address_1'
                  id='field_address_1'
                  placeholder='Address Line 1'
                  value={address_1}
                  onChange={(e) =>
                    setCompanyObject((obj) => {
                      return { ...obj, address_1: e.target.value };
                    })
                  }
                />
                <div id='validationfield_address_1' className='invalid-feedback'>
                  <span id='invalid_address_1_message'></span>
                </div>
              </div>
            </div>
            <div className='form-group row'>
              <div className='col mb-3 mb-sm-0'>
                <input
                  type='text'
                  className='form-control form-control-company address_2'
                  id='field_address_2'
                  placeholder='Address Line 2'
                  value={address_2}
                  onChange={(e) =>
                    setCompanyObject((obj) => {
                      return { ...obj, address_2: e.target.value };
                    })
                  }
                />
                <div id='validationfield_address_2' className='invalid-feedback'>
                  <span id='invalid_address_2_message'></span>
                </div>
              </div>
            </div>
            <div className='form-group row'>
              <div className='col mb-3 mb-sm-0'>
                <input
                  type='text'
                  className='form-control form-control-company city'
                  id='field_city'
                  placeholder='City'
                  value={city}
                  onChange={(e) =>
                    setCompanyObject((obj) => {
                      return { ...obj, city: e.target.value };
                    })
                  }
                />
                <div id='validationfield_city' className='invalid-feedback'>
                  <span id='invalid_city_message'></span>
                </div>
              </div>
              <div className='col mb-3 mb-sm-0'>
                <input
                  type='text'
                  className='form-control form-control-company postcode'
                  id='field_postcode'
                  placeholder='Postcode'
                  value={postcode}
                  onChange={(e) =>
                    setCompanyObject((obj) => {
                      return { ...obj, postcode: e.target.value };
                    })
                  }
                />
                <div id='validationfield_postcode' className='invalid-feedback'>
                  <span id='invalid_postcode_message'></span>
                </div>
              </div>
            </div>
            <div className='form-group row'>
              <div className='col mb-3 mb-sm-0'>
                <CompanyCountrySelect
                  className='form-control form-control-company country'
                  id='field_country'
                  value={country}
                  onChange={(e) =>
                    setCompanyObject((obj) => {
                      return { ...obj, country: e.target.value, timezone: '', timezone_offset: 0 };
                    })
                  }
                />
                <div id='validationfield_country' className='invalid-feedback'>
                  <span id='invalid_country_message'></span>
                </div>
              </div>
              <div className='col mb-3 mb-sm-0'>
                <CompanyTimeZoneSelect
                  className='form-control form-control-timezone timezone'
                  id='field_timezone'
                  value={`${timezone}|${timezone_offset}`}
                  onChange={(e) =>
                    setCompanyObject((obj) => {
                      return { ...obj, timezone: e.target.value.split('|')[0], timezone_offset: e.target.value.split('|')[1] };
                    })
                  }
                  options={timezoneOptions}
                />
                <div id='validationfield_timezone' className='invalid-feedback'>
                  <span id='invalid_timezone_message'></span>
                </div>
              </div>
              <div className='col mb-3 mb-sm-0'>
                <DateFormatSelect
                  value={dateformat}
                  id='field_dateformat'
                  className='form-control form-control-dateformat dateformat'
                  onChange={(e) =>
                    setCompanyObject((obj) => {
                      return { ...obj, dateformat: e.target.value };
                    })
                  }
                  style={{ color: '#858796', paddingLeft: '0.375rem' }}
                />
              </div>
            </div>
            <div className='form-group row'>
              <div className='col mb-3 mb-sm-0'>
                <input
                  type='number'
                  className='form-control form-control-company phone'
                  id='field_phone'
                  placeholder='Phone number'
                  value={phone}
                  onChange={(e) =>
                    setCompanyObject((obj) => {
                      return { ...obj, phone: e.target.value };
                    })
                  }
                />
                <div id='validationfield_phone' className='invalid-feedback'>
                  <span id='invalid_phone_message'></span>
                </div>
              </div>
            </div>
            <div className='form-group row'>
              <div className='col mb-3 mb-sm-0'>
                <input
                  type='number'
                  className='form-control form-control-company vat'
                  id='field_vat'
                  placeholder='VAT Number'
                  value={vat}
                  onChange={(e) =>
                    setCompanyObject((obj) => {
                      return { ...obj, vat: e.target.value };
                    })
                  }
                />
                <div id='validationfield_vat' className='invalid-feedback'>
                  <span id='invalid_vat_message'></span>
                </div>
              </div>
            </div>
          </form>

          <div className='modal-footer'>
            <button className='btn btn-secondary' type='button' onClick={toggle}>
              Cancel
            </button>
            <button
              className='btn btn-ibis'
              type='button'
              onClick={() => {
                handleCompanyFormSubmit();
              }}
            >
              Next
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default CompanyForm;
