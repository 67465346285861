import AppointmentForm from '../Appointment/AppointmentForm';
import IbisModal from './IbisModal';

const AppointmentModal = ({ modalIsOpen, toggle }) => {
  return (
    <IbisModal title={`Add Appointment`} modalIsOpen={modalIsOpen} toggle={toggle} className='Modal__Bootstrap modal-dialog modal-md'>
      <AppointmentForm toggle={toggle} />
    </IbisModal>
  );
};

export default AppointmentModal;
