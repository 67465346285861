const DateFormatSelect = ({ value, onChange, id, className, style }) => {
  return (
    <>
      <select name='date-format' className={className} value={value} id={id} onChange={onChange} style={style}>
        <option value='' hidden disabled>
          Select date format...
        </option>
        <option key='DD/MM/YYYY' value='%d/%m/%Y'>
          DD/MM/YYYY
        </option>
        <option key='MM/DD/YYYY' value='%m/%d/%Y'>
          MM/DD/YYYY
        </option>
        <option key='YYYY/MM/DD' value='%Y/%m/%d'>
          YYYY/MM/DD
        </option>
        <option key='YYYY/DD/MM' value='%Y/%d/%m'>
          YYYY/DD/MM
        </option>
      </select>
      <div id='validationfield_dateformat' className='invalid-feedback'>
        <span id='invalid_dateformat_message'></span>
      </div>
    </>
  );
};

export default DateFormatSelect;
