import { useEffect } from 'react';
import { createPopper } from '@popperjs/core';
import { deleteAppointment, getAppointments } from '../../api/appointmentFunctions';
import { useAppointment } from '../../context/appointmentContext';

const AppointmentActionsPopover = ({ id, showPopover, setAppointmentToEdit, openEditModal }) => {
  const { setAppointments } = useAppointment();

  useEffect(() => {
    const popover = document.querySelector(`#action-panel-${id}`);
    if (showPopover) {
      const actionsBtn = document.querySelector(`#action-btn-${id}`);
      createPopper(actionsBtn, popover, {
        placement: 'left',
      });

      popover.style.display = 'block';

      popover.addEventListener('mousedown', function preventActionBtnBlur(e) {
        return e.preventDefault();
      });
    } else {
      popover.style.display = 'none';
    }
  }, [id, showPopover]);

  const handleEditAppointment = () => {
    setAppointmentToEdit(id);
    openEditModal();
  };

  const handleDeleteAppointment = async () => {
    const response = await deleteAppointment(id);
    if (response) {
      getAppointments().then((response) => setAppointments(response));
    }
  };

  return (
    <dialog id={`action-panel-${id}`} className='popover' style={{ padding: 0, width: '15vw' }}>
      <ul>
        <div className='popover-list-container'>
          <li className='popover-link' onClick={() => handleEditAppointment()}>
            <i className='fas fa-fw fa-edit' style={{ marginRight: '0.65rem' }} />
            Edit Appointment Info
          </li>

          <hr />

          <li className='popover-delete' onClick={() => handleDeleteAppointment()}>
            <i className='fas fa-fw fa-trash' style={{ marginRight: '0.65rem' }} />
            Delete Appointment
          </li>
        </div>
      </ul>
    </dialog>
  );
};

export default AppointmentActionsPopover;
