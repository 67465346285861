const PoweredByIbisvisionBanner = () => {
  return (
    <div className='fixed-bottom bg-white d-flex align-items-center py-2'>
      <p className='my-0 ml-2'>{`Powered by`}</p>
      <a href='https://ibis.vision' rel='noreferrer' target='_blank'>
        <img src={'img/rebrand-logo.svg'} height='40' width='40' alt='ibisvision' />
      </a>
    </div>
  );
};

export default PoweredByIbisvisionBanner;
