import { useState, useEffect } from 'react';
import { useQuestionnaire } from '../../context/questionnaireContext';
import CheckboxQuestionForm from '../Questionnaire/CheckboxQuestionForm';

const EditQuestionForm = () => {
  const { saveQuestion, checkboxOptionsForEditing } = useQuestionnaire();
  const [questionText, setQuestionText] = useState('');
  const [questionType, setQuestionType] = useState('');
  const [localCheckboxOptions, setLocalCheckboxOptions] = useState([]);

  const questionData = {
    text: questionText,
    question_type: questionType,
    ...(questionType === 'checkbox' && { options: localCheckboxOptions }),
  };

  const getQuestionId = () => {
    return document.getElementById('edit_field_id').value;
  };

  const validateTextInputs = () => {
    const questionTextExists = questionText.trim().length;
    if (!questionTextExists) return alert('Please enter the question text.');
    const optionTextExists = localCheckboxOptions.every((opt) => opt.text.trim().length);
    if (!optionTextExists) return alert('One or more options have been left blank. Please fill them in or delete them.');
    return true;
  };

  const customSaveQuestion = async () => {
    if (questionType !== 'checkbox' || validateTextInputs()) {
      const existentQuestion = await saveQuestion(getQuestionId(), questionData);
      if (typeof existentQuestion == 'object') {
        document.querySelector('#editQuestionModal .close').click();
      }
    }
  };

  useEffect(() => {
    setQuestionText(document.getElementById('edit_field_text')?.value || '');
    setQuestionType(document.getElementById('edit_field_question_type')?.value || '');
    setLocalCheckboxOptions(checkboxOptionsForEditing);
  }, [checkboxOptionsForEditing]);

  return (
    <div className='modal fade' id='editQuestionModal' tabIndex='-1' role='dialog' aria-labelledby='editQuestionModalLabel' aria-hidden='true'>
      <div className='modal-dialog' role='document'>
        <div className='modal-content'>
          <div className='modal-header bg-primary-ibis'>
            <h5 className='modal-title' id={'editQuestionModalLabel'}>
              Edit Question
            </h5>
            <button className='close' type='button' data-dismiss='modal' aria-label='Close'>
              <span aria-hidden='true'>&times;</span>
            </button>
          </div>
          <div className='modal-body'>
            <form className='question' name='edit-question-form' autoComplete='off'>
              <div className='form-group row'>
                <div className='col-sm'>
                  <label htmlFor='edit_field_text'>Question text</label>
                  <input
                    type='text'
                    className='form-control form-control-question text'
                    id='edit_field_text'
                    value={questionText}
                    onChange={(e) => setQuestionText(e.target.value)}
                  />
                  <div id='validationedit_field_text' className='invalid-feedback'>
                    <span id='edit_invalid_text_message'></span>
                  </div>
                </div>
              </div>
              <div className='form-group row'>
                <div className='col-sm-6 mb-3 mb-sm-0'>
                  <label htmlFor='edit_field_question_type'>Question type</label>
                  <select
                    className='form-control form-control-question question_type form-select'
                    aria-label='Select a question type'
                    id='edit_field_question_type'
                    value={questionType}
                    onChange={(e) => setQuestionType(e.target.value)}
                  >
                    <option value='' hidden>
                      Select question type
                    </option>
                    <option value='text'>Text</option>
                    <option value='boolean'>True or False</option>
                    <option value='checkbox'>Checkbox</option>
                  </select>
                  <div id='validationedit_field_question_type' className='invalid-feedback'>
                    <span id='edit_invalid_question_type_message'></span>
                  </div>
                </div>
              </div>
              {questionType === 'checkbox' && <CheckboxQuestionForm options={localCheckboxOptions} setOptions={setLocalCheckboxOptions} />}
              <input type='hidden' className='form-control form-control-appointment id' id='edit_field_id' />
            </form>
          </div>
          <div className='modal-footer'>
            <button className='btn btn-secondary' type='button' data-dismiss='modal'>
              Cancel
            </button>
            <button
              className='btn btn-ibis'
              type='button'
              onClick={() => {
                customSaveQuestion();
              }}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditQuestionForm;
