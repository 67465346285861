const PrintKey = ({ testType }) => {
  return (
    <div className='d-flex align-items-center' style={{ gap: testType === 'stroke' ? '1.5rem' : '2rem' }}>
      <p className='m-0'>{'KEY:'}</p>

      {testType === 'stroke' && (
        <>
          <div className='d-flex flex-column justify-content-center align-items-center'>
            <div style={{ backgroundColor: '#000', height: '11.25px', width: '11.25px' }}></div>
            <p className='m-0'>
              <small>ORIENTATION CORRECT</small>
            </p>
          </div>
          <div className='d-flex flex-column justify-content-center align-items-center'>
            <div style={{ backgroundColor: '#fff', height: '11.25px', width: '11.25px', border: '1px solid #000' }}></div>
            <p className='m-0'>
              <small>ORIENTATION INCORRECT</small>
            </p>
          </div>
        </>
      )}

      {testType !== 'stroke' && (
        <div className='d-flex flex-column justify-content-center align-items-center'>
          <div style={{ backgroundColor: '#000', height: '11.25px', width: '11.25px', borderRadius: '50%' }}></div>
          <p className='m-0'>
            <small>SEEN</small>
          </p>
        </div>
      )}
      <div className='d-flex flex-column justify-content-center align-items-center'>
        <div style={{ backgroundColor: '#fff', height: '11.25px', width: '11.25px', border: '1px solid #000', borderRadius: '50%' }}></div>
        <p className='m-0'>
          <small>NOT SEEN</small>
        </p>
      </div>
      <div className='d-flex flex-column justify-content-center align-items-center'>
        <div
          className='d-flex justify-content-center align-items-center'
          style={{ height: '11.25px', width: '11.25px', transform: 'translate(-50%, -15%)' }}
        >
          {'\u2a09'}
        </div>
        <p className='m-0'>
          <small>NOT SHOWN</small>
        </p>
      </div>
      {testType !== 'stroke' && (
        <div className='d-flex flex-column justify-content-center align-items-center'>
          <div
            className='d-flex justify-content-center align-items-center font-weight-normal'
            style={{ height: '11.25px', width: '11.25px', transform: 'translate(-50%, -15%)', fontSize: '1.5rem', color: '#000' }}
          >
            {'\u22c7'}
          </div>
          <p className='m-0'>
            <small>BLINDSPOT</small>
          </p>
        </div>
      )}
    </div>
  );
};

export default PrintKey;
