import { useState } from 'react';

const useFilter = () => {
  const [filter, setFilter] = useState(null);
  const [dateTimeFilter, setDateTimeFilter] = useState({
    start: null,
    end: null,
    field: null,
  });

  const handleFilterChange = (name, value) => {
    setFilter({ name, value });
  };

  const handleDateTimeFilterChange = (start, end, field = 'datetime') => {
    setDateTimeFilter({ start, end, field });
  };

  const resetFilters = () => {
    setFilter(null);
    setDateTimeFilter({ start: null, end: null, field: null });
  };

  return {
    filter,
    handleFilterChange,
    dateTimeFilter,
    handleDateTimeFilterChange,
    resetFilters,
  };
};

export default useFilter;
