import QuestionnaireContent from './QuestionnaireContent';
import { useQuestionnaire } from '../../context/questionnaireContext';

import { useEffect } from 'react';

const Questionnaire = () => {
  const { getQuestionnaires } = useQuestionnaire();

  useEffect(() => {
    getQuestionnaires();
  }, []);

  return <QuestionnaireContent />;
};

export default Questionnaire;
