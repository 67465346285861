import CompaniesList from './CompaniesList';
import { useModal } from '../../hooks';
import CompanyModal from '../Modals/CompanyModal';

const CompanyContent = () => {
  const { modalIsOpen, toggle } = useModal();

  return (
    <>
      <CompanyModal modalIsOpen={modalIsOpen} toggle={toggle} />
      <div className='container-fluid'>
        <div className='d-sm-flex align-items-center justify-content-end'>
          <button className='d-none d-sm-inline-block btn btn-sm btn-ibis shadow-sm' type='button' onClick={toggle}>
            <i className='fas fa-plus fa-sm text-white-50'></i>&nbsp;Add
          </button>
        </div>
        <div className='row'>
          <div className='col-xl-12 col-md-6 mb-4'>
            <CompaniesList />
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanyContent;
