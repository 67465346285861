import { useState, useEffect } from 'react';
import { useUser } from '../../context/userContext';
import { useAppointment } from '../../context/appointmentContext';
import { useQuestionnaire } from '../../context/questionnaireContext';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import LoadingSpinner from '../Misc/LoadingSpinner';
import PreScreeningSelection from './PreScreeningSelection';
import CreatableSelect from 'react-select/creatable';
import { dateFormatMap } from '../../helper/IbisUser';

const loggedUser = JSON.parse(localStorage.getItem('loggedUser'));

const AppointmentForm = ({ toggle }) => {
  const { patients, opticians } = useUser();
  const { addAppointment } = useAppointment();
  const [optician, setOptician] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [startTime, setStartTime] = useState('');
  const [expectedEndDate, setExpectedEndDate] = useState('');
  const [expectedEndTime, setExpectedEndTime] = useState('');
  const [patientObj, setPatientObj] = useState({ email: '', label: '' });
  const [questionnaire, setQuestionnaire] = useState('');
  const [appointmentDuration, setAppointmentDuration] = useState('5');
  const [appointmentPending, setAppointmentPending] = useState(false);
  const [selectedPreScreeningTests, setSelectedPreScreeningTests] = useState([]);
  const [prescriptionCheck, setPrescriptionCheck] = useState(false);
  const { questionnairesEnabled, questionnaires, getQuestionnaires } = useQuestionnaire();

  const handlePatientSelection = (patient) => {
    if (!patient) return setPatientObj({ email: '', label: '' });
    if (patient.__isNew__) return setPatientObj({ email: patient.value, is_new: true });
    const { email, full_name } = patient.value;
    return setPatientObj({ email, full_name, is_new: false });
  };

  const appointmentObject = () => {
    return {
      optician: optician,
      start: `${startDate?.toLocaleDateString('en-GB')} ${startTime}`,
      expected_end: expectedEndDate + ' ' + expectedEndTime,
      status: '',
      patient: patientObj,
      questionnaire: questionnaire,
      prescreening: { questionnaires: [], tests: selectedPreScreeningTests, prescription: prescriptionCheck },
    };
  };

  const getOpticianField = () => {
    if (loggedUser.role === 'admin') {
      return (
        <select
          className='form-control form-control-appointment optician form-select'
          aria-label='Select an optician'
          id='field_optician'
          onChange={(e) => setOptician(e.target.value)}
          value={optician}
          style={{ color: `${optician.length ? '#6e707e' : '#858796'}` }}
        >
          <option value='' disabled hidden>
            Select a clinician
          </option>
          {opticians
            ? opticians.map((optician_option) => (
                <option key={optician_option.id} value={optician_option.id}>
                  {optician_option.full_name}
                </option>
              ))
            : ''}
        </select>
      );
    } else if (loggedUser.role === 'optician') {
      return <input type='hidden' className='form-control form-control-appointment optician' id='field_optician' placeholder='Clinician' />;
    }
  };

  const getPatientField = () => {
    const patientOptions = patients?.map((patient) => {
      return { value: patient, label: patient.full_name };
    });

    return (
      <CreatableSelect
        isClearable
        options={patientOptions}
        value={patientObj.label}
        id='field_patient'
        onChange={(e) => handlePatientSelection(e)}
        placeholder='Select from list or type an email...'
        styles={{ valueContainer: (base) => ({ ...base, paddingLeft: '0.75rem' }) }}
      />
    );
  };

  const getQuestionnaireField = () => {
    return (
      <select
        className='form-control form-control-appointment questionnaire form-select'
        aria-label='Select a questionnaire'
        id='field_questionnaire'
        onChange={(e) => setQuestionnaire(e.target.value)}
        value={questionnaire}
        style={{ color: `${questionnaire.length ? '#6e707e' : '#858796'}` }}
      >
        <option value=''>No questionnaire selected</option>
        {questionnaires?.length > 0
          ? questionnaires.map((questionnaire_option) => (
              <option key={questionnaire_option.id} value={questionnaire_option.id}>
                {questionnaire_option.name}
              </option>
            ))
          : ''}
      </select>
    );
  };

  const pad = (num, size) => {
    num = num.toString();
    while (num.length < size) num = '0' + num;
    return num;
  };

  useEffect(() => {
    const start_date = startDate?.toLocaleDateString('en-GB') || '';
    const start_time = startTime;
    var parts = start_date.split('/');
    var time_parts = start_time.split(':');
    var initial_date = new Date(
      parseInt(parts[2], 10),
      parseInt(parts[1], 10) - 1,
      parseInt(parts[0], 10),
      parseInt(time_parts[0], 10),
      parseInt(time_parts[1], 10)
    );
    var expected_end_date = new Date(initial_date.getTime() + parseInt(appointmentDuration) * 60000);
    setExpectedEndDate(
      pad(expected_end_date.getDate(), 2) + '/' + pad(expected_end_date.getMonth() + 1, 2) + '/' + expected_end_date.getFullYear().toString()
    );

    setExpectedEndTime(pad(expected_end_date.getHours(), 2) + ':' + pad(expected_end_date.getMinutes(), 2));
  }, [appointmentDuration, startDate, startTime]);

  const customAddAppointment = () => {
    const delay = setInterval(() => setAppointmentPending(true), 300);
    addAppointment(appointmentObject()).then((response) => {
      clearInterval(delay);
      setAppointmentPending(false);
      if (response) {
        toggle();
      }
    });
  };

  useEffect(() => {
    if (loggedUser.role === 'optician') {
      setOptician(loggedUser.id);
    }
  }, []);

  useEffect(() => {
    // Only active questionnaires (with at least one question)
    getQuestionnaires(true);
  }, []);

  return (
    <>
      {appointmentPending ? <LoadingSpinner message={'Creating appointment...'} /> : null}
      <form className='appointment' name='appointment-form' autoComplete='off'>
        <div className='form-group row'>
          <div className='col'>
            {getOpticianField()}
            <div id='validationfield_optician' className='invalid-feedback'>
              <span id='invalid_optician_message'></span>
            </div>
          </div>
        </div>
        <div className='form-group row'>
          <div className='col'>
            {getPatientField()}
            <div id='validationfield_patient' className='invalid-feedback'>
              <span id='invalid_patient_message'></span>
            </div>
          </div>
        </div>
        <div className='form-group row'>
          <div className='col-sm-6'>
            <DatePicker
              placeholderText='Start date'
              id='field_start_date'
              dateFormat={dateFormatMap[loggedUser?.company_info?.dateformat || '%d/%m/%Y']}
              selected={startDate}
              onChange={(e) => setStartDate(e)}
              className='form-control form-control-appointment start_date'
            />
            <div id='validationfield_start_date' className='text-danger mt-1'>
              <span id='invalid_start_date_message'></span>
            </div>
          </div>
          <div className='col-sm-6'>
            <input
              type='text'
              className='form-control form-control-appointment start_time'
              id='field_start_time'
              placeholder='Start time'
              value={startTime}
              onChange={(e) => setStartTime(e.target.value)}
            />
            <div id='validationfield_start_time' className='invalid-feedback'>
              <span id='invalid_start_time_message'></span>
            </div>
          </div>
        </div>
        <div className='form-group row'>
          <div className='col-sm-12'>
            <select
              className='form-control form-control-appointment duration form-select'
              aria-label='Appointment duration in minutes'
              id='field_duration'
              onChange={(e) => {
                setAppointmentDuration(e.target.value);
              }}
              value={appointmentDuration}
            >
              <option value='5'>5 minutes</option>
              <option value='10'>10 minutes</option>
              <option value='15'>15 minutes</option>
              <option value='20'>20 minutes</option>
              <option value='25'>25 minutes</option>
              <option value='30'>30 minutes</option>
              <option value='35'>35 minutes</option>
              <option value='40'>40 minutes</option>
              <option value='45'>45 minutes</option>
              <option value='50'>50 minutes</option>
              <option value='55'>55 minutes</option>
              <option value='60'>60 minutes</option>
            </select>
          </div>
        </div>
        <div className='form-group row d-none'>
          <div className='col-sm-6'>
            <input
              type='hidden'
              className='form-control form-control-appointment expected_end_date field-date-picker'
              id='field_expected_end_date'
              placeholder='Expected end date'
              value={expectedEndDate}
            />
            <div id='validationfield_expected_end_date' className='invalid-feedback'>
              <span id='invalid_expected_end_date_message'></span>
            </div>
          </div>
          <div className='col-sm-6'>
            <input
              type='hidden'
              className='form-control form-control-appointment expected_end_time '
              id='field_expected_end_time'
              placeholder='Expected end time'
              value={expectedEndTime}
            />
            <div id='validationfield_expected_end_time' className='invalid-feedback'>
              <span id='invalid_expected_end_time_message'></span>
            </div>
          </div>
        </div>
        {questionnairesEnabled && (
          <div className='form-group row'>
            <div className='col'>
              {getQuestionnaireField()}
              <div id='validationfield_questionnaire' className='invalid-feedback'>
                <span id='invalid_questionnaire_message'></span>
              </div>
            </div>
          </div>
        )}
      </form>
      <PreScreeningSelection
        selectedTests={selectedPreScreeningTests}
        setSelectedTests={setSelectedPreScreeningTests}
        prescriptionCheck={prescriptionCheck}
        setPrescriptionCheck={setPrescriptionCheck}
      />
      <div className='modal-footer'>
        <button className='btn btn-secondary' type='button' onClick={toggle}>
          Cancel
        </button>
        <button
          className='btn btn-ibis'
          type='button'
          onClick={() => {
            customAddAppointment();
          }}
        >
          Add
        </button>
      </div>
    </>
  );
};

export default AppointmentForm;
