import { createContext, useContext, useEffect, useState } from 'react';
import { useCrud } from '../context/crudContext';
import { getCurrentCompany } from '../api/companyFunctions';

const QuestionnaireContext = createContext();

export const useQuestionnaire = () => {
  return useContext(QuestionnaireContext);
};

const loggedUser = JSON.parse(localStorage.getItem('loggedUser'));

export const QuestionnaireProvider = ({ children }) => {
  const { fetchObjects, addObject, saveObject, detailObject } = useCrud();
  const [questionnairesEnabled, setQuestionnairesEnabled] = useState(false);
  const [questionnaires, setQuestionnaires] = useState(null);
  const [questionnaire, setQuestionnaire] = useState([]);
  const [questions, setQuestions] = useState(null);
  const [questionnaireQuestions, setQuestionnaireQuestions] = useState(null);
  const [checkboxOptionsForEditing, setCheckboxOptionsForEditing] = useState([]);

  useEffect(() => {
    if (!loggedUser) return;
    getCurrentCompany().then((company) => {
      if (company.questionnaires_enabled) setQuestionnairesEnabled(true);
    });
  }, []);

  const getApiUrl = (domain, objectId) => {
    if (objectId) {
      return `/api/${domain}/${objectId}/`;
    }

    return `/api/${domain}/`;
  };

  const getQuestionnaireApiUrl = (objectId) => {
    return getApiUrl('company-questionnaires', objectId);
  };

  const getQuestionApiUrl = (objectId) => {
    return getApiUrl('company-questions', objectId);
  };

  const getQuestionnaireQuestionApiUrl = (objectId) => {
    return getApiUrl('questionnaire/questionnaire-questions', objectId);
  };

  const getQuestionPathApiUrl = (questionType) => {
    const domain = `questionnaire-${questionType}-question-path`;
    return `/api/questionnaire/${domain}/`;
  };

  const getQuestionAnswerApiUrl = (questionType, questionAnswerId) => {
    const domain = `questionnaire-${questionType}-answers`;

    if (questionAnswerId) {
      return `/api/${domain}/${questionAnswerId}/`;
    }

    return `/api/${domain}/`;
  };

  const addQuestionnaire = async (questionnaireData) => {
    const newQuestionnaire = await addObject(getQuestionnaireApiUrl(), questionnaireData);
    if (typeof newQuestionnaire === 'object') {
      setQuestionnaires([...questionnaires, newQuestionnaire]);
      return newQuestionnaire;
    }
  };

  const getQuestionnaires = async (active) => {
    let apiUrl = getQuestionnaireApiUrl();
    if (active === true) {
      apiUrl = getQuestionnaireApiUrl('active');
    }
    const dataFromServer = await fetchQuestionnaires(apiUrl);
    const questionnaireRecords = dataFromServer.results || [];

    setQuestionnaires(questionnaireRecords);

    return dataFromServer;
  };

  const fetchQuestionnaires = async (apiUrl) => {
    return await fetchObjects(apiUrl);
  };

  const saveQuestionnaire = async (questionnaireId, questionnaireData) => {
    delete questionnaireData.id;
    const existentQuestionnaire = await saveObject(getQuestionnaireApiUrl(questionnaireId), questionnaireData);
    if (typeof existentQuestionnaire === 'object') {
      getQuestionnaires();
      return existentQuestionnaire;
    }
  };

  const deleteQuestionnaire = async (questionnaireId) => {
    if (window.confirm('Are you sure you want to delete this questionnaire?') === true) {
      await detailObject(getQuestionnaireApiUrl(questionnaireId), 'DELETE');
      await getQuestionnaires();
      return true;
    } else {
      return false;
    }
  };

  const editQuestionnaire = async (questionnaireId) => {
    Array.from(document.querySelectorAll('.is-invalid')).forEach((el) => el.classList.remove('is-invalid'));
    const res = await detailObject(getQuestionnaireApiUrl(questionnaireId), 'GET');
    const questionnaireObject = await res.json();

    for (const fieldname in questionnaireObject) {
      const field_element = document.getElementById('edit_field_' + fieldname);
      if (field_element) {
        field_element.value = questionnaireObject[fieldname];
      }
    }

    return questionnaireObject;
  };

  const getQuestionnaire = async (questionnaireId) => {
    const res = await detailObject(getQuestionnaireApiUrl(questionnaireId), 'GET');
    const questionnaireObject = await res.json();
    setQuestionnaire(questionnaireObject || null);
    return questionnaireObject;
  };

  const addQuestion = async (questionData) => {
    const newQuestion = await addObject(getQuestionApiUrl(), questionData);
    if (typeof newQuestion === 'object') {
      setQuestions([...questions, newQuestion]);
      return newQuestion;
    }
  };

  const fetchQuestions = async (apiUrl) => {
    return await fetchObjects(apiUrl);
  };

  const getQuestions = async (questionnaireId, available) => {
    let apiUrl = getQuestionApiUrl();
    if (questionnaireId) {
      if (available) {
        apiUrl = `${getQuestionApiUrl()}available/?questionnaire=${questionnaireId}`;
      } else {
        apiUrl = getQuestionnaireApiUrl(`${questionnaireId}/questions`);
      }
    }
    const dataFromServer = await fetchQuestions(apiUrl);
    const questionsRecords = dataFromServer.results || [];

    setQuestions(questionsRecords);

    return dataFromServer;
  };

  const editQuestion = async (questionId) => {
    Array.from(document.querySelectorAll('.is-invalid')).forEach((el) => el.classList.remove('is-invalid'));
    const res = await detailObject(getQuestionApiUrl(questionId), 'GET');
    const questionObject = await res.json();
    if (questionObject.options) {
      setCheckboxOptionsForEditing(questionObject.options);
    } else
      setCheckboxOptionsForEditing([
        { position: 1, text: '' },
        { position: 2, text: '' },
      ]);
    for (const fieldname in questionObject) {
      const field_element = document.getElementById('edit_field_' + fieldname);
      if (field_element) {
        field_element.value = questionObject[fieldname];
      }
    }

    return questionObject;
  };

  const deleteQuestion = async (questionId) => {
    if (window.confirm('Are you sure you want to delete this question?') === true) {
      await detailObject(getQuestionApiUrl(questionId), 'DELETE');
      getQuestions();
      return true;
    } else {
      return false;
    }
  };

  const saveQuestion = async (questionId, questionData) => {
    delete questionData.id;
    const existentQuestion = await saveObject(getQuestionApiUrl(questionId), questionData);
    if (typeof existentQuestion === 'object') {
      getQuestions();
      return existentQuestion;
    }
  };

  const addQuestionnaireQuestion = async (questionnaireQuestionData) => {
    const newQuestionnaireQuestion = await addObject(getQuestionnaireQuestionApiUrl(), questionnaireQuestionData);
    if (typeof newQuestionnaireQuestion === 'object') {
      return newQuestionnaireQuestion;
    }
  };

  const fetchQuestionnaireQuestions = async (apiUrl) => {
    return await fetchObjects(apiUrl);
  };

  const getQuestionnaireQuestions = async (questionId, questionnaireId) => {
    if (questionId === undefined) {
      questionId = '';
    }

    if (questionnaireId === undefined) {
      questionnaireId = '';
    }

    let apiUrl = `${getQuestionnaireQuestionApiUrl()}?question=${questionId}&questionnaire=${questionnaireId}`;

    const dataFromServer = await fetchQuestionnaireQuestions(apiUrl);
    const questionnaireQuestions = dataFromServer.results || [];

    setQuestionnaireQuestions(questionnaireQuestions);

    return dataFromServer;
  };

  const deleteQuestionnaireQuestion = async (questionnaire_question_id) => {
    if (window.confirm('Are you sure you want to remove this question from the questionnaire?') === true) {
      await detailObject(getQuestionnaireQuestionApiUrl(questionnaire_question_id), 'DELETE');
      return true;
    } else {
      return false;
    }
  };

  const updateQuestionsOrder = async (questionnaireId) => {
    return await detailObject(`${getQuestionnaireApiUrl(questionnaireId)}save_questions_order/`);
  };

  const addQuestionPath = async (questionPathData, questionType) => {
    const newQuestionPath = await addObject(getQuestionPathApiUrl(questionType), questionPathData);

    if (typeof newQuestionPath === 'object') {
      return newQuestionPath;
    }
  };

  const getQuestionPath = async (originQuestion) => {
    const dataFromServer = await fetchObjects(
      `${getQuestionPathApiUrl(originQuestion.question_type)}?origin_questionnaire_question=${originQuestion.id}`,
      'GET'
    );
    return dataFromServer.results || [];
  };

  const deleteQuestionPath = async (originQuestion) => {
    const questionPathResults = await getQuestionPath(originQuestion);
    const fetchPromises = [];
    questionPathResults.forEach((question_path_result) => {
      const fetchPromise = detailObject(`${getQuestionPathApiUrl(originQuestion.question_type)}${question_path_result.id}`, 'DELETE');
      fetchPromises.push(fetchPromise);
    });
    await Promise.all(fetchPromises);
  };

  const setInitialQuestionnaireQuestion = async (questionnaireQuestionId) => {
    const url = `${getQuestionnaireQuestionApiUrl(questionnaireQuestionId)}set_initial/`;
    await detailObject(url, 'GET');
  };

  const getAppointmentQuestionnaire = async (appointmentQuestionnaireId) => {
    const res = await detailObject(`/api/appointment-questionnaire/${appointmentQuestionnaireId}/`);
    const appointmentQuestionnaireObject = await res.json();
    return appointmentQuestionnaireObject;
  };

  const addQuestionnaireAnswer = async (questionnaireAnswerData, questionType) => {
    if (questionnaireAnswerData.id) {
      await deleteQuestionnaireAnswer(questionType, questionnaireAnswerData.id);
      delete questionnaireAnswerData.id;
    }

    const questionnaireAnswerResponse = await addObject(getQuestionAnswerApiUrl(questionType), questionnaireAnswerData);

    if (typeof questionnaireAnswerResponse === 'object') {
      return questionnaireAnswerResponse;
    }
  };

  const deleteQuestionnaireAnswer = async (questionType, questionnaireAnswerId) => {
    await detailObject(getQuestionAnswerApiUrl(questionType, questionnaireAnswerId), 'DELETE');
  };

  return (
    <QuestionnaireContext.Provider
      value={{
        questionnairesEnabled,
        questionnaires,
        getQuestionnaires,
        addQuestionnaire,
        saveQuestionnaire,
        deleteQuestionnaire,
        editQuestionnaire,
        getQuestionnaire,
        questionnaire,
        addQuestion,
        editQuestion,
        saveQuestion,
        getQuestions,
        deleteQuestion,
        questions,
        addQuestionnaireQuestion,
        getQuestionnaireQuestions,
        questionnaireQuestions,
        deleteQuestionnaireQuestion,
        updateQuestionsOrder,
        setInitialQuestionnaireQuestion,
        addQuestionPath,
        getQuestionPath,
        deleteQuestionPath,
        getAppointmentQuestionnaire,
        addQuestionnaireAnswer,
        setQuestionnaireQuestions,
        checkboxOptionsForEditing,
      }}
    >
      {children}
    </QuestionnaireContext.Provider>
  );
};
