import { useState } from 'react';
import AppointmentDetailsCard from './AppointmentDetailsCard';

const WaitingRoomList = ({ openAppointments, updateWaitingRoomAppointments, selectAppointment, selectedAppointment }) => {
  const [detailsPanePosition, setDetailsPanePosition] = useState({});

  const positionDetailsPane = (appointmentId) => {
    const appointmentsListPosition = document.getElementById('waiting-room-appointments-list').getBoundingClientRect();
    const appointmentCard = document.getElementById(`card-${appointmentId}`);
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const appointmentCardPosition = appointmentCard.getBoundingClientRect();

    const isLastCard = appointmentCard.parentElement.lastChild === appointmentCard;

    setDetailsPanePosition({
      left: appointmentCardPosition.left + scrollLeft,
      top: isLastCard ? null : appointmentsListPosition.top + scrollTop,
      width: appointmentCardPosition.width,
      bottom: isLastCard ? 0 : null,
      scrollTop: scrollTop,
    });
  };

  return (
    <>
      {selectedAppointment ? <AppointmentDetailsCard appointment={selectedAppointment} position={detailsPanePosition} /> : null}
      <div className='col-xl-6 col-md-6 mb-4'>
        <div className='card shadow mb-4' id='waiting-room-appointments-list'>
          <div className='card-header bg-primary-ibis py-3'>
            <div className='row'>
              <div className='col-10'>
                <h5 className='m-0 font-weight-bold text-white'>Waiting Room</h5>
              </div>
              <div className='col'>
                <button
                  id='waiting-room-refresh-button'
                  className='btn btn-sm btn-circle btn-ibis float-right'
                  onClick={(e) => {
                    updateWaitingRoomAppointments();
                  }}
                >
                  <i className='fas fa-sync-alt'></i>
                </button>
              </div>
            </div>
          </div>
          <div className='card-body'>
            {!openAppointments
              ? 'Loading...'
              : !openAppointments.length
              ? 'No appointments to display.'
              : openAppointments.map((appointment) => {
                  if (appointment.status === 'open' || appointment.status === 'pending') {
                    return (
                      <div
                        className={`room-cards card shadow mb-4 ${selectedAppointment?.id === appointment.id ? 'border border-ibis' : null}`}
                        id={`card-${appointment.room_name}`}
                        key={appointment.id}
                      >
                        <div className='card-body'>
                          <div className='row mb-2'>
                            <div className='col'>
                              <h6 className='m-0 font-weight-bold text-ibis text-center'>{appointment.patient_full_name}</h6>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col'>
                              <span className='room-badge badge badge-success float-right m-1 d-none' id={`badge-${appointment.room_name}`}>
                                PATIENT IS CONNECTED
                              </span>
                              <div className='table-responsive'>
                                <table className='table table-sm' width='100%' cellSpacing='0'>
                                  <tbody>
                                    <tr>
                                      <td>Date: {appointment.start.split(' ')[0]}</td>
                                      <td rowSpan='3' className='p-4'>
                                        <button
                                          className='btn btn-ibis w-100 btn-lg'
                                          type='button'
                                          id={`btn-${appointment.room_name}`}
                                          onClick={() => {
                                            selectAppointment(appointment);
                                            positionDetailsPane(appointment.room_name);
                                          }}
                                        >
                                          Details
                                        </button>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>Time: {appointment.start.split(' ')[1]}</td>
                                    </tr>
                                    <tr>
                                      <td>Duration: {appointment.appointment_duration}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  } else return null;
                })}
          </div>
        </div>
      </div>
    </>
  );
};

export default WaitingRoomList;
