import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getAppointments } from '../api/appointmentFunctions';
import { websocketManager } from '../helper/websocketManager';
import WaitingRoomList from '../components/WaitingRoom/WaitingRoomList';
import parseDateAndSort from '../components/DataTable/parseDateAndSort';
import { useAppointment } from '../context/appointmentContext';

const WaitingRoom = () => {
  const [openAppointments, setOpenAppointments] = useState(null);
  const [selectedAppointment, setSelectedAppointment] = useState(null);

  const { editAppointment } = useAppointment();

  const { origin } = useParams();

  const loggedUser = JSON.parse(sessionStorage.getItem('loggedUser'));

  const logout = () => {
    localStorage.removeItem('__endpoint__');
    localStorage.removeItem('token');
    localStorage.removeItem('loggedUser');
    localStorage.removeItem('__ibis_token__');
    window.location.href = process.env.PUBLIC_URL + '/login';
  };

  const updateWaitingRoomAppointments = () => {
    var divs = document.querySelectorAll('.room-badge');
    for (var i = 0; i < divs.length; i++) {
      divs[i].classList.add('d-none');
    }
    getAppointments('?status=open').then((response) => {
      setOpenAppointments(response.sort((appt1, appt2) => parseDateAndSort(appt1.start_timestamp, appt2.start_timestamp)));
    });
  };

  const selectAppointment = async (appointment) => {
    const appointmentFromServer = await editAppointment(appointment.id);
    setSelectedAppointment(appointmentFromServer);
  };

  useEffect(() => {
    updateWaitingRoomAppointments();
    const interval = setInterval(() => {
      updateWaitingRoomAppointments();
    }, 30000);

    return () => {
      clearInterval(interval);
    };
  }, [setOpenAppointments]);

  useEffect(() => {
    if (!openAppointments?.length || loggedUser?.superuser) return;

    const listenerFunction = (messageData) => {
      if (messageData.message.message.content.type === 'heartBeatResponse') {
        updateConnectedRooms(messageData.message.message.content.room_name);
      }

      if (messageData.message.message.content.type === 'endedSession') {
        updateWaitingRoomAppointments();
        setSelectedAppointment(null);
      }
    };

    openAppointments.forEach(async (appointment) => {
      let websocketManagerObject = await websocketManager(appointment.room_name, listenerFunction);
      websocketManagerObject.postMessage({
        message: {
          destination: 'patient',
          content: { type: 'heartBeat', room_name: appointment.room_name },
        },
      });
    });
  }, [openAppointments, loggedUser]);

  const updateConnectedRooms = (room_name) => {
    let badgeElement = document.getElementById('badge-' + room_name);

    if (badgeElement) {
      badgeElement.classList.remove('d-none');
    }
  };

  return (
    <>
      <div id='content-wrapper' className='d-flex flex-column'>
        <div id='content'>
          {origin === 'ros' ? (
            <div className='row'>
              <div className='col'>
                <button className='dropdown-item text-ibis' onClick={logout}>
                  <i className='text-ibis fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400'></i>
                  Logout
                </button>
              </div>
            </div>
          ) : null}
          <div className='container-fluid'>
            <div className='row'>
              <WaitingRoomList
                openAppointments={openAppointments}
                updateWaitingRoomAppointments={updateWaitingRoomAppointments}
                selectedAppointment={selectedAppointment}
                selectAppointment={selectAppointment}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WaitingRoom;
