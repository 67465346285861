import { useState } from 'react';

import { useUser } from '../../context/userContext';

const Register = () => {
  const { addInvitedUser } = useUser();
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [phone, setPhone] = useState('');

  const userObject = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const invitation_code = urlParams.get('invitation');
    const email_address = urlParams.get('email');
    return {
      first_name: firstname,
      last_name: lastname,
      username: username,
      password: password,
      password2: password2,
      profile: { phone: phone },
      invitation_code: invitation_code,
      email: email_address,
    };
  };

  const addNewInvitedUser = async () => {
    Array.from(document.querySelectorAll('.is-invalid')).forEach((el) => el.classList.remove('is-invalid'));
    const invisibleEmailField = document.getElementById('invalid_email_message');
    const newInvitedUser = await addInvitedUser(userObject(), true);

    if (typeof newInvitedUser == 'object') {
      document.querySelectorAll('.patient-form input').forEach((el) => {
        el.value = '';
      });
      window.location.href = process.env.PUBLIC_URL + '/login';
    } else if (invisibleEmailField.innerText) {
      invisibleEmailField.innerText = 'A user with this email address already exists.';
    }
  };

  return (
    <div className='container'>
      <div className='card o-hidden border-0 shadow-lg my-5'>
        <div className='card-body p-0'>
          <div className='row'>
            <div className='col-lg-5 d-none d-lg-block bg-register-image'></div>
            <div className='col-lg-7'>
              <div className='p-5'>
                <div className='text-center'>
                  <h1 className='h4 text-ibis mb-4'>Create your account!</h1>
                </div>
                <form className='user' name='user-form'>
                  <div className='form-group row'>
                    <div className='col-sm-6 mb-3 mb-sm-0'>
                      <input
                        type='text'
                        className='form-control form-control-user first_name'
                        id='field_first_name'
                        placeholder='First Name'
                        value={firstname}
                        onChange={(e) => setFirstname(e.target.value)}
                      />
                      <div id='validationfield_first_name' className='invalid-feedback'>
                        <span id='invalid_first_name_message'></span>
                      </div>
                    </div>
                    <div className='col-sm-6'>
                      <input
                        type='text'
                        className='form-control form-control-user last_name'
                        id='field_last_name'
                        placeholder='Last Name'
                        value={lastname}
                        onChange={(e) => setLastname(e.target.value)}
                      />
                      <div id='validationfield_last_name' className='invalid-feedback'>
                        <span id='invalid_last_name_message'></span>
                      </div>
                    </div>
                  </div>
                  <div className='form-group row'>
                    <div className='col-sm-6'>
                      <input
                        type='text'
                        className='form-control form-control-user phone'
                        id='field_phone'
                        placeholder='Phone'
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                      <div id='validationfield_phone' className='invalid-feedback'>
                        <span id='invalid_phone_message'></span>
                      </div>
                    </div>
                  </div>
                  <div className='form-group row'>
                    <div className='col-sm'>
                      <input
                        type='text'
                        className='form-control form-control-user username'
                        id='field_username'
                        placeholder='Username'
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                      />
                      <div id='validationfield_username' className='invalid-feedback'>
                        <span id='invalid_username_message'></span>
                      </div>
                    </div>
                  </div>
                  <div className='form-group row'>
                    <div className='col-sm-6 mb-3 mb-sm-0'>
                      <input
                        type='password'
                        className='form-control form-control-user password'
                        id='field_password'
                        placeholder='Password'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <div id='validationfield_password' className='invalid-feedback'>
                        <span id='invalid_password_message'></span>
                      </div>
                    </div>
                    <div className='col-sm-6'>
                      <input
                        type='password'
                        className='form-control form-control-user password2'
                        id='field_password2'
                        placeholder='Repeat Password'
                        value={password2}
                        onChange={(e) => setPassword2(e.target.value)}
                      />
                      <div id='validationfield_password2' className='invalid-feedback'>
                        <span id='invalid_password2_message'></span>
                      </div>
                    </div>
                  </div>
                </form>

                <button className='btn btn-ibis btn-user btn-block' style={{ marginBottom: '20px' }} onClick={() => addNewInvitedUser()}>
                  Create
                </button>
                <div id='field_email'></div>
                <div id='validationfield_email' className='invalid-feedback text-center'>
                  <span id='invalid_email_message'></span>
                </div>
                <div className='text-danger text-center'>
                  <span id='registration-error-message'></span>
                </div>
                <hr />
                <div className='text-center'>
                  <a className='small text-ibis' href={`${process.env.PUBLIC_URL}/login`}>
                    Already have an account? Login!
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
