import QuestionContent from './QuestionContent';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuestionnaire } from '../../context/questionnaireContext';

const Question = ({ addQuestionPath, deleteQuestionPath, setInitialQuestionnaireQuestion }) => {
  const { getQuestionnaire, getQuestions } = useQuestionnaire();

  const { questionnaireId } = useParams();

  useEffect(async () => {
    if (questionnaireId) {
      const questionnaireFromServer = await getQuestionnaire(questionnaireId);
    }

    getQuestions();
  }, []);

  return (
    <QuestionContent
      addQuestionPath={addQuestionPath}
      deleteQuestionPath={deleteQuestionPath}
      setInitialQuestionnaireQuestion={setInitialQuestionnaireQuestion}
    />
  );
};

export default Question;
