import { useEffect, useState } from 'react';
import { useRecheck } from '../../context/recheckContext';
import RecheckSessionsList from './RecheckSessionsList';
import ToggleSwitch from './ToggleSwitch';

const RecheckSessions = () => {
  const {
    sessions: allSessions,
    currentPage,
    itemsPerPage,
    handlePageChange,
    handleRowsPerPageChange,
    filter,
    handleFilterChange,
    dateTimeFilter,
    handleDateTimeFilterChange,
    sortField,
    sortDirection,
    handleSort,
    resetAllFilters,
    setFiltersObject,
    fetchSessions
  } = useRecheck();
  const [showCompletedSessions, setShowCompletedSessions] = useState(false);
  const [showClosedSessions, setShowClosedSessions] = useState(false);

  const resetAndHandleFilterChange = (name, value) => {
    resetAllFilters();
    handleFilterChange(name, value);
  };

  const resetAndHandleDateTimeFilterChange = (start, end, field = 'datetime') => {
    resetAllFilters();
    handleDateTimeFilterChange(start, end, field);
  };

  useEffect(() => {
    const apiFilters = {};

    apiFilters.show_completed = showCompletedSessions;
    apiFilters.show_closed = showClosedSessions;

    if (sortField) {
      apiFilters.sort = sortDirection === 'desc' ? `-${sortField}` : sortField;
    }

    if (filter?.name && filter?.value) apiFilters[filter.name] = filter.value;
    if (dateTimeFilter) {
      apiFilters[`start_${dateTimeFilter.field}`] = dateTimeFilter.start;
      apiFilters[`end_${dateTimeFilter.field}`] = dateTimeFilter.end;
    }
    apiFilters.page = currentPage;
    apiFilters.page_size = itemsPerPage;

    setFiltersObject(apiFilters);
  }, [showCompletedSessions, showClosedSessions, sortField, sortDirection, filter, dateTimeFilter, currentPage, itemsPerPage, setFiltersObject]);

  useEffect(() => {
    fetchSessions();
  }, [fetchSessions]);

  useEffect(() => {
    return () => {
      setFiltersObject({show_completed: false, show_closed: false});
      resetAllFilters();
    };
  }, []);


  return (
    <div className='container-fluid'>
      <ToggleSwitch
        id='showCompletedSessionsSwitch'
        checked={showCompletedSessions}
        onChange={() => setShowCompletedSessions(!showCompletedSessions)}
        label='Include Complete Sessions'
      />
      <ToggleSwitch
        id='showInvalidSessionsSwitch'
        checked={showClosedSessions}
        onChange={() => setShowClosedSessions(!showClosedSessions)}
        label='Include Closed Sessions'
      />
      <RecheckSessionsList
        itemsPerPage={itemsPerPage}
        sessions={allSessions?.results || []}
        totalRows={allSessions?.count}
        currentPage={currentPage}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        onFilterChange={resetAndHandleFilterChange}
        onSort={handleSort}
        onDateTimeFilterChange={resetAndHandleDateTimeFilterChange}
      />
    </div>
  );
};

export default RecheckSessions;
