import PatientContent from './PatientContent';
import { useEffect } from 'react';
import { useUser } from '../../context/userContext';
import { getUsers } from '../../api/userFunctions';
import { IbisPatient } from '../../helper/IbisUser';

const Patient = () => {
  const { setPatients } = useUser();

  useEffect(() => {
    getUsers(new IbisPatient()).then((response) => setPatients(response));
  }, [setPatients]);

  return <PatientContent />;
};

export default Patient;
