import { useState, useEffect } from 'react';
import { useCompany } from '../../context/companyContext';
import { IbisAdministrator } from '../../helper/IbisUser';
import LoadingSpinner from '../Misc/LoadingSpinner';

const UserForm = ({ userType, addUser, ibisUserType }) => {
  const { companies, getCompanies } = useCompany();
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [phone, setPhone] = useState('');
  const [company, setCompany] = useState('');
  const [clinicianIsAdmin, setClinicianIsAdmin] = useState(false);
  const [adminIsClinician, setAdminIsClinician] = useState(false);
  const [userCreationPending, setUserCreationPending] = useState(false);

  const userObject = () => {
    return {
      first_name: firstname,
      last_name: lastname,
      email: email,
      username: username,
      password: password,
      password2: password2,
      profile: { phone: phone },
      user_type: userType.toLowerCase(),
      company: company,
      is_staff: clinicianIsAdmin,
      is_optician: adminIsClinician,
    };
  };

  const customAddUser = () => {
    const delay = setInterval(() => setUserCreationPending(true), 300);
    addUser(ibisUserType, userObject()).then((response) => {
      clearInterval(delay);
      setUserCreationPending(false);
      if (response) {
        document.querySelector('#add' + userType + 'Modal .close').click();
      }
    });
  };

  useEffect(() => {
    if (ibisUserType.constructor === IbisAdministrator) {
      getCompanies();
    }
  }, []);

  return (
    <>
      {userCreationPending ? <LoadingSpinner message={'Adding user...'} /> : null}
      <div
        className='modal fade'
        id={'add' + userType + 'Modal'}
        tabIndex='-1'
        role='dialog'
        aria-labelledby={'add' + userType + 'ModalLabel'}
        aria-hidden='true'
      >
        <div className='modal-dialog' role='document'>
          <div className='modal-content'>
            <div className='modal-header bg-primary-ibis'>
              <h5 className='modal-title' id={'add' + userType + 'ModalLabel'}>
                Add {userType === 'Optician' ? 'Clinician' : userType}
              </h5>
              <button className='close' type='button' data-dismiss='modal' aria-label='Close'>
                <span aria-hidden='true'>{`\u0078`}</span>
              </button>
            </div>
            <div className='modal-body'>
              <form className='user user-form' name='user-form'>
                <div className='form-group row'>
                  <div className='col-sm-6 mb-3 mb-sm-0'>
                    <input
                      type='text'
                      className='form-control form-control-user first_name'
                      id='field_first_name'
                      placeholder='First Name'
                      value={firstname}
                      onChange={(e) => setFirstname(e.target.value)}
                    />
                    <div id='validationfield_first_name' className='invalid-feedback'>
                      <span id='invalid_first_name_message'></span>
                    </div>
                  </div>
                  <div className='col-sm-6'>
                    <input
                      type='text'
                      className='form-control form-control-user last_name'
                      id='field_last_name'
                      placeholder='Last Name'
                      value={lastname}
                      onChange={(e) => setLastname(e.target.value)}
                    />
                    <div id='validationfield_last_name' className='invalid-feedback'>
                      <span id='invalid_last_name_message'></span>
                    </div>
                  </div>
                </div>
                <div className='form-group row'>
                  <div className='col-sm-6'>
                    <input
                      type='email'
                      className='form-control form-control-user email'
                      id='field_email'
                      placeholder='Email Address'
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <div id='validationfield_email' className='invalid-feedback'>
                      <span id='invalid_email_message'></span>
                    </div>
                  </div>
                  <div className='col-sm-6'>
                    <input
                      type='text'
                      className='form-control form-control-user username'
                      id='field_username'
                      placeholder='Username'
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                    <div id='validationfield_username' className='invalid-feedback'>
                      <span id='invalid_username_message'></span>
                    </div>
                  </div>
                </div>
                {userType.toLowerCase() !== 'administrator' ? (
                  <div className='form-group row'>
                    <div className='col-sm-12'>
                      <input
                        type='text'
                        className='form-control form-control-user phone'
                        id='field_phone'
                        placeholder='Phone'
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                      <div id='validationfield_phone' className='invalid-feedback'>
                        <span id='invalid_phone_message'></span>
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}
                <div className='form-group row'>
                  <div className='col-sm-6 mb-3 mb-sm-0'>
                    <input
                      type='password'
                      className='form-control form-control-user password'
                      id='field_password'
                      placeholder='Password'
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <div id='validationfield_password' className='invalid-feedback'>
                      <span id='invalid_password_message'></span>
                    </div>
                  </div>
                  <div className='col-sm-6'>
                    <input
                      type='password'
                      className='form-control form-control-user password2'
                      id='field_password2'
                      placeholder='Repeat Password'
                      value={password2}
                      onChange={(e) => setPassword2(e.target.value)}
                    />
                    <div id='validationfield_password2' className='invalid-feedback'>
                      <span id='invalid_password2_message'></span>
                    </div>
                  </div>
                </div>
                {userType.toLowerCase() === 'administrator' ? (
                  <div className='form-group row'>
                    <div className='col-sm'>
                      <select
                        className='form-control form-control company form-select'
                        aria-label='Select a company'
                        id='field_company'
                        onChange={(e) => setCompany(e.target.value)}
                        defaultValue={''}
                        style={{ color: `${company && company !== '' ? '#6e707e' : '#858796'}` }}
                      >
                        <option value='' disabled hidden>
                          No company selected
                        </option>
                        {companies?.length > 0
                          ? companies.map((company_option) => (
                              <option key={company_option.id} value={company_option.id}>
                                {company_option.name}
                              </option>
                            ))
                          : ''}
                      </select>
                      <div id='validationfield_company' className='invalid-feedback'>
                        <span id='invalid_company_message'></span>
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}
                {userType.toLowerCase() === 'optician' && (
                  <>
                    <div className='dropdown-divider mt-4 mb-3'></div>
                    <div className='custom-control custom-checkbox'>
                      <input
                        type='checkbox'
                        className='custom-control-input'
                        id='field_make_clinician_admin'
                        checked={clinicianIsAdmin}
                        onChange={() => setClinicianIsAdmin(!clinicianIsAdmin)}
                      />
                      <label className='custom-control-label' htmlFor='field_make_clinician_admin'>
                        Make Clinician Administrator
                      </label>
                    </div>
                  </>
                )}
                {userType.toLowerCase() === 'administrator' && (
                  <>
                    <div className='dropdown-divider mt-4 mb-3'></div>
                    <div className='custom-control custom-checkbox'>
                      <input
                        type='checkbox'
                        className='custom-control-input'
                        id='field_make_admin_clinician'
                        checked={adminIsClinician}
                        onChange={() => setAdminIsClinician(!adminIsClinician)}
                      />
                      <label className='custom-control-label' htmlFor='field_make_admin_clinician'>
                        Make Administrator Clinician
                      </label>
                    </div>
                  </>
                )}
              </form>
            </div>
            <div className='modal-footer'>
              <button className='btn btn-secondary' type='button' data-dismiss='modal'>
                Cancel
              </button>
              <button
                className='btn btn-ibis'
                type='button'
                id='saveButton'
                onClick={() => {
                  customAddUser();
                }}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserForm;
