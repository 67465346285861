import { useUser } from '../../context/userContext';
import { useState, useEffect } from 'react';
import { useAppointment } from '../../context/appointmentContext';
import { getAppointments, saveAppointment, editAppointment } from '../../api/appointmentFunctions';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { dateFormatMap } from '../../helper/IbisUser';

const EditAppointmentForm = ({ loggedUser, hide, appointment }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const { opticians, patients } = useUser();
  const { setAppointments } = useAppointment();

  useEffect(() => {
    const getInitialAppointmentData = async () => {
      const initialAppointmentObject = await editAppointment(appointment);
      dateParser(initialAppointmentObject.start, initialAppointmentObject.expected_end);
    };

    const dateParser = (start, end) => {
      if (start) {
        const startDateString = start.split(' ')[0];
        let parts = startDateString.split('/');
        setStartDate(new Date(parseInt(parts[2], 10), parseInt(parts[1], 10) - 1, parseInt(parts[0], 10)));
      }

      if (end) {
        const endDateString = end.split(' ')[0];
        let parts = endDateString.split('/');
        setEndDate(new Date(parseInt(parts[2], 10), parseInt(parts[1], 10) - 1, parseInt(parts[0], 10)));
      }
    };

    getInitialAppointmentData();
  }, [appointment]);

  const getAppointmentData = () => {
    let appointmentData = {
      patient: document.getElementById('edit_field_patient').value,
      start: `${startDate?.toLocaleDateString('en-GB')} ${document.getElementById('edit_field_start_time').value}`,
      expected_end: `${endDate?.toLocaleDateString('en-GB')} ${document.getElementById('edit_field_expected_end_time').value}`,
    };
    if (loggedUser.role === 'admin') {
      appointmentData['optician'] = document.getElementById('edit_field_optician').value;
    }
    if (loggedUser.role === 'optician') {
      appointmentData['optician'] = loggedUser.id;
    }

    return appointmentData;
  };

  const getOpticianField = () => {
    let selectField = '';
    if (loggedUser.role === 'admin') {
      selectField = (
        <select
          className='form-control form-control-appointment optician form-select'
          aria-label='Select an optician'
          id='edit_field_optician'
          defaultValue={''}
        >
          <option value=''>Select a clinician</option>
          {opticians?.map((optician_option) => {
            return (
              <option key={optician_option.id} value={optician_option.id}>
                {optician_option.full_name}
              </option>
            );
          })}
        </select>
      );
    }

    return selectField;
  };

  const getPatientField = () => {
    let selectField = (
      <select
        className='form-control form-control-appointment patient form-select'
        aria-label='Select a patient'
        id='edit_field_patient'
        defaultValue={''}
      >
        <option value=''>Select a patient</option>
        {patients?.map((patient_option) => {
          return (
            <option key={patient_option.id} value={patient_option.id}>
              {patient_option.full_name}
            </option>
          );
        })}
      </select>
    );

    return selectField;
  };

  const getForm = () => {
    let editAppointmentForm = (
      <form className='appointment' name='edi-appointment-form' autoComplete='off'>
        <div className='form-group row'>
          <div className='col-sm'>
            <label htmlFor='edit_field_room_name'>Room name</label>
            <input type='text' className='form-control form-control-appointment room_name' id='edit_field_room_name' readOnly='readonly' />
          </div>
        </div>
        <div className='form-group row'>
          <div className='col-sm-6 mb-3 mb-sm-0'>
            <label htmlFor='edit_field_optician'>Clinician</label>
            {getOpticianField()}
            <div id='validationedit_field_optician' className='invalid-feedback'>
              <span id='edit_invalid_optician_message'></span>
            </div>
          </div>
          <div className='col-sm-6'>
            <label htmlFor='edit_field_patient'>Patient</label>
            {getPatientField()}
            <div id='validationedit_field_patient' className='invalid-feedback'>
              <span id='edit_invalid_patient_message'></span>
            </div>
          </div>
        </div>
        <div className='form-group row'>
          <div className='col-sm-6'>
            <label htmlFor='edit_field_start_date'>Start Date</label>

            <DatePicker
              id='edit_field_start_date'
              selected={startDate}
              dateFormat={dateFormatMap[loggedUser?.company_info?.dateformat || '%d/%m/%Y']}
              onChange={(e) => setStartDate(e)}
              className={'form-control form-control-appointment start_date'}
            />

            <div id='validationedit_field_start_date' className='invalid-feedback'>
              <span id='edit_invalid_start_date_message'></span>
            </div>
          </div>
          <div className='col-sm-6'>
            <label htmlFor='edit_field_start_time'>Start time</label>
            <input type='text' className='form-control form-control-appointment start_time' id='edit_field_start_time' placeholder='Start Time' />
            <div id='validationedit_field_start_time' className='invalid-feedback'>
              <span id='edit_invalid_start_time_message'></span>
            </div>
          </div>
        </div>
        <div className='form-group row'>
          <div className='col-sm-6'>
            <label htmlFor='edit_field_expected_end_date'>Expected end date</label>

            <DatePicker
              id='edit_field_expected_end_date'
              selected={endDate}
              dateFormat={'dd/MM/yyyy'}
              onChange={(e) => setEndDate(e)}
              className={'form-control form-control-appointment expected_end_date'}
            />
            <div id='validationedit_field_expected_end_date' className='invalid-feedback'>
              <span id='edit_invalid_expected_end_date_message'></span>
            </div>
          </div>
          <div className='col-sm-6'>
            <label htmlFor='edit_field_expected_end_time'>Expected end time</label>
            <input
              type='text'
              className='form-control form-control-appointment expected_end_time'
              id='edit_field_expected_end_time'
              placeholder='Expected end time'
            />
            <div id='validationedit_field_expected_end_time' className='invalid-feedback'>
              <span id='edit_invalid_expected_end_time_message'></span>
            </div>
          </div>
        </div>
        <div className='form-group row'>
          <div className='col-sm'>
            <label htmlFor='edit_field_status'>Status</label>
            <input type='text' className='form-control form-control-appointment status' id='edit_field_status' readOnly='readonly' />
          </div>
        </div>
        <div className='form-group row'>
          <div className='col-sm'>
            <label htmlFor='edit_field_questionnaire_name'>Questionnaire</label>
            <input
              type='text'
              className='form-control form-control-appointment questionnaire_name'
              id='edit_field_questionnaire_name'
              readOnly='readonly'
            />
          </div>
        </div>
      </form>
    );

    if (loggedUser.role === 'optician') {
      editAppointmentForm = (
        <form className='appointment' name='edi-appointment-form' autoComplete='off'>
          <div className='form-group row'>
            <div className='col-sm-6'>
              <label htmlFor='edit_field_room_name'>Room name</label>
              <input type='text' className='form-control form-control-appointment room_name' id='edit_field_room_name' readOnly='readonly' />
            </div>
            <div className='col-sm-6'>
              <label htmlFor='edit_field_patient'>Patient</label>
              {getPatientField()}
              <div id='validationedit_field_patient' className='invalid-feedback'>
                <span id='edit_invalid_patient_message'></span>
              </div>
            </div>
          </div>
          <div className='form-group row'>
            <div className='col-sm-6'>
              <label htmlFor='edit_field_start_date'>Start Date</label>

              <DatePicker
                id='edit_field_start_date'
                selected={startDate}
                dateFormat={'dd/MM/yyyy'}
                onChange={(e) => setStartDate(e)}
                className={'form-control form-control-appointment start_date'}
              />
              <div id='validationedit_field_start_date' className='invalid-feedback'>
                <span id='edit_invalid_start_date_message'></span>
              </div>
            </div>
            <div className='col-sm-6'>
              <label htmlFor='edit_field_start_time'>Start time</label>
              <input type='text' className='form-control form-control-appointment start_time' id='edit_field_start_time' placeholder='Start Time' />
              <div id='validationedit_field_start_time' className='invalid-feedback'>
                <span id='edit_invalid_start_time_message'></span>
              </div>
            </div>
          </div>
          <div className='form-group row'>
            <div className='col-sm-6'>
              <label htmlFor='edit_field_expected_end_date'>Expected end date</label>

              <DatePicker
                id='edit_field_expected_end_date'
                selected={endDate}
                dateFormat={'dd/MM/yyyy'}
                onChange={(e) => setEndDate(e)}
                className={'form-control form-control-appointment expected_end_date'}
              />
              <div id='validationedit_field_expected_end_date' className='invalid-feedback'>
                <span id='edit_invalid_expected_end_date_message'></span>
              </div>
            </div>
            <div className='col-sm-6'>
              <label htmlFor='edit_field_expected_end_time'>Expected end time</label>
              <input
                type='text'
                className='form-control form-control-appointment expected_end_time'
                id='edit_field_expected_end_time'
                placeholder='Expected end time'
              />
              <div id='validationedit_field_expected_end_time' className='invalid-feedback'>
                <span id='edit_invalid_expected_end_time_message'></span>
              </div>
            </div>
          </div>
          <div className='form-group row'>
            <div className='col-sm'>
              <label htmlFor='edit_field_status'>Status</label>
              <input type='text' className='form-control form-control-appointment status' id='edit_field_status' readOnly='readonly' />
            </div>
          </div>
        </form>
      );
    } else if (loggedUser.role === 'patient') {
      editAppointmentForm = '';
    }

    return editAppointmentForm;
  };

  const customSaveAppointment = async () => {
    const refreshAppointments = () => {
      getAppointments().then((res) => {
        setAppointments(res);
        hide();
      });
    };

    const saveAppointmentResponse = await saveAppointment(appointment, getAppointmentData());

    if (saveAppointmentResponse) refreshAppointments();
  };

  return (
    <div className='modal-content'>
      <div className='modal-header bg-primary-ibis'>
        <h5 className='modal-title' id={'editAppointmentModalLabel'}>
          Edit Appointment
        </h5>
        <button className='close' type='button' aria-label='Close' onClick={hide}>
          <span aria-hidden='true'>&times;</span>
        </button>
      </div>
      <div className='modal-body'>{getForm()}</div>
      <div className='modal-footer'>
        <button className='btn btn-secondary' type='button' onClick={hide}>
          Cancel
        </button>
        <button
          className='btn btn-ibis'
          type='button'
          onClick={() => {
            customSaveAppointment();
          }}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default EditAppointmentForm;
