import { useEffect, useState } from 'react';
import { usePrescription, validatePrescriptionInput } from '../../helper/prescription-helpers';
import PrescriptionEntryTable from '../Prescription/PrescriptionEntryTable';
import { patchData } from '../../api/apiFunctions';

const loggedUser = JSON.parse(localStorage.getItem('loggedUser'));
const userCanEdit = loggedUser?.role === 'patient' || loggedUser?.superuser;

const Prescription = ({ prescriptionFromServer, id }) => {
  const [contentExpanded, setContentExpanded] = useState(false);
  const [activeCategory, setActiveCategory] = useState(null);
  const { prescriptionData, updatePrescriptionData } = usePrescription();

  useEffect(() => {
    updatePrescriptionData({ type: 'SET_ALL_VALUES', payload: prescriptionFromServer });
  }, [prescriptionFromServer, updatePrescriptionData]);

  const savePrescription = () => {
    return patchData(`/api/prescriptions/${id}/`, { prescription_data: JSON.stringify(prescriptionData) });
  };

  const handleClick = () => {
    if (!contentExpanded) {
      setActiveCategory(null);
      return setContentExpanded(true);
    }
    if (!userCanEdit) return setContentExpanded(false);
    const errorMsg = validatePrescriptionInput(prescriptionData);
    return errorMsg ? alert(errorMsg) : savePrescription().then(() => setContentExpanded(false));
  };

  return (
    <div
      className='border border-dark rounded p-3 mb-4 bg-white overflow-hidden'
      style={{ height: contentExpanded ? '350px' : '100px', transition: 'height 500ms' }}
    >
      <div className='d-sm-flex align-items-center justify-content-between mb-4'>
        <div className='col-sm-6' style={{ paddingLeft: '0.3rem' }}>
          <h3 className='h3 mb-0 text-gray-800'>{`${loggedUser.role === 'patient' ? 'Your' : 'Patient'} Prescription`}</h3>
          <i className='fas fa-clock' style={{ marginRight: '0.35rem', color: 'rgb(0, 136, 161)' }} />
          {`3 minutes`}
        </div>

        <div className='d-flex justify-content-center'>
          <button className='btn-ibis btn-lg shadow-sm' style={{ minWidth: '188px' }} onClick={handleClick}>
            {contentExpanded ? `${userCanEdit ? 'Save &' : ''} Close` : `${userCanEdit ? 'Edit' : 'View'} Prescription`}
          </button>
        </div>
      </div>
      <PrescriptionEntryTable
        prescriptionData={prescriptionData}
        updatePrescriptionData={updatePrescriptionData}
        activeCategory={activeCategory}
        setActiveCategory={setActiveCategory}
        userCanEdit={userCanEdit}
      />
    </div>
  );
};

export default Prescription;
