import { useEffect, useState } from 'react';
import { useQuestionnaire } from '../../context/questionnaireContext';
import LoadingSpinner from '../Misc/LoadingSpinner';

const QuestionnaireForm = ({ toggle }) => {
  const { addQuestionnaire } = useQuestionnaire();
  const [questionnaireObject, setQuestionnaireObject] = useState({ average_duration: '300' });
  const [questionnaireCreationPending, setQuestionnaireCreationPending] = useState(false);
  const [completionTimes, setCompletionTimes] = useState([]);

  const { name = '', average_duration, description = '' } = questionnaireObject;

  const createQuestionnaire = () => {
    const delay = setInterval(() => setQuestionnaireCreationPending(true), 300);
    addQuestionnaire(questionnaireObject).then((response) => {
      clearInterval(delay);
      setQuestionnaireCreationPending(false);
      if (typeof response == 'object') {
        toggle();
      }
    });
  };

  useEffect(() => {
    let timeValuesArray = [];
    for (let time = 5; time < 65; time += 5) {
      timeValuesArray.push(time);
    }
    setCompletionTimes(timeValuesArray);
  }, []);

  return (
    <>
      {questionnaireCreationPending ? <LoadingSpinner message={'Creating questionnaire...'} /> : null}

      <form className='' name='questionnaire-form'>
        <div className='col mb-3 mb-sm-0'>
          <label htmlFor='field_name'>Questionnaire Name</label>
          <input
            type='text'
            className='form-control form-control-questionnaire name'
            id='field_name'
            placeholder='Name'
            value={name}
            style={{ marginBottom: '1rem' }}
            onChange={(e) =>
              setQuestionnaireObject((obj) => {
                return { ...obj, name: e.target.value };
              })
            }
          />
          <div id='validationfield_name' className='invalid-feedback'>
            <span id='invalid_name_message'></span>
          </div>
          <div className='row'>
            <div className='col'>
              <label htmlFor='field_average_duration'>Average Time to Complete</label>
              <select
                className='form-control form-control-questionnaire average_duration form-select'
                aria-label='Average time needed to complete questionnaire'
                id='field_average_duration'
                style={{ marginBottom: '1rem' }}
                value={average_duration}
                onChange={(e) =>
                  setQuestionnaireObject((obj) => {
                    return { ...obj, average_duration: e.target.value };
                  })
                }
              >
                {completionTimes.map((time) => {
                  const timeInSeconds = time * 60;
                  return (
                    <option key={`completion-time-${time}`} value={timeInSeconds}>
                      {`${time} minutes`}
                    </option>
                  );
                })}
              </select>
              <div id='validationfield_average_duration' className='invalid-feedback'>
                <span id='invalid_average_duration_message'></span>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col mb-3'>
              <label htmlFor='field_questionnaire_description'>Description</label>
              <textarea
                className='form-control form-control-questionnaire questionnaire-description form-textarea'
                id='field_questionnaire_description'
                rows={3}
                value={description}
                placeholder='Enter your description...'
                onChange={(e) =>
                  setQuestionnaireObject((obj) => {
                    return { ...obj, description: e.target.value };
                  })
                }
              ></textarea>
            </div>
          </div>
        </div>
      </form>

      <div className='modal-footer'>
        <button className='btn btn-secondary' type='button' onClick={() => toggle()}>
          Cancel
        </button>
        <button
          className='btn btn-ibis'
          type='button'
          onClick={() => {
            createQuestionnaire();
          }}
        >
          Add
        </button>
      </div>
    </>
  );
};

export default QuestionnaireForm;
