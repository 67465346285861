import { useState } from 'react';

const usePagination = (initialPage = 1, initialRowsPerPage = 10) => {
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [itemsPerPage, setItemsPerPage] = useState(initialRowsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setItemsPerPage(newRowsPerPage);
    setCurrentPage(1);
  };

  const resetPagination = () => {
    setCurrentPage(initialPage);
    setItemsPerPage(initialRowsPerPage);
  };

  return {
    currentPage,
    itemsPerPage,
    handlePageChange,
    handleRowsPerPageChange,
    resetPagination,
  };
};

export default usePagination;
