import { useQuestionnaire } from '../../context/questionnaireContext';
import IbisDataTable from '../DataTable/IbisDataTable';

const loggedUser = JSON.parse(localStorage.getItem('loggedUser'));

const QuestionsList = () => {
  const { questions, deleteQuestion, editQuestion } = useQuestionnaire();

  const hasEditPrivileges = (question) => {
    if (!loggedUser.superuser) {
      if (!question.company) {
        return false;
      }
    }
    return true;
  };
  const getEditBtn = (question) => {
    if (!hasEditPrivileges(question)) return;
    return (
      <button
        className='btn btn-ibis btn-circle'
        data-toggle='modal'
        data-target='#editQuestionModal'
        onClick={() => {
          editQuestion(question.id);
        }}
      >
        <i className='fas fa-edit' />
      </button>
    );
  };

  const getDeleteBtn = (question) => {
    if (!hasEditPrivileges(question)) return;
    return (
      <button className='btn btn-danger btn-circle' onClick={() => deleteQuestion(question.id)}>
        <i className='fas fa-trash' />
      </button>
    );
  };

  const getQuestionType = ({ question_type }) => {
    if (question_type === 'boolean') {
      return 'Yes or No';
    } else if (question_type === 'text') {
      return 'Text';
    } else if (question_type === 'checkbox') {
      return 'Checkbox';
    }
  };

  const questionTableColumns = [
    { name: 'ID', selector: (question) => question.id, sortable: true, filter_field: 'id' },
    {
      name: 'Question Text',
      selector: (question) => {
        return (
          <span>
            <QuestionCompanyBadge question={question} />
            {question.text}
          </span>
        );
      },
    },
    { name: 'Type', selector: (question) => getQuestionType(question) },
    { name: 'Edit', selector: (question) => getEditBtn(question) },
    { name: 'Delete', selector: (question) => getDeleteBtn(question) },
  ];

  return (
    <div className='container-fluid'>
      <IbisDataTable title='Questions' data={questions} columns={questionTableColumns} />
    </div>
  );
};

export default QuestionsList;

const QuestionCompanyBadge = ({ question }) => {
  if (loggedUser.superuser) {
    if (question.company) {
      return <span className='badge badge-ibis mr-1'>{question.company}</span>;
    }
  } else {
    if (!question.company) {
      return <span className='badge badge-ibis mr-1'>IbisVision</span>;
    }
  }
  return null;
};
