import { patchData, postData } from './apiFunctions';
import { fetchObjects } from './crudFunctions';

const apiUrl = '/api/recheck/sessions';

export const getAllSessions = async (filters = {}) => {
  let url = `${apiUrl}`;

  const queryParams = Object.entries({ ...filters })
    .filter(([_, value]) => value !== undefined && value !== null && value !== '')
    .map(([key, value]) => {
      if (key === 'start_datetime' || key === 'end_datetime' || key === 'start_latest_activity_datetime' || key === 'end_latest_activity_datetime') {
        // Convert milliseconds to seconds for the API
        const timestampInSeconds = Math.floor(value / 1000);
        return `${key}=${encodeURIComponent(timestampInSeconds)}`;
      } else {
        return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
      }
    })
    .filter(Boolean)
    .join('&');

  if (queryParams) {
    url += `?${queryParams}`;
  }

  try {
    const res = await fetchObjects(url);
    return res;
  } catch (error) {
    console.error('Error fetching sessions:', error);
    return [];
  }
};

export const getSession = async (id) => {
  const res = await fetchObjects(`${apiUrl}/${id}/`);

  try {
    return res;
  } catch (error) {
    console.error('Error fetching session:', error);
    return {};
  }
};

export const updateRecheckStatus = async (session, status) => {
  try {
    await patchData(`${apiUrl}/${session}/`, { status });
    return `Session marked as ${status}`;
  } catch {
    throw new Error('Error updating session status');
  }
};

export const sendRecheckEmail = async (session, type) => {
  const titleCaseType = type.charAt(0).toUpperCase() + type.slice(1);
  try {
    const response_data = await postData(`${apiUrl}/${session}/send-email/`, {
      email: type,
    });
    if (response_data.error) return response_data.error;
    return `${titleCaseType} email sent`;
  } catch {
    throw new Error('Error sending email');
  }
};
