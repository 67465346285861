export const getPreScreeningChecks = (preScreeningData) => {
  const { prescription, questionnaires, tests } = preScreeningData;
  let allChecks = [];

  if (Object.keys(prescription).length) allChecks.push('prescription');
  tests.forEach((test) => allChecks.push(test.key));
  questionnaires.forEach((q) => allChecks.push(q.id));

  return allChecks;
};
