import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { patchData } from '../../api/apiFunctions';
import { addObject, detailObject, fetchObjects } from '../../api/crudFunctions';
import CompanyDangerZone from './CompanyDangerZone';
import CompanyTestSelect from './CompanyTestSelect';
import './EditCompanyFeatures.css';

const EditCompanyFeatures = ({ closeModal, newCompanyId }) => {
  const [availableTests, setAvailableTests] = useState([]);
  const [selectedTests, setSelectedTests] = useState([]);
  const [questionnairesEnabled, setQuestionnairesEnabled] = useState(false);
  const [recheckEnabled, setRecheckEnabled] = useState(false);
  const [engageEnabled, setEngageEnabled] = useState(true);
  const [companyActive, setCompanyActive] = useState(true);
  const [companyName, setCompanyName] = useState('');
  const [recheckData, setRecheckData] = useState({});
  const history = useHistory();
  const { companyId = newCompanyId } = useParams();

  useEffect(() => {
    const getCompanyDetails = async () => {
      let enabledTestsArray = [];
      let availableTestsArray = [];

      const { enabled_tests, questionnaires_enabled, is_active, name, recheck_enabled, engage_enabled, recheck_data } = await fetchObjects(
        `/api/companies/${companyId}`
      );
      const { results } = await fetchObjects('/api/appointment-eye-test');

      for (let test in enabled_tests) {
        enabledTestsArray.push(enabled_tests[test]);
      }

      if (enabledTestsArray.length) {
        availableTestsArray = results.filter((availableTest) => {
          return !enabledTestsArray.some((enabledTest) => {
            return enabledTest.id === availableTest.id;
          });
        });
      } else {
        availableTestsArray = results;
      }

      return { enabledTestsArray, availableTestsArray, questionnaires_enabled, recheck_enabled, engage_enabled, is_active, name, recheck_data };
    };

    getCompanyDetails().then((response) => {
      setSelectedTests([...response.enabledTestsArray]);
      setAvailableTests([...response.availableTestsArray]);
      setQuestionnairesEnabled(response.questionnaires_enabled);
      setCompanyActive(response.is_active);
      setCompanyName(response.name);
      setRecheckEnabled(response.recheck_enabled);
      setEngageEnabled(response.engage_enabled);
      setRecheckData(response.recheck_data);
    });
  }, [companyId]);

  const saveChanges = async () => {
    const { results } = await fetchObjects('/api/company-eye-tests');
    const existingCompanyTests = results.filter((test) => {
      return test.company === +companyId;
    });

    if (availableTests.length) {
      const testsToRemove = availableTests.filter((availableTest) => {
        return existingCompanyTests.some((existingTest) => {
          return existingTest.eye_test === availableTest.id;
        });
      });

      existingCompanyTests.forEach(async (existingTest) => {
        if (testsToRemove.some((test) => existingTest.eye_test === test.id)) {
          const deletedResponse = await detailObject(`/api/company-eye-tests/${existingTest.id}`, 'DELETE');
          return deletedResponse;
        }
      });
    }

    if (selectedTests.length) {
      const testsToAdd = selectedTests.filter((selectedTest) => {
        return !existingCompanyTests.some((existingTest) => {
          return existingTest.eye_test === selectedTest.id;
        });
      });

      testsToAdd.forEach(async (test) => {
        const addedResponse = await addObject('/api/company-eye-tests/', { company: companyId, eye_test: test.id });
        return addedResponse;
      });
    }

    await patchData(`/api/companies/${companyId}/`, {
      questionnaires_enabled: questionnairesEnabled,
      recheck_enabled: recheckEnabled,
      engage_enabled: engageEnabled,
    });

    return closeModal ? closeModal() : history.push('/companies');
  };

  return (
    <div className='card shadow mb-5 w-75'>
      <div className='card-header bg-primary-ibis py-3'>
        <h6 className='m-0 font-weight-bold text-white'>{`Company Features - ${companyName}`}</h6>
      </div>
      <div className='card-body d-flex flex-column' style={{ gap: '1rem' }}>
        <div className='card shadow-sm'>
          <div className='list-group-item border border-dark'>
            <div className='d-flex justify-content-between'>
              <h4 className='m-0'>Questionnaires</h4>
              <div className='custom-control custom-switch p-1' style={{ transform: 'scale(1.2)' }}>
                <input
                  type='checkbox'
                  className='custom-control-input'
                  id='questionnaire-switch'
                  checked={questionnairesEnabled}
                  onChange={() => setQuestionnairesEnabled(!questionnairesEnabled)}
                />
                <label className='custom-control-label' htmlFor='questionnaire-switch'></label>
              </div>
            </div>
            <p className='text-muted m-0'>Enable or disable the use of questionnaires for this company.</p>
          </div>
        </div>
        <div className='card shadow-sm'>
          <div className='list-group-item border border-dark'>
            <div className='d-flex justify-content-between'>
              <h4 className='m-0'>Recheck</h4>
              <div className='custom-control custom-switch p-1' style={{ transform: 'scale(1.2)' }}>
                <input
                  type='checkbox'
                  className='custom-control-input'
                  id='recheck-switch'
                  checked={recheckEnabled}
                  onChange={() => setRecheckEnabled(!recheckEnabled)}
                />
                <label className='custom-control-label' htmlFor='recheck-switch'></label>
              </div>
            </div>
            <p className='text-muted m-0'>Enable or disable access to Recheck for this company.</p>
            {recheckEnabled && (
              <span className='text-muted m-0'>
                <strong>Recheck link: </strong>
                <a href={recheckData?.link} target='_blank' rel='noreferrer' className='text-ibis'>
                  {recheckData?.link}
                </a>
              </span>
            )}
          </div>
        </div>
        <div className='card shadow-sm'>
          <div className='list-group-item border border-dark'>
            <div className='d-flex justify-content-between'>
              <h4 className='m-0'>Engage</h4>
              <div className='custom-control custom-switch p-1' style={{ transform: 'scale(1.2)' }}>
                <input
                  type='checkbox'
                  className='custom-control-input'
                  id='engage-switch'
                  checked={engageEnabled}
                  onChange={() => setEngageEnabled(!engageEnabled)}
                />
                <label className='custom-control-label' htmlFor='engage-switch'></label>
              </div>
            </div>
            <p className='text-muted m-0'>Enable or disable access to Engage for this company.</p>
          </div>
        </div>
        <div className={`${engageEnabled ? 'card shadow-sm' : 'card shadow-sm d-none'}`}>
          <div className='list-group-item border border-dark'>
            <h4 className='m-0'>Tests</h4>
            <p className='text-muted m-0'>Choose which tests are enabled for this company.</p>
            <div className='w-100 d-flex mt-3'>
              <CompanyTestSelect
                availableTests={availableTests}
                selectedTests={selectedTests}
                setAvailableTests={setAvailableTests}
                setSelectedTests={setSelectedTests}
              />
            </div>
          </div>
        </div>
        {!closeModal && (
          <CompanyDangerZone companyId={companyId} companyName={companyName} companyActive={companyActive} setCompanyActive={setCompanyActive} />
        )}
      </div>
      <div className='d-flex justify-content-center mb-4'>
        <button
          className='btn btn-secondary'
          type='button'
          style={{ margin: '0px 10px 0px 10px', width: '120px' }}
          onClick={() => {
            return closeModal ? closeModal() : history.push('/companies');
          }}
        >
          Cancel
        </button>
        <button className='btn btn-ibis' type='button' style={{ margin: '0px 10px 0px 10px', width: '120px' }} onClick={() => saveChanges()}>
          Save
        </button>
      </div>
    </div>
  );
};

export default EditCompanyFeatures;
