import { getToken, destroySession } from './authFunctions';

const apiUrl = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_API_URL : process.env.REACT_APP_DEV_API_URL;

export const customRequest = async (path, options) => {
  let response = await fetch(apiUrl + path, options);

  if (response.status === 401 && response.redirected) {
    response = await fetch(response.url, options);
  }

  if (response.status === 401) {
    destroySession();
    window.location.reload();
  }

  return await response;
};

export const getAuthorisedHeader = (method) => {
  if (!method) {
    method = 'GET';
  }
  const authorisedHeader = {
    Authorization: 'Token ' + getToken(),
  };
  if (method === 'POST' || method === 'PATCH') {
    authorisedHeader['Content-type'] = 'application/json';
  }

  return authorisedHeader;
};

export const postData = async (url = '', data = {}, unauthorised) => {
  const postHeader = getAuthorisedHeader('POST');
  if (unauthorised) {
    delete postHeader.Authorization;
  }
  const response = await customRequest(url, {
    method: 'POST',
    headers: postHeader,
    body: JSON.stringify(data),
  });

  return response.json();
};

export const patchData = async (url = '', data = {}) => {
  const response = await customRequest(url, {
    method: 'PATCH',
    headers: getAuthorisedHeader('PATCH'),
    body: JSON.stringify(data),
  });

  return response.json();
};

export const uploadFormData = async ({ url, method, data }) => {
  const headers = new Headers();
  headers.append('Authorization', `Token ${getToken()}`);
  const formData = new FormData();

  for (const key in data) {
    formData.append(key, data[key]);
  }

  const response = await customRequest(url, { headers, method, body: formData });
  return response;
};
