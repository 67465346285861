import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { validateTicket, getLoggedUser } from '../api/authFunctions';

const AuthenticationContext = createContext();

export const useAuth = () => {
  return useContext(AuthenticationContext);
};

export const AuthenticationProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  const getToken = () => {
    const sessionToken = localStorage.getItem('token');
    return sessionToken;
  };

  const getTicket = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    return urlParams.get('ticket');
  };

  const destroySession = () => {
    localStorage.removeItem('__endpoint__');
    localStorage.removeItem('token');
    localStorage.removeItem('loggedUser');
    localStorage.removeItem('__ibis_token__');
    setUser(null);
  };

  const checkToken = useCallback(async () => {
    let token = getToken();
    const ticket = getTicket();
    if (ticket) {
      await validateTicket(ticket);
      token = localStorage.getItem('token');
      if (token) {
        const loggedUserResult = await getLoggedUser();
        if (!loggedUserResult?.profile?.consent) localStorage.setItem('ticket', ticket);
        localStorage.setItem('loggedUser', JSON.stringify(loggedUserResult));
        setUser(loggedUserResult);
        window.location.href = process.env.PUBLIC_URL + window.location.pathname;
      }
    }

    if (!token) {
      destroySession();
      localStorage.setItem('__endpoint__', window.location.pathname);
      window.location.href = process.env.PUBLIC_URL + '/login';
    }

    const loggedUser = await getLoggedUser();
    return setUser(loggedUser);
  }, []);

  useEffect(() => {
    const publicRoutes = ['/login', '/register', '/reset-password', '/reset-password-success', '/refused-consent'];
    if (!publicRoutes.includes(window.location.pathname)) checkToken();
  }, [checkToken]);

  return <AuthenticationContext.Provider value={{ destroySession, getToken, user }}>{children}</AuthenticationContext.Provider>;
};
