import { useUser } from '../../context/userContext';
import { IbisAdministrator } from '../../helper/IbisUser';
import IbisDataTable from '../DataTable/IbisDataTable';

const AdministratorsList = () => {
  const { administrators, deleteUser, editUser } = useUser();

  const getEditButton = (administrator) => {
    return !administrator.is_superuser ? (
      <button
        className='btn btn-ibis btn-circle'
        data-toggle='modal'
        data-target='#editAdministratorModal'
        onClick={() => editUser(new IbisAdministrator(), administrator.id)}
      >
        <i className='fas fa-edit'></i>
      </button>
    ) : (
      <span className='badge badge-secondary' style={{ padding: '10px' }}>
        SUPERUSER
      </span>
    );
  };

  const getDeleteButton = (administrator) => {
    return !administrator.is_superuser ? (
      <button className='btn btn-danger btn-circle' onClick={() => deleteUser(new IbisAdministrator(), administrator.id)}>
        <i className='fas fa-trash'></i>
      </button>
    ) : (
      <span className='badge badge-secondary' style={{ padding: '10px' }}>
        SUPERUSER
      </span>
    );
  };

  const getColumns = () => {
    return [
      { name: 'First Name', selector: (administrator) => administrator.first_name, sortable: true, filter_field: 'first_name' },
      { name: 'Last Name', selector: (administrator) => administrator.last_name, sortable: true, filter_field: 'last_name' },
      { name: 'Email', selector: (administrator) => administrator.email, sortable: true, filter_field: 'email' },
      { name: 'Username', selector: (administrator) => administrator.username, sortable: true, filter_field: 'username' },
      { name: 'Company', selector: (administrator) => administrator.company_name, sortable: true, filter_field: 'company_name' },
      { name: 'Edit', selector: (administrator) => getEditButton(administrator), button: true, center: true, minWidth: '130px' },
      { name: 'Remove', selector: (administrator) => getDeleteButton(administrator), button: true, center: true, minWidth: '130px' },
    ];
  };

  return <IbisDataTable title='Administrators' data={administrators} columns={getColumns()} />;
};

export default AdministratorsList;
