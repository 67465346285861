import { useEffect, useState } from 'react';

const FileUploader = ({ uploadedImg, setUploadedImg, title, desc }) => {
  const [preview, setPreview] = useState();

  useEffect(() => {
    if (!uploadedImg) {
      setPreview(undefined);
      return;
    }

    if (typeof uploadedImg === 'string') setPreview(uploadedImg);

    if (typeof uploadedImg === 'object') {
      const objectUrl = URL.createObjectURL(uploadedImg);
      setPreview(objectUrl);
      return () => URL.revokeObjectURL(objectUrl);
    }
  }, [uploadedImg]);

  const onFileUpload = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setUploadedImg(undefined);
      return;
    }

    return setUploadedImg(e.target.files[0]);
  };
  return (
    <div className='card-body'>
      <div className='card shadow-sm'>
        <div className='list-group-item border border-dark'>
          <div className='d-flex justify-content-between'>
            <div className='d-flex flex-column'>
              <h4 className='m-0 text-capitalize'>{title}</h4>
              <p className='text-muted'>{desc}</p>
            </div>
            <div className='d-flex flex-column w-50 align-items-center'>
              <div className='custom-file'>
                <input type='file' className='custom-file-input' id={`${title}-upload`} onChange={onFileUpload} accept='image/*' />
                <label className='custom-file-label' htmlFor={`${title}-upload`}>
                  Click to upload image
                </label>
              </div>
              <div className='border rounded mt-2 p-2 d-flex flex-column align-items-center w-100' style={{ height: '125px' }}>
                <strong className='m-0 mb-1'>Preview</strong>
                {preview ? <img src={preview} alt='preview of upload' width='224' height='70' /> : 'No image selected.'}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FileUploader;
