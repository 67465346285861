import { editUser } from '../../api/userFunctions';
import { useEffect, useState } from 'react';
import { IbisPatient } from '../../helper/IbisUser';
import { getPreScreeningChecks } from '../../helper/prescreening-helpers';
import PreScreeningDetails from './PreScreeningDetails';

const loggedUser = JSON.parse(localStorage.getItem('loggedUser'));

const AppointmentDetailsCard = ({ appointment, position }) => {
  const [patientDetails, setPatientDetails] = useState({ first_name: '', last_name: '', email: '' });
  const [preScreeningChecks, setPreScreeningChecks] = useState([]);
  const appointmentBelongsToUser = loggedUser.id === appointment.optician;

  useEffect(() => {
    const getPatientDetails = async () => {
      setPatientDetails(await editUser(new IbisPatient(), appointment.patient));
    };

    getPatientDetails();

    const requestedPreScreeningChecks = getPreScreeningChecks(appointment.prescreening);
    setPreScreeningChecks([...requestedPreScreeningChecks, ...(appointment.questionnaire_id ? ['questionnaires'] : [])]);

    document.getElementById('tabs-details-tab').click();
  }, [appointment]);

  return (
    <div
      className='col-xl-6 col-md-6 mb-4'
      style={{
        position: 'fixed',
        top: position.top,
        left: position.left + position.width + 36,
        width: '590px',
        bottom: position.bottom,
      }}
      id='waiting-room-details-pane'
    >
      <div className='card shadow mb-4'>
        <div className='card-header bg-primary-ibis py-3'>
          <h5 className='m-0 font-weight-bold text-white'>
            {appointment.patient_full_name}
            <span className='room-badge badge badge-success float-right m-1 d-none' id={`feature-badge-${appointment.room_name}`}>
              PATIENT IS CONNECTED
            </span>
          </h5>
        </div>
        <div className='card-body'>
          <div className='card shadow mb-4'>
            <div className='card-body' style={{ height: '350px' }}>
              <div className='row'>
                <div className='col-12'>
                  {appointmentBelongsToUser ? (
                    <a className='btn btn-ibis btn-lg w-100' target='_blank' rel='noreferrer' href={`/start/${appointment.room_name}`}>
                      Start Session
                    </a>
                  ) : (
                    <span className='btn btn-dark btn-lg w-100 disabled'>{`Clinician: ${appointment.optician_full_name}`}</span>
                  )}
                </div>
              </div>
              <div className='col-12 pl-0 pr-1 mt-2'>
                {appointmentBelongsToUser ? (
                  <a
                    className='btn btn-primary btn-md shadow-sm w-100'
                    target='_blank'
                    rel='noreferrer'
                    href={`/health/?userID=${loggedUser.id}&userName=${loggedUser.username}&userEmail=${loggedUser.email}&roomName=${appointment.room_name}`}
                  >
                    Check Connection Health
                  </a>
                ) : null}
              </div>
              <ul className='nav nav-tabs mb-3 mt-3' id='appointments-tab' role='tablist'>
                <li className='nav-item'>
                  <a
                    className='nav-link text-ibis active'
                    id='tabs-details-tab'
                    data-toggle='pill'
                    href='#tabs-details'
                    role='tab'
                    aria-controls='tabs-details'
                    aria-selected='true'
                  >
                    Details
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    className='nav-link text-ibis'
                    id='appointment-patient-tab'
                    data-toggle='pill'
                    href='#appointment-patient'
                    role='tab'
                    aria-controls='appointment-patient'
                    aria-selected='false'
                  >
                    Patient
                  </a>
                </li>
                {preScreeningChecks.length ? (
                  <li className='nav-item'>
                    <a
                      className='nav-link text-ibis'
                      id='prescreening-tab'
                      data-toggle='pill'
                      href='#prescreening'
                      role='tab'
                      aria-controls='prescreening'
                      aria-selected='false'
                    >
                      Pre-Screening
                    </a>
                  </li>
                ) : null}
              </ul>
              <div className='tab-content' id='pills-tabContent'>
                <div className='tab-pane fade show active' id='tabs-details' role='tabpanel' aria-labelledby='tabs-details-tab'>
                  <table className='table' id='dataTable' width='100%' cellSpacing='0'>
                    <tbody>
                      <tr>
                        <td>Date: {appointment.start.split(' ')[0]}</td>
                      </tr>
                      <tr>
                        <td>Time: {appointment.start.split(' ')[1]}</td>
                      </tr>
                      <tr>
                        <td>Duration: {appointment.appointment_duration}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className='tab-pane fade' id='appointment-patient' role='tabpanel' aria-labelledby='appointment-patient-tab'>
                  <div className='form-group row'>
                    <div className='col-sm-6'>
                      <label htmlFor='disabled_first_name'>First Name</label>
                      <input type='text' className='form-control' readOnly='readOnly' id='disabled_first_name' value={patientDetails.first_name} />
                    </div>
                    <div className='col-sm-6'>
                      <label htmlFor='disabled_last_name'>Last Name</label>
                      <input type='text' className='form-control' readOnly='readOnly' id='disabled_last_name' value={patientDetails.last_name} />
                    </div>
                  </div>
                  <div className='form-group row'>
                    <div className='col-sm-12'>
                      <label htmlFor='disabled_email'>Email</label>
                      <input type='text' className='form-control' readOnly='readOnly' id='disabled_email' value={patientDetails.email} />
                    </div>
                  </div>
                </div>
                {preScreeningChecks.length ? <PreScreeningDetails categories={preScreeningChecks} details={appointment} /> : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppointmentDetailsCard;
