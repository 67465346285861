import { fetchObjects, addObject, detailObject } from './crudFunctions';

const apiUrl = '/api/invitations/';

export const getInvitations = async () => {
  const { results } = await fetchObjects(`${apiUrl}?all=1`);
  return results;
};

export const sendNewInvitation = async (invitationData) => {
  const response = await addObject(apiUrl, invitationData);
  return response;
};

export const resendInvitation = async (invitationId) => {
  if (window.confirm('Are you sure you want to re-send this invitation?')) {
    const response = await fetchObjects(`${apiUrl}${invitationId}/resend/`);
    return response;
  } else return 'canceled';
};

export const deleteInvitation = async (invitationId) => {
  if (window.confirm('Are you sure you want to delete this invitation?')) {
    const response = await detailObject(`${apiUrl}${invitationId}`, 'DELETE');
    return response;
  }
};
