import { useState } from 'react';
import { VisualFieldMatrix } from '.';

const Flex = ({ children, column, ...styleProps }) => {
  return (
    <div className={`d-flex ${column ? 'flex-column' : 'flex-row'}`} style={styleProps}>
      {children}
    </div>
  );
};

const VisualFieldResultsFV = ({ testData }) => {
  const [outOfBoundariesDots, setOutOfBoundariesDots] = useState([]);
  const [hoveredDotInfo, setHoveredDotInfo] = useState(false);
  const { orientationCorrect, orientationIncorrect, missedOnceDots, missedTwiceDots, seenDots, forthValleyVisualFieldDots } = testData;

  const handleCoordHover = (hoveredDot) => {
    if (!hoveredDot) return setHoveredDotInfo(null);
    const { degreeX, degreeY } = forthValleyVisualFieldDots.find((rawDot) => rawDot.id === hoveredDot.id);
    return setHoveredDotInfo({ x: degreeX, y: degreeY });
  };

  return (
    <Flex height='500px' width='100%' alignItems='center' justifyContent='space-between'>
      {!Object.keys(testData).length ? (
        <Flex width='100%' justifyContent='center'>
          {'Cannot display visual field results at this time.'}
        </Flex>
      ) : (
        <>
          <VisualFieldMatrix
            testProgressData={testData}
            dotMatrixFromResults={forthValleyVisualFieldDots}
            size={420}
            outOfBoundariesDots={outOfBoundariesDots}
            setOutOfBoundariesDots={setOutOfBoundariesDots}
            handleCoordHover={handleCoordHover}
          />
          <Flex alignItems='center' column marginLeft='0.5rem'>
            <Flex marginBottom='2rem' justifyContent='space-evenly' width='75%' visibility={hoveredDotInfo ? 'visible' : 'hidden'}>
              <span>
                <strong>{'X: '}</strong>
                {hoveredDotInfo?.x}
              </span>
              <span>
                <strong>{'Y: '}</strong>
                {hoveredDotInfo?.y}
              </span>
            </Flex>

            <Flex alignItems='center' justifyContent='left' height='30px' width='100%'>
              <Flex backgroundColor='#6DC89D' height='20px' width='20px' borderRadius='50%' marginRight='1rem' />
              <Flex>{`Orientation Correct: ${orientationCorrect?.length || 0}`}</Flex>
            </Flex>
            <Flex alignItems='center' justifyContent='left' height='30px' width='100%'>
              <Flex backgroundColor='#870000' height='20px' width='20px' borderRadius='50%' marginRight='1rem' />
              <Flex>{`Orientation Incorrect ${orientationIncorrect?.length || 0}`}</Flex>
            </Flex>

            <Flex alignItems='center' justifyContent='left' height='30px' width='100%'>
              <Flex backgroundColor='#F0B729' height='20px' width='20px' borderRadius='50%' marginRight='1rem' />
              <Flex>{`Not Seen Once: ${missedOnceDots?.length || 0}`}</Flex>
            </Flex>
            <Flex alignItems='center' justifyContent='left' height='30px' width='100%'>
              <Flex backgroundColor='red' height='20px' width='20px' borderRadius='50%' marginRight='1rem' />
              <Flex>{`Not Seen Twice: ${missedTwiceDots?.length || 0}`}</Flex>
            </Flex>
            {outOfBoundariesDots.length ? (
              <Flex alignItems='center' justifyContent='left' height='30px' width='100%'>
                <Flex backgroundColor='#F2E6E6' height='20px' width='20px' borderRadius='50%' marginRight='1rem' />
                <Flex>{`Out of Boundaries: ${outOfBoundariesDots.length}`}</Flex>
              </Flex>
            ) : null}
            <Flex alignItems='center' justifyContent='left' height='30px' width='100%'>
              <Flex backgroundColor='white' height='20px' width='20px' borderRadius='50%' marginRight='1rem' />
              <Flex>
                <span style={{ fontWeight: '700' }}>{`Total Seen: ${seenDots?.length || 0}`}</span>
              </Flex>
            </Flex>
          </Flex>
        </>
      )}
    </Flex>
  );
};

export default VisualFieldResultsFV;
