import React from 'react';
import IbisModal from './IbisModal';
import BasicEyeScore from '../TestResults/BasicEyeScore';
import OptimaEyeScore from '../TestResults/OptimaEyeScore';
import RefractionEyeScore from '../TestResults/RefractionEyeScore';
import ContrastSensitivityResults from '../TestResults/ContrastSensitivityResults';
import VisualFieldTestResult from '../TestResults/VisualFieldTestResult';
import VisualFieldTwoResults from '../TestResults/VisualFieldTwoResults';
import { StrokeTestResults } from '../TestResults/StrokeSuite';

const TestResultsModal = ({ modalIsOpen, toggle, selectedTest }) => {
  const resultData = selectedTest ? JSON.parse(selectedTest.result_data) : {};
  const { test_title, test_key } = selectedTest || {};
  const { bothEyes } = resultData;

  const renderTestResult = () => {
    switch (test_key) {
      case 'optimaChart':
        return renderResults(OptimaEyeScore);
      case 'contrastSensitivity':
        return <ContrastSensitivityResults results={resultData} />;
      case 'visualFieldTest':
        return <VisualFieldTestResult results={resultData} testInfo={selectedTest} />;
      case 'Refraction':
      case 'RefractionTwo':
        return renderResults(RefractionEyeScore);
      case 'visualFieldForthValley':
        return <StrokeTestResults results={resultData} testInfo={selectedTest} />;
      case 'visualFieldTwo':
        return <VisualFieldTwoResults results={resultData} />;
      default:
        return renderResults(BasicEyeScore);
    }
  };

  const renderResults = (testResultComponent) => {
    if (bothEyes) {
      return React.createElement(testResultComponent, { eye: 'binocular', results: resultData });
    } else {
      return [
        React.createElement(testResultComponent, { eye: 'right', results: resultData, key: 'results-right-eye' }),
        React.createElement(testResultComponent, { eye: 'left', results: resultData, key: 'results-left-eye' }),
      ];
    }
  };

  return (
    <IbisModal title='Test Results' modalIsOpen={modalIsOpen} toggle={toggle} className='Modal__Bootstrap modal-dialog modal-lg'>
      <div className='container-fluid d-flex flex-column justify-content-center align-items-center' style={{ paddingBottom: '30px' }}>
        <h5>{test_title}</h5>
        <div className='d-flex justify-content-center'>{renderTestResult()}</div>
      </div>
    </IbisModal>
  );
};

export default TestResultsModal;
