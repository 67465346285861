import { useEffect, useState, Fragment } from 'react';
import VF2Matrix from './VF2Matrix';

const OptionSeparator = () => {
  return <div style={{ height: '50%', background: 'rgba(60, 60, 67, 0.36)', borderRadius: '0.5px', width: '1px' }}></div>;
};

const SegmentedPicker = ({ options, selectedOption, setSelectedOption }) => {
  const selectedOptionProps = {
    borderRadius: '7px',
    boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.12), 0px 3px 1px rgba(0, 0, 0, 0.04)',
    backgroundColor: 'var(--primary-color)',
    color: '#fff',
  };

  const renderOptions = (option, index) => {
    const nextOption = options[index + 1];
    const optionIsSelected = selectedOption === option;
    const isLastOption = index === options.length - 1;

    const shouldRenderSeparator = () => {
      if (!isLastOption && !optionIsSelected && nextOption !== selectedOption) {
        return true;
      }
      return false;
    };

    return (
      <Fragment key={`segmented-picker-option-${option}`}>
        <div
          className='d-flex align-items-center justify-content-center'
          style={{
            padding: '1.25rem 1.5rem',
            flexGrow: '1',
            height: '2rem',
            cursor: 'pointer',
            ...(optionIsSelected && { ...selectedOptionProps }),
            ...(!shouldRenderSeparator() && { borderRight: '0.5px solid transparent' }),
          }}
          onClick={() => setSelectedOption(option)}
        >
          <span>{option}</span>
        </div>
        {shouldRenderSeparator() && <OptionSeparator />}
      </Fragment>
    );
  };
  return (
    <div className='d-flex align-items-center' style={{ minWidth: '150px', height: '2rem', borderRadius: '8px', backgroundColor: '#fbfbfb' }}>
      {options?.map(renderOptions)}
    </div>
  );
};

const BoldText = ({ children }) => {
  return <span style={{ fontWeight: 'bold' }}>{children}</span>;
};

const Header = ({ eyesTested, selectedOption, setSelectedOption }) => {
  useEffect(() => {
    if (eyesTested.includes('Binocular')) return setSelectedOption('Binocular');
    if (eyesTested.includes('Right Eye')) return setSelectedOption('Right Eye');
    return setSelectedOption('Left Eye');
  }, [eyesTested, setSelectedOption]);

  return <SegmentedPicker options={eyesTested} selectedOption={selectedOption} setSelectedOption={setSelectedOption} marginBottom='1rem' />;
};

const VisualFieldTwoResults = ({ results: allResults }) => {
  const [selectedResults, setSelectedResults] = useState({});
  const [eyesTested, setEyesTested] = useState([]);
  const [currentEye, setCurrentEye] = useState();
  const [selectedOption, setSelectedOption] = useState();
  const [rawDotDataset, setRawDotDataset] = useState([]);

  useEffect(() => {
    if (allResults.bothEyes) return setEyesTested(['Binocular']);
    if (allResults.rightEye) setEyesTested((eyes) => [...eyes.filter((eye) => eye !== 'Right Eye'), 'Right Eye']);
    if (allResults.leftEye) setEyesTested((eyes) => [...eyes.filter((eye) => eye !== 'Left Eye'), 'Left Eye']);
  }, [allResults]);

  useEffect(() => {
    const eyeLabelToKey = {
      'Left Eye': 'leftEye',
      'Right Eye': 'rightEye',
      Binocular: 'bothEyes',
    };

    setCurrentEye(eyeLabelToKey[selectedOption]);
  }, [selectedOption]);

  useEffect(() => {
    if (currentEye) setSelectedResults(allResults[currentEye]);
  }, [currentEye, allResults]);

  useEffect(() => {
    const leftEyeBlindSpot = { degreeX: -15, degreeY: -1.5, blindspot: true, disabled: currentEye !== 'leftEye' };
    const rightEyeBlindSpot = { degreeX: 15, degreeY: -1.5, blindspot: true, disabled: currentEye !== 'rightEye' };

    const allDots = [3, 9, 15, 21].flatMap((degree1, outerIndex, arr) =>
      arr.flatMap((degree2, innerIndex) => {
        if (outerIndex === 2 && innerIndex === 3) return [];
        if (outerIndex === 3 && innerIndex > 1) return [];
        return [
          { degreeX: degree1, degreeY: degree2 },
          { degreeX: degree1, degreeY: -degree2 },
          { degreeX: -degree1, degreeY: degree2 },
          { degreeX: -degree1, degreeY: -degree2 },
        ];
      })
    );

    setRawDotDataset([...allDots, leftEyeBlindSpot, rightEyeBlindSpot]);
  }, [currentEye]);

  return (
    <div className='d-flex flex-column' width='750px' height='90%'>
      <Header eyesTested={eyesTested} selectedOption={selectedOption} setSelectedOption={setSelectedOption} />
      <div className='d-flex justify-content-between mt-4' height='100%'>
        <div className='d-flex flex-column justify-content-center p-1 mr-5' width='35%' height='100%'>
          <div className='d-flex flex-column'>
            <p>
              <BoldText>{`Fixation: `}</BoldText>
              {selectedResults?.fixationType?.label || 'Not found'}
            </p>
            <p>
              <BoldText>{`Response: `}</BoldText>
              {`Patient Mouse Click`}
            </p>
            <p>
              <BoldText>{`Greyscale: `}</BoldText> {`Maximum Contrast`}
            </p>
          </div>
          <div className='d-flex flex-column'>
            <p>
              <BoldText>{`Target Duration: `}</BoldText> {`${selectedResults?.targetDuration / 1000 || '--'}s`}
            </p>
            <p>
              <BoldText>{`Confidence Level: `}</BoldText> {`${selectedResults?.testConfidence || 0}%`}
            </p>
            <p>
              <BoldText>{`Seen:`}</BoldText> {`${selectedResults?.seenDots?.length || 0}`}
            </p>
            <p>
              <BoldText>{`Not Seen:`}</BoldText> {`${selectedResults?.notSeenDots?.length || 0}`}
            </p>
            <p>
              <BoldText>{`Patient Distance: `}</BoldText> {`${selectedResults?.patientDistance || '--'}mm`}
            </p>
          </div>
        </div>
        <VF2Matrix size={375} rawDotDataSet={rawDotDataset} testProgressData={selectedResults} />
      </div>
    </div>
  );
};

export default VisualFieldTwoResults;
