import { useEffect } from 'react';
import { useCompany } from '../../context/companyContext';
import { IbisAdministrator } from '../../helper/IbisUser';

const EditUserForm = ({ userType, saveUser, ibisUserType }) => {
  const { companies, getCompanies } = useCompany();

  const getUserData = () => {
    const clinicianIsAdmin = document.getElementById('edit_field_is_staff')?.checked;
    const adminIsClinician = document.getElementById('edit_field_is_optician')?.checked;
    let userData = {
      first_name: document.getElementById('edit_field_first_name').value,
      last_name: document.getElementById('edit_field_last_name').value,
      email: document.getElementById('edit_field_email').value,
      username: document.getElementById('edit_field_username').value,
      profile: { phone: document.getElementById('edit_field_phone') ? document.getElementById('edit_field_phone').value : '' },
      is_staff: clinicianIsAdmin,
      is_optician: adminIsClinician,
    };

    if (ibisUserType.constructor === IbisAdministrator) {
      userData['company'] = !isNaN(document.getElementById('edit_field_company').value) ? document.getElementById('edit_field_company').value : '';
    }

    return userData;
  };

  const getUserId = () => {
    return document.getElementById('edit_field_id').value;
  };

  const customSaveUser = async () => {
    const saveUserResponse = await saveUser(ibisUserType, getUserId(), getUserData());
    if (saveUserResponse) {
      document.querySelector('#edit' + userType + 'Modal .close').click();
    }
  };

  useEffect(() => {
    if (ibisUserType.constructor === IbisAdministrator) {
      getCompanies();
    }
  }, []);

  return (
    <div
      className='modal fade'
      id={'edit' + userType + 'Modal'}
      tabIndex='-1'
      role='dialog'
      aria-labelledby={'add' + userType + 'ModalLabel'}
      aria-hidden='true'
    >
      <div className='modal-dialog' role='document'>
        <div className='modal-content'>
          <div className='modal-header bg-primary-ibis'>
            <h5 className='modal-title' id={'add' + userType + 'ModalLabel'}>
              Add {userType === 'Optician' ? 'Clinician' : userType}
            </h5>
            <button className='close' type='button' data-dismiss='modal' aria-label='Close'>
              <span aria-hidden='true'>&times;</span>
            </button>
          </div>
          <div className='modal-body'>
            <form className='user' name='edit-user-form'>
              <div className='form-group row'>
                <div className='col-sm-6 mb-3 mb-sm-0'>
                  <input type='text' className='form-control form-control-user first_name' id='edit_field_first_name' placeholder='First Name' />
                  <div id='validationedit_field_first_name' className='invalid-feedback'>
                    <span id='edit_invalid_first_name_message'></span>
                  </div>
                </div>
                <div className='col-sm-6'>
                  <input type='text' className='form-control form-control-user last_name' id='edit_field_last_name' placeholder='Last Name' />
                  <div id='validationedit_field_last_name' className='invalid-feedback'>
                    <span id='edit_invalid_last_name_message'></span>
                  </div>
                </div>
              </div>
              <div className='form-group row'>
                <div className='col-sm-6'>
                  <input type='email' className='form-control form-control-user email' id='edit_field_email' placeholder='Email Address' />
                  <div id='validationedit_field_email' className='invalid-feedback'>
                    <span id='edit_invalid_email_message'></span>
                  </div>
                </div>
                <div className='col-sm-6'>
                  <input type='text' className='form-control form-control-user username' id='edit_field_username' placeholder='Username' />
                  <div id='validationedit_field_username' className='invalid-feedback'>
                    <span id='edit_invalid_username_message'></span>
                  </div>
                </div>
              </div>
              {userType.toLowerCase() !== 'administrator' ? (
                <div className='form-group row'>
                  <div className='col-sm-12'>
                    <input type='text' className='form-control form-control-user phone' id='edit_field_phone' placeholder='Phone' />
                    <div id='validationfield_phone' className='invalid-feedback'>
                      <span id='invalid_phone_message'></span>
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )}
              {userType.toLowerCase() === 'administrator' ? (
                <div className='form-group row'>
                  <div className='col-sm'>
                    <select className='form-control form-control company form-select mb-3' aria-label='Select a company' id='edit_field_company'>
                      <option>No company selected</option>
                      {companies?.length > 0
                        ? companies.map((company_option) => (
                            <option key={company_option.id} value={company_option.id}>
                              {company_option.name}
                            </option>
                          ))
                        : ''}
                    </select>
                    <div id='validationfield_company' className='invalid-feedback'>
                      <span id='invalid_company_message'></span>
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )}
              {userType.toLowerCase() === 'optician' && (
                <>
                  <div className='dropdown-divider mt-4 mb-3'></div>
                  <div className='custom-control custom-checkbox'>
                    <input type='checkbox' className='custom-control-input' id='edit_field_is_staff' />
                    <label className='custom-control-label' htmlFor='edit_field_is_staff'>
                      Clinician is Administrator
                    </label>
                  </div>
                </>
              )}
              {userType.toLowerCase() === 'administrator' && (
                <>
                  <div className='dropdown-divider mt-4 mb-3'></div>
                  <div className='custom-control custom-checkbox'>
                    <input type='checkbox' className='custom-control-input' id='edit_field_is_optician' />
                    <label className='custom-control-label' htmlFor='edit_field_is_optician'>
                      Administrator is Clinician
                    </label>
                  </div>
                </>
              )}
              <input type='hidden' className='form-control form-control-user id' id='edit_field_id' />
            </form>
          </div>
          <div className='modal-footer'>
            <button className='btn btn-secondary' type='button' data-dismiss='modal'>
              Cancel
            </button>
            <button
              className='btn btn-ibis'
              type='button'
              onClick={() => {
                customSaveUser(getUserId(), getUserData());
              }}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditUserForm;
