import { useEffect, useState } from 'react';
import { useUser } from '../../context/userContext';
import { IbisOptician } from '../../helper/IbisUser';
import IbisDataTable from '../DataTable/IbisDataTable';

const OpticiansList = ({ showInactiveOpticians }) => {
  const [opticiansToDisplay, setOpticiansToDisplay] = useState(null);
  const { opticians, deleteUser, editUser } = useUser();
  const loggedUser = JSON.parse(localStorage.getItem('loggedUser'));

  useEffect(() => {
    if (!opticians) return;
    if (showInactiveOpticians) {
      setOpticiansToDisplay(opticians);
    } else {
      setOpticiansToDisplay(
        opticians.filter((opt) => {
          return opt.is_active;
        })
      );
    }
  }, [opticians, showInactiveOpticians]);

  const getEditButton = (optician) => {
    return optician.is_active ? (
      <button
        className='btn btn-ibis btn-circle'
        data-toggle='modal'
        data-target='#editOpticianModal'
        onClick={() => editUser(new IbisOptician(), optician.id)}
      >
        <i className='fas fa-edit'></i>
      </button>
    ) : (
      <button className='btn btn-ibis btn-circle' disabled style={{ cursor: 'not-allowed' }}>
        <i className='fas fa-ban'></i>
      </button>
    );
  };

  const getDeleteButton = (optician) => {
    return optician.is_active ? (
      <button className='btn btn-danger btn-circle' onClick={() => deleteUser(new IbisOptician(), optician.id)}>
        <i className='fas fa-trash'></i>
      </button>
    ) : (
      <button className='btn btn-danger btn-circle' disabled style={{ cursor: 'not-allowed' }}>
        <i className='fas fa-ban'></i>
      </button>
    );
  };

  const getColumns = () => {
    return [
      {
        name: 'First Name',
        selector: (optician) => optician.first_name,
        sortable: true,
        filter_field: 'first_name',
      },
      {
        name: 'Last Name',
        selector: (optician) => optician.last_name,
        sortable: true,
        filter_field: 'last_name',
      },
      {
        name: 'Email',
        selector: (optician) => optician.email,
        sortable: true,
        filter_field: 'email',
      },
      {
        name: 'Phone',
        selector: (optician) => optician.profile?.phone,
        sortable: true,
        filter_field: 'profile.phone',
      },
      {
        name: 'Username',
        selector: (optician) => optician.username,
        sortable: true,
        filter_field: 'username',
      },
      { name: 'Company', selector: (optician) => optician.company, sortable: true, filter_field: 'company', omit: !loggedUser?.superuser },
      {
        name: 'Status',
        selector: (optician) => (optician.is_active ? 'Active' : 'Inactive'),
        sortable: true,
        omit: !showInactiveOpticians,
        center: true,
      },
      {
        name: 'Edit',
        selector: (optician) => getEditButton(optician),
        button: true,
        center: true,
      },
      {
        name: 'Remove',
        selector: (optician) => getDeleteButton(optician),
        button: true,
        center: true,
      },
    ];
  };

  return <IbisDataTable title='Clinicians' data={opticiansToDisplay} columns={getColumns()} conditionalRowStyles={inactiveOpticianStyles} />;
};

export default OpticiansList;

const inactiveOpticianStyles = [{ when: (row) => !row.is_active, style: { backgroundColor: 'rgba(255, 163, 164, 0.6)' } }];
