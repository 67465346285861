import { useEffect, useState } from 'react';
import { getAppointments } from '../api/appointmentFunctions';
import ResponsiveLoadingPage from './ResponsiveLoadingPage';

const logout = () => {
  localStorage.removeItem('__endpoint__');
  localStorage.removeItem('token');
  localStorage.removeItem('loggedUser');
  localStorage.removeItem('__ibis_token__');
  window.location.href = process.env.PUBLIC_URL + '/login';
  window.Intercom('shutdown');
};

const StartButton = ({ appointment, loggedUser }) => {
  return (
    <button
      style={{
        border: 0,
        backgroundColor: 'var(--primary-color)',
        borderRadius: '4px',
        color: 'white',
        padding: '0.5rem',
        minWidth: '75px',
        width: '75%',
      }}
      onClick={() => {
        if (loggedUser.role === 'patient') {
          window.open(
            `/health/?userID=${loggedUser.id}&userName=${loggedUser.username}&userEmail=${loggedUser.email}&roomName=${appointment?.room_name}`,
            '_blank'
          );
        } else {
          window.open(`/start/${appointment?.room_name}`, '_blank');
        }
      }}
    >
      Start
    </button>
  );
};

const HealthButton = ({ appointment, loggedUser }) => {
  return (
    <button
      style={{
        border: 0,
        backgroundColor: 'var(--primary-color)',
        borderRadius: '4px',
        color: 'white',
        padding: '0.5rem',
        minWidth: '75px',
        width: '75%',
        marginTop: '10px',
      }}
      onClick={() =>
        window.open(
          `/health/?userID=${loggedUser.id}&userName=${loggedUser.username}&userEmail=${loggedUser.email}&roomName=${appointment?.room_name}`,
          '_blank'
        )
      }
    >
      Check Connection Health
    </button>
  );
};

const AppointmentCard = ({ appointment, smallScreen }) => {
  const loggedUser = JSON.parse(localStorage.getItem('loggedUser'));
  const appointmentBelongsToUser = loggedUser.id === appointment.optician;
  const patientView = loggedUser?.role === 'patient';
  const { start, patient_full_name, optician_full_name, optician_company } = appointment;
  const apptDate = start?.split(' ')[0];
  const apptTime = start?.split(' ')[1];
  return (
    <div
      style={{
        width: smallScreen ? '100%' : '35vw',
        maxWidth: smallScreen ? null : '250px',
        height: smallScreen ? null : '30vw',
        maxHeight: '250px',
        padding: '1rem',
        margin: smallScreen ? null : '1rem 2.5vw',
        ...(smallScreen && { marginTop: '0.5rem' }),
        boxShadow: '0px 6px 10px 4px rgba(0, 0, 0, 0.15), 0px 2px 3px rgba(0, 0, 0, 0.3)',
        borderRadius: '8px',
        fontSize: '0.875rem',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: smallScreen ? 'row' : 'column',
          justifyContent: 'space-between',
          alignItems: smallScreen ? null : 'center',
          width: '100%',
          marginBottom: '2rem',
          overflow: 'auto',
        }}
      >
        <span
          style={{
            width: smallScreen ? '50%' : '100%',
            marginBottom: smallScreen ? null : '15%',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textAlign: smallScreen ? null : 'center',
          }}
        >
          <strong>{patientView ? optician_full_name : patient_full_name}</strong>
          {patientView && <p style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{`(${optician_company})`}</p>}
        </span>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <span>{apptDate}</span>
          <span>{apptTime}</span>
        </div>
      </div>
      {loggedUser.role === 'patient' || appointmentBelongsToUser ? <StartButton appointment={appointment} loggedUser={loggedUser} /> : null}
      {loggedUser.role !== 'patient' && appointmentBelongsToUser ? <HealthButton appointment={appointment} loggedUser={loggedUser} /> : null}
    </div>
  );
};

const ResponsiveDashboard = () => {
  const [smallScreen, setSmallScreen] = useState(false);
  const [appointmentsLoaded, setAppointmentsLoaded] = useState(false);
  const [openAppointments, setOpenAppointments] = useState([]);

  useEffect(() => {
    const checkMatch = () => {
      if (smallScreenQuery.matches) {
        setSmallScreen(true);
      } else setSmallScreen(false);
    };

    const smallScreenQuery = window.matchMedia('(max-width: 700px)');
    checkMatch();

    smallScreenQuery.addEventListener('change', checkMatch);
    return () => smallScreenQuery.removeEventListener('change', checkMatch);
  }, []);

  useEffect(() => {
    if (appointmentsLoaded === false && openAppointments.length === 0) {
      getAppointments().then((response) => {
        const openedAppointments = response.filter((appt) => {
          return appt.status === 'open';
        });
        setOpenAppointments(openedAppointments);
        setAppointmentsLoaded(true);
      });
    }
  }, [appointmentsLoaded, openAppointments]);

  return (
    <div
      style={{
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        width: '100vw',
        height: `${window.innerHeight}px`,
        alignItems: 'center',
        padding: '0.5rem',
        overflow: 'auto',
      }}
    >
      <div
        className='bg-primary-ibis'
        style={{
          width: '100%',
          padding: '1rem 0.5rem',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          boxShadow: '0px 6px 10px 4px rgba(0, 0, 0, 0.15), 0px 2px 3px rgba(0, 0, 0, 0.3)',
        }}
      >
        <h6 className='m-0 font-weight-bold text-white'>{'Your Appointments'}</h6>
        <span onClick={() => logout()}>
          <span style={{ fontWeight: 700, marginRight: '0.25rem' }}>Logout</span>
          <i className={`fas fa-sign-out-alt fa-${smallScreen ? 'md' : 'lg'} fa-fw text-white-400 ${!smallScreen ? 'mr-2' : null}`} />
        </span>
      </div>
      <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
        {appointmentsLoaded ? (
          openAppointments.map((appt) => {
            return <AppointmentCard appointment={appt} key={`appointment-id-${appt.id}`} smallScreen={smallScreen} />;
          })
        ) : (
          <ResponsiveLoadingPage />
        )}
      </div>
    </div>
  );
};

export default ResponsiveDashboard;
