import { useState, useEffect } from 'react';
import { getAppointmentQuestionnaire } from '../../api/questionnaireFunctions';

const AppointmentQuestionnaireQuestions = ({ appointmentQuestionnaireId, answers }) => {
  const [questionnaireQuestions, setQuestionnaireQuestions] = useState([]);
  const [questionnaireFilteredQuestions, setQuestionnaireFilteredQuestions] = useState([]);

  useEffect(() => {
    if (!appointmentQuestionnaireId) return;
    const getQuestions = async () => {
      const { questions, filtered_questions } = (await getAppointmentQuestionnaire(appointmentQuestionnaireId)) || [];
      setQuestionnaireQuestions(questions);
      setQuestionnaireFilteredQuestions(filtered_questions);
    };

    getQuestions();
  }, [appointmentQuestionnaireId]);

  const getAnswer = (question) => {
    if (question.question_type === 'boolean') {
      if (question.answer === true) {
        return 'Yes';
      } else if (question.answer === false) {
        return 'No';
      }
    }

    if (question.question_type === 'checkbox') {
      return (
        <ul className='m-0 p-0'>
          {question.options.map((opt) => {
            const optionSelected = question.answer.some((ans) => ans === opt.position);
            return (
              <li key={opt.id} style={{ listStyle: 'none' }}>
                <span className='border-bottom my-3 pb-1 d-flex justify-content-between' style={{ width: '275px' }}>
                  <p className='m-0'>{opt.text}</p>
                  <strong className={`m-0 text-center ${optionSelected ? 'bg-success text-white' : ''}`} style={{ width: '40px' }}>
                    {optionSelected ? 'YES' : 'NO'}
                  </strong>
                </span>
              </li>
            );
          })}
        </ul>
      );
    }

    return question.answer;
  };

  const getQuestionType = (question_type) => {
    if (question_type === 'boolean') {
      return 'Yes or No';
    } else if (question_type === 'text') {
      return 'Text';
    } else if (question_type === 'checkbox') {
      return 'Checkbox';
    }
  };

  const getQuestionnaireQuestionList = (answers) => {
    if (answers === true) {
      return (
        <>
          {questionnaireFilteredQuestions?.length ? (
            questionnaireFilteredQuestions.map((appointment_question) => {
              return (
                <tr key={appointment_question.id}>
                  <td>{appointment_question.text}</td>
                  <td>{getAnswer(appointment_question)}</td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan='2'>
                <h4 className='text-center'>No patient answers</h4>
              </td>
            </tr>
          )}
        </>
      );
    } else {
      return (
        <>
          {questionnaireQuestions && questionnaireQuestions.length > 0 ? (
            questionnaireQuestions.map((appointment_question) => {
              return (
                <tr key={appointment_question.id}>
                  <td>{appointment_question.text}</td>
                  <td>{getQuestionType(appointment_question.question_type)}</td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan='2'>
                <h4 className='text-center'>No questionnaire questions</h4>
              </td>
            </tr>
          )}
        </>
      );
    }
  };

  return getQuestionnaireQuestionList(answers);
};

export default AppointmentQuestionnaireQuestions;
