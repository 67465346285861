export const fisherYatesShuffle = (arr) => {
  for (let i = arr.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [arr[i], arr[j]] = [arr[j], arr[i]];
  }
  return arr;
};

export const ishiharaFamiliarisationPlate = {
  id: '01',
  options: ['12', '37'],
};

export const ishiharaPlates = [
  {
    id: '02',
    answer: '8',
    options: ['8', '3'],
    penalty_answer: '3',
  },
  {
    id: '03',
    answer: '5',
    options: ['5', '2'],
    penalty_answer: '2',
  },
  {
    id: '04',
    answer: '29',
    options: ['29', '70'],
    penalty_answer: '70',
  },
  {
    id: '05',
    answer: '74',
    options: ['74', '21'],
    penalty_answer: '21',
  },
  {
    id: '06',
    answer: '7',
    options: ['7', '4'],
    penalty_answer: 'unclear',
  },
  {
    id: '07',
    answer: '45',
    options: ['45', '73'],
    penalty_answer: 'unclear',
  },
  {
    id: '08',
    answer: '2',
    options: ['2', '8'],
    penalty_answer: '8',
  },
  {
    id: '09',
    answer: 'unclear',
    options: ['8', '2'],
    penalty_answer: '2',
  },
  {
    id: '10',
    answer: '16',
    options: ['16', '33'],
    penalty_answer: 'unclear',
  },
];

export const fixedOrderPlates = [
  {
    id: '11',
    answer: 'Yes',
    options: ['Yes', 'No'],
    custom_instructions: 'Can you see a path traced from left to right across the plate?',
  },
];

export const ishiharaAdditionalPlates = [
  {
    id: '12',
    answer: '3 or 5',
    options: ['5', '3'],
    custom_instructions: 'Indicate if you see a number on the plate.  If you see more than one number please select the most distinct.',
  },
  {
    id: '13',
    answer: '9 or 6',
    options: ['6', '9'],
    custom_instructions: 'Indicate if you see a number on the plate.  If you see more than one number please select the most distinct.',
  },
];
