import OpticianContent from './OpticianContent';
import { useEffect } from 'react';
import { IbisOptician } from '../../helper/IbisUser';
import { useUser } from '../../context/userContext';
import { getUsers } from '../../api/userFunctions';

const Optician = () => {
  const { setOpticians } = useUser();
  useEffect(() => {
    getUsers(new IbisOptician()).then((response) => setOpticians(response));
  }, [setOpticians]);

  return <OpticianContent />;
};

export default Optician;
