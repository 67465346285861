const Questionnaire = ({ questionnaire }) => {
  const isObject = (value) => value !== null && typeof value === 'object';
  return (
    <table className='table table-bordered table-striped w-75'>
      <thead>
        <tr>
          <th colSpan='2' className='text-center bg-primary-ibis'>
            Questionnaire
          </th>
        </tr>
      </thead>
      <tbody>
        {(isObject(questionnaire) &&
          Object.values(questionnaire).map((question, index) => (
            <tr key={index}>
              <td className='font-weight-bold'>{question?.question?.question || ''}</td>
              <td className='text-capitalize'>{question?.question?.answer || ''}</td>
            </tr>
          ))) || (
          <tr>
            <td colSpan={2}>No questionnaire found.</td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default Questionnaire;
