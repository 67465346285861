import { useState, useEffect } from 'react';
import { useCompany } from '../../context/companyContext';
import { fetchObjects } from '../../api/crudFunctions';
import CompanyCountrySelect from './CompanyCountrySelect';
import CompanyTimeZoneSelect from './CompanyTimeZoneSelect';
import { useHistory } from 'react-router-dom';
import moment from 'moment-timezone';
import DateFormatSelect from './DateFormatSelect';

const EditCompanyForm = ({ toggle, companyId }) => {
  const { saveCompany } = useCompany();
  const [companyObject, setCompanyObject] = useState({});
  const history = useHistory();
  const [timezoneOptions, setTimezoneOptions] = useState([]);

  const {
    name = '',
    description = '',
    contact_email = '',
    contact_name = '',
    country = '',
    address_1 = '',
    address_2 = '',
    city = '',
    postcode = '',
    phone = '',
    vat = '',
    timezone = '',
    timezone_offset = '0',
    dateformat = '',
  } = companyObject;

  const handleEditCompanyFormSubmit = async () => {
    const companySaved = await saveCompany(companyId, companyObject);
    if (companySaved) {
      return toggle ? toggle() : history.goBack();
    }
  };

  useEffect(() => {
    fetchObjects(`/api/companies/${companyId}`).then((response) => setCompanyObject(response));
  }, [companyId]);

  useEffect(() => {
    if (companyObject.country) {
      setTimezoneOptions(
        moment.tz.zonesForCountry(companyObject.country)?.map((country_zone) => {
          return {
            value: country_zone + '|' + moment.tz(country_zone).utcOffset(),
            label: country_zone,
          };
        })
      );
    }
  }, [companyObject]);

  return (
    <div className='card shadow' style={{ width: '50vw', marginBottom: '2rem' }}>
      <div className='card-header bg-primary-ibis text-white bg-ibis text-center'>
        <h4>Company Details</h4>
      </div>
      <div className='card-body'>
        <form>
          <div className='form-group row'>
            <div className='col mb-3 mb-sm-0'>
              <input
                type='text'
                className='form-control form-control-company name'
                id='edit_field_name'
                placeholder='Company Name'
                value={name}
                onChange={(e) =>
                  setCompanyObject((obj) => {
                    return { ...obj, name: e.target.value };
                  })
                }
              />
              <div id='validationedit_field_name' className='invalid-feedback'>
                <span id='edit_invalid_name_message'></span>
              </div>
            </div>
          </div>
          <div className='form-group row'>
            <div className='col mb-3 mb-sm-0'>
              <input
                type='text'
                className='form-control description'
                id='edit_field_description'
                placeholder='Description'
                value={description}
                onChange={(e) =>
                  setCompanyObject((obj) => {
                    return { ...obj, description: e.target.value };
                  })
                }
              />
              <div id='validationedit_field_description' className='invalid-feedback'>
                <span id='edit_invalid_description_message'></span>
              </div>
            </div>
          </div>
          <div className='form-group row'>
            <div className='col-sm-6'>
              <input
                type='email'
                className='form-control form-control-company contact_email'
                id='edit_field_contact_email'
                placeholder='Contact Email'
                value={contact_email}
                onChange={(e) =>
                  setCompanyObject((obj) => {
                    return { ...obj, contact_email: e.target.value };
                  })
                }
              />
              <div id='validationedit_field_contact_email' className='invalid-feedback'>
                <span id='edit_invalid_contact_email_message'></span>
              </div>
            </div>

            <div className='col mb-3 mb-sm-0'>
              <input
                type='text'
                className='form-control form-control-company contact_name'
                id='edit_field_contact_name'
                placeholder='Contact Name'
                value={contact_name}
                onChange={(e) =>
                  setCompanyObject((obj) => {
                    return { ...obj, contact_name: e.target.value };
                  })
                }
              />
              <div id='validationedit_field_contact_name' className='invalid-feedback'>
                <span id='edit_invalid_contact_name_message'></span>
              </div>
            </div>
          </div>
          <div className='form-group row'>
            <div className='col mb-3 mb-sm-0'>
              <input
                type='text'
                className='form-control form-control-company address_1'
                id='edit_field_address_1'
                placeholder='Address Line 1'
                value={address_1}
                onChange={(e) =>
                  setCompanyObject((obj) => {
                    return { ...obj, address_1: e.target.value };
                  })
                }
              />
              <div id='validationedit_field_address_1' className='invalid-feedback'>
                <span id='edit_invalid_address_1_message'></span>
              </div>
            </div>
          </div>
          <div className='form-group row'>
            <div className='col mb-3 mb-sm-0'>
              <input
                type='text'
                className='form-control form-control-company address_2'
                id='edit_field_address_2'
                placeholder='Address Line 2'
                value={address_2}
                onChange={(e) =>
                  setCompanyObject((obj) => {
                    return { ...obj, address_2: e.target.value };
                  })
                }
              />
              <div id='validationedit_field_address_2' className='invalid-feedback'>
                <span id='edit_invalid_address_2_message'></span>
              </div>
            </div>
          </div>
          <div className='form-group row'>
            <div className='col mb-3 mb-sm-0'>
              <input
                type='text'
                className='form-control form-control-company city'
                id='edit_field_city'
                placeholder='City'
                value={city}
                onChange={(e) =>
                  setCompanyObject((obj) => {
                    return { ...obj, city: e.target.value };
                  })
                }
              />
              <div id='validationedit_field_city' className='invalid-feedback'>
                <span id='edit_invalid_city_message'></span>
              </div>
            </div>
            <div className='col mb-3 mb-sm-0'>
              <input
                type='text'
                className='form-control form-control-company postcode'
                id='edit_field_postcode'
                placeholder='Postcode'
                value={postcode}
                onChange={(e) =>
                  setCompanyObject((obj) => {
                    return { ...obj, postcode: e.target.value };
                  })
                }
              />
              <div id='validationedit_field_postcode' className='invalid-feedback'>
                <span id='edit_invalid_postcode_message'></span>
              </div>
            </div>
          </div>
          <div className='form-group row'>
            <div className='col mb-3 mb-sm-0'>
              <CompanyCountrySelect
                className='form-control form-control-company country'
                id='edit_field_country'
                value={country}
                onChange={(e) =>
                  setCompanyObject((obj) => {
                    return { ...obj, country: e.target.value, timezone: '', timezone_offset: 0 };
                  })
                }
              />
              <div id='validationedit_field_country' className='invalid-feedback'>
                <span id='edit_invalid_country_message'></span>
              </div>
            </div>
            <div className='col mb-3 mb-sm-0'>
              <CompanyTimeZoneSelect
                className='form-control form-control-timezone timezone'
                id='field_timezone'
                value={`${timezone}|${timezone_offset}`}
                onChange={(e) =>
                  setCompanyObject((obj) => {
                    return { ...obj, timezone: e.target.value.split('|')[0], timezone_offset: e.target.value.split('|')[1] };
                  })
                }
                options={timezoneOptions}
              />
              <div id='validationfield_timezone' className='invalid-feedback'>
                <span id='invalid_timezone_message'></span>
              </div>
            </div>
            <div className='col mb-3 mb-sm-0'>
              <DateFormatSelect
                value={dateformat}
                id='field_dateformat'
                className='form-control form-control-dateformat dateformat'
                onChange={(e) =>
                  setCompanyObject((obj) => {
                    return { ...obj, dateformat: e.target.value };
                  })
                }
                style={{ color: '#858796', paddingLeft: '0.375rem' }}
              />
            </div>
          </div>
          <div className='form-group row'>
            <div className='col mb-3 mb-sm-0'>
              <input
                type='number'
                className='form-control form-control-company phone'
                id='edit_field_phone'
                placeholder='Phone number'
                value={phone}
                onChange={(e) =>
                  setCompanyObject((obj) => {
                    return { ...obj, phone: e.target.value };
                  })
                }
              />
              <div id='validationedit_field_phone' className='invalid-feedback'>
                <span id='edit_invalid_phone_message'></span>
              </div>
            </div>
          </div>
          <div className='form-group row'>
            <div className='col mb-3 mb-sm-0'>
              <input
                type='number'
                className='form-control form-control-company vat'
                id='edit_field_vat'
                placeholder='VAT Number'
                value={vat}
                onChange={(e) =>
                  setCompanyObject((obj) => {
                    return { ...obj, vat: e.target.value };
                  })
                }
              />
              <div id='validationedit_field_vat' className='invalid-feedback'>
                <span id='edit_invalid_vat_message'></span>
              </div>
            </div>
          </div>
        </form>

        <div className='modal-footer'>
          <button className='btn btn-secondary' type='button' onClick={() => (toggle ? toggle() : history.goBack())}>
            Cancel
          </button>
          <button
            className='btn btn-ibis'
            type='button'
            onClick={() => {
              handleEditCompanyFormSubmit();
            }}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditCompanyForm;
