import { fetchObjects, detailObject } from './crudFunctions';

const getApiUrl = (domain, objectId) => {
  if (objectId) {
    return `/api/${domain}/${objectId}/`;
  }

  return `/api/${domain}/`;
};

const getQuestionnaireQuestionApiUrl = (objectId) => {
  return getApiUrl('questionnaire/questionnaire-questions', objectId);
};

const fetchQuestionnaireQuestions = async (apiUrl) => {
  return await fetchObjects(apiUrl);
};

export const getQuestionnaireQuestions = async (questionId, questionnaireId) => {
  if (questionId === undefined) {
    questionId = '';
  }

  if (questionnaireId === undefined) {
    questionnaireId = '';
  }

  let apiUrl = `${getQuestionnaireQuestionApiUrl()}?question=${questionId}&questionnaire=${questionnaireId}`;

  const dataFromServer = await fetchQuestionnaireQuestions(apiUrl);

  return dataFromServer.results;
};

export const getAppointmentQuestionnaire = async (appointmentQuestionnaireId) => {
  const res = await detailObject(`/api/appointment-questionnaire/${appointmentQuestionnaireId}`);

  try {
    const appointmentQuestionnaireObject = await res.json();
    return appointmentQuestionnaireObject;
  } catch (error) {
    const appointmentQuestionnaireObject = error;
    return appointmentQuestionnaireObject;
  }
};
