import { useParams } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import { getAppointment } from '../../api/appointmentFunctions';
import { getPatient } from '../../api/userFunctions';
import { AppointmentResultsContent } from './AppointmentResultsContent';
import ReactToPrint from 'react-to-print';
import { IbisPatient } from '../../helper/IbisUser';

const AppointmentResults = () => {
  const { appointmentId } = useParams();
  const [appointment, setAppointment] = useState(null);
  const [patient, setPatient] = useState(null);

  const componentRef = useRef();

  useEffect(() => {
    getAppointment(appointmentId).then((appt) => setAppointment(appt));
  }, [appointmentId]);

  useEffect(() => {
    if (appointment) {
      getPatient(appointment.patient).then((patient) => setPatient(patient));
    }
  }, [appointment]);

  return (
    <>
      <ReactToPrint trigger={() => <button className='btn btn-lg btn-ibis m-2'>Print Results</button>} content={() => componentRef.current} />
      {appointment && <AppointmentResultsContent appointment={appointment} ref={componentRef} patient={patient} />}
    </>
  );
};

export default AppointmentResults;
