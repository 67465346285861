import CompanyContent from './CompanyContent';
import { useEffect } from 'react';
import { useCompany } from '../../context/companyContext';

const Company = () => {
  const { getCompanies } = useCompany();

  useEffect(() => {
    getCompanies();
  }, []);

  return <CompanyContent />;
};

export default Company;
