import AdminDashboard from './pages/AdminDashboard';
import Patient from './components/User/Patient';
import Optician from './components/User/Optician';
import Appointment from './components/Appointment/Appointment';
import WaitingRoom from './pages/WaitingRoom';
import Login from './components/User/Login';
import Register from './components/User/Register';
import AppointmentResults from './components/Appointment/AppointmentResults.js';
import Company from './components/Company/Company';
import Administrator from './components/User/Administrator';
import Invitations from './components/Misc/Invitations';
import IshiharaTest from './pages/IshiharaTest';
import { ApiProvider } from './context/apiContext';
import { CrudProvider } from './context/crudContext';
import { UserProvider } from './context/userContext';
import { AppointmentProvider } from './context/appointmentContext';
import { QuestionnaireProvider } from './context/questionnaireContext';
import { CompanyProvider } from './context/companyContext';
import { useAuth } from './context/authenticationContext';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Sidebar from './components/Misc/Sidebar';
import Topbar from './components/Misc/Topbar';
import ResetPassword from './components/User/ResetPassword';
import ResetPasswordSuccess from './components/User/ResetPasswordSuccess';
import TestResults from './pages/TestResults';
import PageNotFound from './pages/PageNotFound';
import EditCompany from './pages/EditCompany';
import ResponsiveDashboard from './pages/ResponsiveDashboard';
import PreScreening from './pages/PreScreening';
import { useEffect, useState } from 'react';
import CompleteQuestionnaire from './pages/CompleteQuestionnaire';
import ConsentModal from './components/Modals/ConsentModal';
import RefusedConsent from './components/Misc/RefusedConsent';
import CustomThemeSelector from './components/Misc/CustomThemeSelector';
import QuestionnaireRoutes from './routes/QuestionnaireRoutes';
import RecheckSessions from './components/Recheck/RecheckSessions.js';
import { RecheckProvider } from './context/recheckContext.js';
import RecheckActivityLog from './components/Recheck/RecheckActivityLog.js';
import RecheckIndividualSession from './components/Recheck/IndividualSessions/RecheckIndividualSession.js';
import RecheckSettings from './components/Recheck/RecheckSettings.js';
import { getTitle } from './helper/general.js';

function App() {
  const [desktopView, setDesktopView] = useState(true);
  const { user } = useAuth();
  const userHasConsented = user?.profile?.consent;

  useEffect(() => {
    document.title = getTitle();
    const checkMatch = () => {
      return desktopViewQuery.matches ? setDesktopView(true) : setDesktopView(false);
    };

    const desktopViewQuery = window.matchMedia('(min-width: 1100px) and (hover: hover), (min-width: 1100px) and (pointer: fine)');
    checkMatch();

    desktopViewQuery.addEventListener('change', checkMatch);
    return () => desktopViewQuery.removeEventListener('change', checkMatch);
  }, []);

  const getHomepageComponent = () => {
    switch (user?.role) {
      case 'admin':
        return <AdminDashboard />;
      case 'optician':
        if (user?.company_info?.engage_enabled) return <WaitingRoom />;
        else return <RecheckSessions />;
      case 'patient':
        return <Appointment />;
      default:
        return <Appointment />;
    }
  };

  return (
    <ApiProvider>
      <CrudProvider>
        <UserProvider>
          <AppointmentProvider>
            <QuestionnaireProvider>
              <CompanyProvider>
                <RecheckProvider>
                  <Router>
                    {!user ? (
                      <>
                        <Route path={`${process.env.PUBLIC_URL}/login`}>
                          <Login />
                        </Route>
                        <Route path={`${process.env.PUBLIC_URL}/register`}>
                          <Register />
                        </Route>
                        <Route path={`${process.env.PUBLIC_URL}/reset-password`}>
                          <ResetPassword />
                        </Route>
                        <Route path={`${process.env.PUBLIC_URL}/reset-password-success`}>
                          <ResetPasswordSuccess />
                        </Route>
                        <Route path={`${process.env.PUBLIC_URL}/refused-consent`}>
                          <RefusedConsent />
                        </Route>
                      </>
                    ) : (
                      <>
                        {!userHasConsented && <ConsentModal modalIsOpen={true} />}
                        {!desktopView ? (
                          <ResponsiveDashboard />
                        ) : (
                          <>
                            <Sidebar />
                            <div id='content-wrapper' className='d-flex flex-column pb-4'>
                              <div id='content' className='pb-5'>
                                <Topbar />
                                <Switch>
                                  <Route path={`${process.env.PUBLIC_URL}/`} exact>
                                    {getHomepageComponent()}
                                  </Route>
                                  <Route path={`/theme`}>
                                    <CustomThemeSelector />
                                  </Route>
                                  <Route path={`${process.env.PUBLIC_URL}/login`}>{getHomepageComponent()}</Route>
                                  <Route path={`${process.env.PUBLIC_URL}/patients`}>
                                    <Patient />
                                  </Route>
                                  <Route path={`${process.env.PUBLIC_URL}/opticians`}>
                                    <Optician />
                                  </Route>
                                  <Route path={`${process.env.PUBLIC_URL}/waiting-room/:origin?`}>
                                    <WaitingRoom />
                                  </Route>
                                  <Route path={`${process.env.PUBLIC_URL}/appointments`}>
                                    <Appointment />
                                  </Route>
                                  <Route path={`${process.env.PUBLIC_URL}/appointment-results/:appointmentId/:origin?`} exact>
                                    <AppointmentResults />
                                  </Route>
                                  <Route path={`${process.env.PUBLIC_URL}/companies`}>
                                    <Company />
                                  </Route>
                                  <Route path={`${process.env.PUBLIC_URL}/administrators`}>
                                    <Administrator />
                                  </Route>
                                  <Route path={`${process.env.PUBLIC_URL}/invitations`}>
                                    <Invitations />
                                  </Route>
                                  <Route path={`${process.env.PUBLIC_URL}/test-results`}>
                                    <TestResults />
                                  </Route>
                                  <Route path={`${process.env.PUBLIC_URL}/edit-company-info/:companyId`}>
                                    <EditCompany />
                                  </Route>
                                  <Route path={`${process.env.PUBLIC_URL}/edit-company-features/:companyId`}>
                                    <EditCompany />
                                  </Route>
                                  <Route path={`${process.env.PUBLIC_URL}/pre-screening/:apptId`}>
                                    <PreScreening />
                                  </Route>
                                  <Route path={`${process.env.PUBLIC_URL}/:apptId/ishihara-test`}>
                                    <IshiharaTest />
                                  </Route>
                                  <Route exact path={'/recheck/sessions'}>
                                    <RecheckSessions />
                                  </Route>
                                  <Route path={'/recheck/activity/:sessionId'}>
                                    <RecheckActivityLog />
                                  </Route>
                                  <Route path={'/recheck/sessions/:sessionId'}>
                                    <RecheckIndividualSession />
                                  </Route>
                                  <Route path={'/recheck/settings'}>
                                    <RecheckSettings />
                                  </Route>
                                  {user?.role === 'admin' && <QuestionnaireRoutes />}
                                  <Route path={`${process.env.PUBLIC_URL}/:apptId/questionnaire-:questionnaireId`}>
                                    <CompleteQuestionnaire />
                                  </Route>
                                  <Route path='*'>
                                    <PageNotFound />
                                  </Route>
                                </Switch>
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </Router>
                </RecheckProvider>
              </CompanyProvider>
            </QuestionnaireProvider>
          </AppointmentProvider>
        </UserProvider>
      </CrudProvider>
    </ApiProvider>
  );
}

export default App;
