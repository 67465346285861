import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getTitle } from '../../helper/general';

const ResetPasswordSuccess = () => {
  const [fadeIn, setFadeIn] = useState(false);
  const history = useHistory();

  useEffect(() => {
    setFadeIn(true);

    setTimeout(() => {
      history.push('/login');
    }, 4000);
  }, [history]);

  return (
    <div className='container'>
      <div className='row justify-content-center'>
        <div className='col-xl-10 col-lg-12 col-md-9'>
          <div className='card o-hidden border-0 shadow-lg my-5'>
            <div className='card-body p-5'>
              <div className='row'>
                <div className='col-lg-6 d-none d-lg-block bg-login-image'></div>
                <div className='col-lg-6 mb-4'>
                  <div className='p-5' style={{ height: '326px' }}>
                    <div className='text-center'>
                      <h1 className='h4  mb-4 text-ibis'>{getTitle()}</h1>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', height: '200px' }}>
                      <div className='text-center' style={{ opacity: `${fadeIn ? 1 : 0}`, transition: 'opacity 2s ease' }}>
                        Password reset successfully.
                      </div>
                      <div
                        className='text-center'
                        style={{ opacity: `${fadeIn ? 1 : 0}`, transition: 'opacity 2s ease 1s', marginTop: '20px', marginBottom: '20px' }}
                      >
                        Please wait while you are redirected to the login page.
                      </div>
                      <div className='dot-flashing'></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordSuccess;
