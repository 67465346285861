import { useState } from 'react';
import PrintKey from '../VisualField/PrintKey';
import PrintMatrix from '../VisualField/PrintMatrix';
import PrintStrokeAcuity from './PrintStrokeAcuity';

const PrintStrokeSuite = ({ results }) => {
  const [outOfBoundariesDots, setOutOfBoundariesDots] = useState([]);
  //these must be handled separately as they are not saved as part of the results.
  const { missedTwiceDots, orientationCorrect, orientationIncorrect, testConfidence } = results;

  return (
    <div className='d-flex flex-column bg-white my-3' width='750px'>
      <table className='table table-bordered m-0'>
        <thead>
          <tr>
            <th style={{ width: '20%', verticalAlign: 'middle' }}>Stroke Suite</th>
            <th>
              <PrintKey testType={'stroke'} />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={2}>
              <div className='d-flex justify-content-between align-items-center'>
                <div className='d-flex flex-column'>
                  <ul className='list-group list-group-flush'>
                    <li className='list-group-item'>
                      <p className='h4 mb-3 text-uppercase font-weight-bold'>
                        <u>{'Visual Field'}</u>
                      </p>
                      <div className='d-flex flex-column'>
                        <TestInfoText category={'orientation correct'}>{orientationCorrect?.length || 0}</TestInfoText>
                        <TestInfoText category={'orientation incorrect'}>{orientationIncorrect?.length || 0}</TestInfoText>
                        <TestInfoText category={'not seen'}>{missedTwiceDots?.length || 0}</TestInfoText>
                        <TestInfoText category={'out of boundaries'}>{outOfBoundariesDots?.length || 0}</TestInfoText>
                        <TestInfoText category={'confidence level'}>{`${testConfidence || 0}%`}</TestInfoText>
                      </div>
                    </li>
                    <PrintStrokeAcuity results={results} />
                  </ul>
                </div>
                <PrintMatrix
                  size={375}
                  rawDotDataSet={results.forthValleyVisualFieldDots}
                  testProgressData={results}
                  localOutOfBoundariesDots={outOfBoundariesDots}
                  setLocalOutOfBoundariesDots={setOutOfBoundariesDots}
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default PrintStrokeSuite;

const TestInfoText = ({ category, children }) => {
  return (
    <div className='d-flex'>
      <p className='font-weight-bold mr-1 text-capitalize'>{`${category}:`}</p>
      <p>{children}</p>
    </div>
  );
};
