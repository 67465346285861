import Modal from 'react-modal';

const IbisModal = ({ modalIsOpen, toggle, title, children, ...props }) => {
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={toggle}
      role='document'
      appElement={document.getElementById('wrapper')}
      closeTimeoutMS={300}
      className='Modal__Bootstrap modal-dialog'
      style={{ content: { outline: 'none' } }}
      {...props}
    >
      <div className='modal-content'>
        <div className='modal-header bg-primary-ibis'>
          <h5 className='modal-title'>{title}</h5>
          <button className='close' type='button' onClick={() => toggle()} aria-label='Close'>
            <span aria-hidden='true'>&times;</span>
          </button>
        </div>
        <div className='modal-body'>{children}</div>
      </div>
    </Modal>
  );
};

export default IbisModal;
