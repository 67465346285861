import { useState, useEffect } from 'react';
import { dioptreRange } from '../../helper/prescription-helpers';
import PlusOrMinus from './PlusOrMinus';

const DioptreSelect = ({ eye, category, externalState, setValue, readOnly, setActiveCategory }) => {
  const [internalState, setInternalState] = useState({ pwr: '', int: '', dec: '' });
  const [dataRange, setDataRange] = useState([]);
  const keyPrefix = `${eye}-${category}`;

  useEffect(() => {
    switch (category) {
      case 'sphere':
        return setDataRange(dioptreRange);
      case 'cylinder':
        return setDataRange(dioptreRange.filter((val) => val < 5));
      case 'add':
        return setDataRange(dioptreRange.filter((val) => val < 4));
      default:
        break;
    }
  }, [category]);

  useEffect(() => {
    const parts = externalState.split('.');
    setInternalState({
      pwr: parts[0].includes('+') || parts[0].includes('-') ? parts[0].charAt(0) : '',
      int: externalState.substring(externalState.length - 5, externalState.length - 3),
      dec: parts[1],
    });
  }, [externalState]);

  const updateReducer = (newData) => {
    const { pwr, int, dec } = { ...internalState, ...newData };
    setValue({ eye, [category]: pwr + int + '.' + dec });
  };

  return (
    <div
      className='d-flex align-items-center justify-content-center h-100 px-3'
      onClick={category !== 'add' ? () => setActiveCategory(category) : null}
    >
      {category === 'add' ? '+' : readOnly ? internalState.pwr : <PlusOrMinus pwr={internalState.pwr} update={updateReducer} keyPrefix={keyPrefix} />}
      <select
        value={internalState.int}
        onChange={(e) => updateReducer({ int: e.target.value })}
        style={{ appearance: 'none', border: 0, textAlign: 'right' }}
        tabIndex={readOnly ? -1 : 0}
      >
        {dataRange.map((val) => {
          return (
            <option key={`${keyPrefix}-${val}`} value={val}>
              {val}
            </option>
          );
        })}
      </select>
      <p className='my-0 font-weight-bold'>{`.`}</p>
      <select
        value={internalState.dec}
        onChange={(e) => updateReducer({ dec: e.target.value })}
        style={{ appearance: 'none', border: 0 }}
        tabIndex={readOnly ? -1 : 0}
      >
        <option value='00'>{`00`}</option>
        <option value='25'>{`25`}</option>
        <option value='50'>{`50`}</option>
        <option value='75'>{`75`}</option>
      </select>
    </div>
  );
};

export default DioptreSelect;
