class IbisUser {
  constructor(apiEndPoint) {
    this.apiEndPoint = apiEndPoint;
  }

  getApiEndpoint(detail) {
    if (detail) {
      return `${this.getApiEndpoint()}${detail}/`;
    }

    return this.apiEndPoint;
  }
}

class IbisPatient extends IbisUser {
  constructor() {
    super('/api/patients/');
  }
}

class IbisOptician extends IbisUser {
  constructor() {
    super('/api/opticians/');
  }
}

class IbisAdministrator extends IbisUser {
  constructor() {
    super('/api/administrators/');
  }
}

const dateFormatMap = {
  '%d/%m/%Y': 'dd/MM/yyyy',
  '%m/%d/%Y': 'MM/dd/yyyy',
  '%Y/%m/%d': 'yyyy/MM/dd',
  '%Y/%d/%m': 'yyyy/dd/MM',
};

export { IbisPatient, IbisOptician, IbisAdministrator, dateFormatMap };
