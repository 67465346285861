const CheckboxQuestionForm = ({ options, setOptions }) => {
  const handleInput = (targetPosition, targetText) => {
    if (targetText.length > 100) return null;
    setOptions((prevOptions) => {
      return prevOptions.map((option) => {
        if (option.position === targetPosition) return { ...option, text: targetText };
        return option;
      });
    });
  };

  const addNewOption = () => {
    setOptions((prev) => [...prev, { position: prev.length + 1, text: '' }]);
  };

  const removeOption = (optionToRemove) => {
    const filteredOptions = options.filter((option) => option.position !== optionToRemove.position);
    const newOptions = filteredOptions.map((option, idx) => ({
      ...option,
      position: idx + 1,
    }));
    return setOptions(newOptions);
  };

  return (
    <div className='d-flex flex-column align-items-center border rounded p-3 w-100'>
      {options.map((option) => {
        return (
          <div key={`option-wrapper-${option.position}`} className='d-flex flex-column w-100 mb-3'>
            <div className='d-flex align-items-center justify-content-around'>
              <label className='mb-0' htmlFor={`field_option_${option.position}`}>{`Option ${option.position}`}</label>
              <div className='input-group w-75'>
                <input
                  type='text'
                  className='form-control'
                  id={`field_option_${option.position}`}
                  value={option.text}
                  onChange={(e) => handleInput(option.position, e.target.value)}
                  required
                />
                {option.position > 2 && (
                  <div className='input-group-append'>
                    <button type='button' className='btn btn-danger' onClick={() => removeOption(option)}>
                      <i className='fas fa-trash' />
                    </button>
                  </div>
                )}
              </div>
            </div>
            <small className='text-muted text-right mt-1 mb-0 mr-3'>{`${option.text.length}/100`}</small>
          </div>
        );
      })}
      <button type='button' className='btn btn-md btn-ibis my-3 w-75' onClick={() => addNewOption()} disabled={options.length === 10}>
        {options.length < 10 ? `\u002b Add Another Option` : 'Maximum Option Number Reached'}
      </button>
    </div>
  );
};

export default CheckboxQuestionForm;
