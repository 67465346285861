import Modal from 'react-modal';
import { useState } from 'react';
import { patchData } from '../../api/apiFunctions';
import { PatientCopy, ClinicianCopy } from '../../helper/consent-helpers';

const ConsentModal = ({ closeModal, ...props }) => {
  const [privacyBoxChecked, setPrivacyBoxChecked] = useState(false);
  const [eulaBoxChecked, setEulaBoxChecked] = useState(false);
  const loggedUser = JSON.parse(localStorage.getItem('loggedUser'));

  const handleConfirm = async (e) => {
    e.preventDefault();
    let endpoint;

    switch (loggedUser.role) {
      case 'patient':
        endpoint = 'patients';
        break;
      case 'optician':
        endpoint = 'opticians';
        break;
      case 'admin':
        endpoint = 'administrators';
        break;
      default:
        break;
    }

    const response = await patchData(`/api/${endpoint}/${loggedUser.id}/`, { profile: { consent: true } });

    if (response.id) {
      localStorage.removeItem('ticket');
      localStorage.setItem('loggedUser', JSON.stringify({ ...loggedUser, ...{ profile: { ...loggedUser.profile, consent: true } } }));
      return window.location.reload();
    }
  };

  const handleCancel = (e) => {
    e.preventDefault();
    return logout();
  };

  return (
    <Modal
      isOpen={true}
      appElement={document.getElementById('wrapper')}
      closeTimeoutMS={300}
      className='Modal__Bootstrap modal-dialog modal-xl'
      style={{ overlay: { backdropFilter: 'blur(0.75rem)' }, content: { outline: 'none' } }}
      {...props}
    >
      <div className='modal-content'>
        <div className='modal-header bg-primary-ibis d-flex justify-content-center'>
          <h5 className='modal-title font-weight-bold'>Your Consent</h5>
        </div>
        <div className='modal-body d-flex flex-column align-items-center p-5 my-5 text-center'>
          {loggedUser.role === 'patient' ? <PatientCopy /> : <ClinicianCopy />}
          <p className='lead'>
            <a
              href={'https://live-api.ibisvision.co.uk/static/media/ibis-connect-eula-12.pdf'}
              className='text-ibis'
              target='_blank'
              rel='noreferrer'
            >
              {`Click Here for End User Licence Agreement (EULA)`}
            </a>
          </p>
          <p className='lead mb-4'>
            <a href={'https://live-api.ibisvision.co.uk/static/media/privacy_policy.pdf'} className='text-ibis' target='_blank' rel='noreferrer'>
              Click Here for Privacy Policy
            </a>
          </p>
          <form className='d-flex flex-column align-items-center'>
            <div className='form-group form-check'>
              <input
                type='checkbox'
                className='form-check-input'
                id='privacy-consent'
                checked={privacyBoxChecked}
                onChange={() => setPrivacyBoxChecked(!privacyBoxChecked)}
                style={{ cursor: 'pointer' }}
              />
              <label className='form-check-label' htmlFor='privacy-consent'>
                {`I confirm that I have read the Privacy Policy and I consent${
                  loggedUser.role === 'patient' ? ' to IbisVision carrying out my eye test.' : '.'
                }`}
              </label>
            </div>
            <div className='form-group form-check'>
              <input
                type='checkbox'
                className='form-check-input'
                id='eula-consent'
                checked={eulaBoxChecked}
                onChange={() => setEulaBoxChecked(!eulaBoxChecked)}
                style={{ cursor: 'pointer' }}
              />
              <label className='form-check-label' htmlFor='eula-consent'>
                {`I confirm that I have read the EULA and I agree to these terms.`}
              </label>
            </div>
            <div className='d-flex w-100 justify-content-center mt-5'>
              <button
                type='submit'
                className='btn btn-ibis w-25 mr-5'
                style={{ minWidth: '100px' }}
                onClick={(e) => handleConfirm(e)}
                disabled={!privacyBoxChecked || !eulaBoxChecked}
              >
                Confirm
              </button>
              <button className='btn btn-danger w-25' style={{ minWidth: '100px' }} onClick={(e) => handleCancel(e)}>
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};
const logout = () => {
  const { role } = JSON.parse(localStorage.getItem('loggedUser'));
  localStorage.removeItem('__endpoint__');
  localStorage.removeItem('token');
  localStorage.removeItem('loggedUser');
  localStorage.removeItem('__ibis_token__');
  window.Intercom('shutdown');
  localStorage.setItem('role', role);
  window.location.href = process.env.PUBLIC_URL + '/refused-consent';
};

export default ConsentModal;
