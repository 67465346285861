const QuestionAnswer = ({ question, activeResponse, setActiveResponse }) => {
  return (
    <div className='card-body' style={{ minHeight: '150px' }}>
      {question.question_type === 'boolean' && <BooleanAnswer activeResponse={activeResponse} setActiveResponse={setActiveResponse} />}
      {question.question_type === 'checkbox' && (
        <CheckboxAnswer options={question.options} activeResponse={activeResponse} setActiveResponse={setActiveResponse} />
      )}
      {question.question_type === 'text' && (
        <textarea
          className='form-control'
          placeholder='Your answer'
          name='questionnaire-question-text-response'
          id='questionnaire_question_text_response'
          style={{ height: '100px' }}
          value={activeResponse}
          onChange={(e) => {
            setActiveResponse(e.target.value);
          }}
        />
      )}
    </div>
  );
};

const BooleanAnswer = ({ activeResponse, setActiveResponse }) => {
  return (
    <div className='d-flex flex-column justify-content-center' style={{ height: '100px' }}>
      {[true, false].map((answer) => {
        return (
          <div className='form-check' key={`radio-btn-${answer}`}>
            <input
              className='form-check-input'
              type='radio'
              name='questionnaire-question-boolean-response'
              id={`questionnaire_question_boolean_response_${answer}`}
              checked={activeResponse === answer}
              onChange={() => {
                setActiveResponse(answer);
              }}
            />
            <label className='form-check-label' htmlFor={`questionnaire_question_boolean_response_${answer}`}>
              {answer ? 'Yes' : 'No'}
            </label>
          </div>
        );
      })}
    </div>
  );
};

const CheckboxAnswer = ({ options, activeResponse, setActiveResponse }) => {
  const handleCheckboxactiveResponse = (selectedOption) => {
    if (activeResponse?.includes(selectedOption)) {
      return setActiveResponse(activeResponse.filter((existingOption) => existingOption !== selectedOption));
    } else setActiveResponse((prev) => [...prev, selectedOption]);
  };

  return (
    <div className='d-flex flex-column justify-content-center'>
      {options?.map((option) => {
        return (
          <div className='custom-control custom-checkbox' key={`checkbox-${option.position}`}>
            <input
              className='custom-control-input'
              type='checkbox'
              id={`questionnaire_question_checkbox_activeResponse_${option.position}`}
              checked={activeResponse?.includes(option.position)}
              onChange={() => handleCheckboxactiveResponse(option.position)}
            />
            <label className='custom-control-label' htmlFor={`questionnaire_question_checkbox_activeResponse_${option.position}`}>
              {option.text}
            </label>
          </div>
        );
      })}
    </div>
  );
};

export default QuestionAnswer;
