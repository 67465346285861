export const formattedRefractionScore = (score, decimalPlaces) => {
    if (score === 0) {
        if (decimalPlaces === 2) {
            return '0.00';
        }
        if (decimalPlaces === 0) {
            return '0';
        }
    }

    if (score === undefined || score === null || score === false) {
        return 'N/A';
    }

    return score.toFixed(decimalPlaces);
}