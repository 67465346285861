import { useEffect, useCallback } from 'react';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import { useLocation, useHistory } from 'react-router-dom';

const Topbar = () => {
  const loggedUser = JSON.parse(localStorage.getItem('loggedUser'));
  const history = useHistory();

  const { pathname } = useLocation();

  const getOrigin = useCallback(() => {
    try {
      return pathname
        .split('/')
        .filter(function (element) {
          return element !== '';
        })
        .at(-1);
    } catch {
      const origin = pathname.split('/').filter(function (element) {
        return element !== '';
      });
      return origin[origin.length - 1];
    }
  }, [pathname]);

  const logout = () => {
    localStorage.removeItem('__endpoint__');
    localStorage.removeItem('token');
    localStorage.removeItem('loggedUser');
    localStorage.removeItem('__ibis_token__');
    window.location.href = process.env.PUBLIC_URL + '/login';
    window.Intercom('shutdown');
  };

  const closeErrorMessage = () => {
    document.getElementById('general_error').classList.remove('show');
  };

  useEffect(() => {
    if (loggedUser?.role !== 'patient' && getOrigin() !== 'ros' && getOrigin() !== 'partner') {
      window.Intercom('boot', {
        app_id: 'qljpl22p',
        name: loggedUser?.full_name,
        email: loggedUser?.email,
      });
    } else {
      window.Intercom('shutdown');
    }

    if (process.env.REACT_APP_LOGROCKET_TOKEN) {
      LogRocket.init(`${process.env.REACT_APP_LOGROCKET_TOKEN}`);
      setupLogRocketReact(LogRocket);
      LogRocket.identify(`${process.env.REACT_APP_LOGROCKET_TOKEN}`, {
        name: loggedUser?.full_name,
        email: loggedUser?.email,
        role: loggedUser?.role,
      });
    }
  }, [loggedUser, getOrigin]);

  return (
    <>
      {loggedUser && getOrigin() !== 'ros' && getOrigin() !== 'partner' ? (
        <div>
          <nav className='navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow'>
            {loggedUser?.company ? <span className='text-ibis'>{loggedUser?.company}</span> : ''}
            {loggedUser?.superuser ? (
              <span className='text-ibis'>
                <strong>&nbsp;(Master User)</strong>
              </span>
            ) : (
              ''
            )}
            <button id='sidebarToggleTop' className='btn btn-link d-md-none rounded-circle mr-3'>
              <i className='fa fa-bars'></i>
            </button>
            <ul className='navbar-nav ml-auto'>
              <li className='nav-item dropdown no-arrow'>
                <button
                  style={{ border: 0, backgroundColor: 'transparent' }}
                  className='nav-link dropdown-toggle'
                  id='userDropdown'
                  data-toggle='dropdown'
                  aria-haspopup='true'
                  aria-expanded='false'
                >
                  <span className='mr-2 d-none d-lg-inline text-gray-600 small'>{loggedUser?.full_name}</span>
                  <img className='img-profile rounded-circle' srcSet={`${process.env.PUBLIC_URL}/img/undraw_profile.svg`} alt='user avatar' />
                </button>
                <div className='dropdown-menu dropdown-menu-right shadow animated--grow-in' aria-labelledby='userDropdown'>
                  {loggedUser.superuser && (
                    <button className='dropdown-item' onClick={() => history.push('/theme')}>
                      <i className='fas fa-palette fa-sm fa-fw mr-2 text-gray-400' />
                      {`Theme`}
                    </button>
                  )}
                  <button className='dropdown-item' onClick={logout}>
                    <i className='fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400'></i>
                    Logout
                  </button>
                </div>
              </li>
            </ul>
          </nav>
          <div className='alert alert-danger fade m-4' role='alert' id='general_error'>
            <span id='general_error_message'></span>
            <button type='button' className='close' id='general_error_close' aria-label='Close' onClick={closeErrorMessage}>
              <span aria-hidden='true'>&times;</span>
            </button>
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default Topbar;
