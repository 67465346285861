import { createContext, useContext } from 'react';

import { useApi } from '../context/apiContext';

const CrudContext = createContext();

export const useCrud = () => {
  return useContext(CrudContext);
};

export const CrudProvider = ({ children }) => {
  const { customRequest, getAuthorisedHeader, postData, patchData } = useApi();

  const fetchObjects = async (path) => {
    const res = await customRequest(path, {
      headers: getAuthorisedHeader(),
    });

    const data = await res.json();

    return data;
  };

  const addObject = async (path, objectData, unauthorised) => {
    const responseObject = await postData(path, objectData, unauthorised);

    if (responseObject.id) {
      return responseObject;
    }

    for (const fieldname in responseObject) {
      const fieldObject = document.getElementById('field_' + fieldname);
      const fieldMessageObject = document.getElementById('invalid_' + fieldname + '_message');
      if (fieldname === 'start' || fieldname === 'expected_end') {
        let fieldDate = document.getElementById('field_' + fieldname + '_date');
        let fieldTime = document.getElementById('field_' + fieldname + '_time');
        let fieldDateMessageObject = document.getElementById('invalid_' + fieldname + '_date_message');
        let fieldTimeMessageObject = document.getElementById('invalid_' + fieldname + '_time_message');
        fieldDate.classList.add('is-invalid');
        fieldTime.classList.add('is-invalid');
        fieldDateMessageObject.innerHTML = responseObject[fieldname];
        fieldTimeMessageObject.innerHTML = responseObject[fieldname];
      }

      if (fieldMessageObject) {
        fieldMessageObject.innerHTML = responseObject[fieldname];
        fieldObject.classList.add('is-invalid');
      }

      if (fieldname === 'detail' && document.getElementById('registration-error-message')) {
        document.getElementById('registration-error-message').innerHTML = responseObject['detail'];
      }
    }

    return false;
  };

  const saveObject = async (path, objectData) => {
    Array.from(document.querySelectorAll('.is-invalid')).forEach((el) => el.classList.remove('is-invalid'));

    const responseObject = await patchData(path, objectData);

    if (responseObject.id) {
      return responseObject;
    }

    for (const fieldname in responseObject) {
      const fieldObject = document.getElementById('edit_field_' + fieldname);
      if (fieldObject) {
        fieldObject.classList.add('is-invalid');
      }
      const fieldErrorContainer = document.getElementById('edit_invalid_' + fieldname + '_message');
      if (fieldErrorContainer) {
        document.getElementById('edit_invalid_' + fieldname + '_message').innerHTML = responseObject[fieldname];
      }
      if (fieldname === 'start' || fieldname === 'expected_end') {
        let fieldDate = document.getElementById('edit_field_' + fieldname + '_date');
        let fieldTime = document.getElementById('edit_field_' + fieldname + '_time');
        let fieldDateMessageObject = document.getElementById('edit_invalid_' + fieldname + '_date_message');
        let fieldTimeMessageObject = document.getElementById('edit_invalid_' + fieldname + '_time_message');
        fieldDate.classList.add('is-invalid');
        fieldTime.classList.add('is-invalid');
        fieldDateMessageObject.innerHTML = responseObject[fieldname];
        fieldTimeMessageObject.innerHTML = responseObject[fieldname];
      }
    }

    return false;
  };

  const detailObject = async (path, method) => {
    let response = await customRequest(path, {
      method: method,
      headers: getAuthorisedHeader(),
    });

    if (response.status === 400) {
      let errorResponse = await response.json();
      if (errorResponse['error']) {
        document.getElementById('general_error').classList.add('show');
        document.getElementById('general_error_message').innerHTML = errorResponse['error'];
        document.getElementById('modal_general_error').classList.remove('d-none');
        document.getElementById('modal_general_error_message').innerHTML = errorResponse['error'];
      }
    }

    return await response;
  };

  const populateFieldsValues = (fieldObject) => {
    for (const fieldname in fieldObject) {
      if (typeof fieldObject[fieldname] === 'object') {
        populateFieldsValues(fieldObject[fieldname]);
      }
      const field_element = document.getElementById('edit_field_' + fieldname);
      if (field_element) {
        if (field_element.type === 'checkbox') {
          field_element.checked = fieldObject[fieldname];
        } else field_element.value = fieldObject[fieldname];
      }
    }
  };

  return <CrudContext.Provider value={{ addObject, saveObject, detailObject, fetchObjects, populateFieldsValues }}>{children}</CrudContext.Provider>;
};
