import { useCompany } from '../../context/companyContext';

const RecheckSettings = () => {
  const { currentCompany } = useCompany();

  return (
    <div className='container-fluid'>
      <div className='card shadow mb-5 w-auto'>
        <div className='card-header bg-primary-ibis py-3'>
          <h5 className='font-weight-bold'>Recheck Settings</h5>
        </div>
        <div className='card-body d-flex flex-column align-items-center'>
          {currentCompany?.recheck_data?.link ? (
            <table className='table table-bordered'>
              <tbody>
                <tr>
                  <td>Link</td>
                  <td style={{ userSelect: 'text' }}>{currentCompany.recheck_data.link}</td>
                </tr>
                <tr>
                  <td colSpan='2'>
                    <a href={currentCompany.recheck_data.link} className='btn btn-ibis'>
                      Go to Recheck
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          ) : (
            'No recheck settings found'
          )}
        </div>
      </div>
    </div>
  );
};

export default RecheckSettings;
