import QuestionnaireQuestionContent from './QuestionnaireQuestionContent';
import AllQuestionContent from './AllQuestionContent';
import { useParams } from 'react-router-dom';

const QuestionContent = ({ addQuestionPath, deleteQuestionPath, setInitialQuestionnaireQuestion }) => {
  const { questionnaireId } = useParams();

  return questionnaireId ? (
    <QuestionnaireQuestionContent
      addQuestionPath={addQuestionPath}
      deleteQuestionPath={deleteQuestionPath}
      setInitialQuestionnaireQuestion={setInitialQuestionnaireQuestion}
    />
  ) : (
    <AllQuestionContent />
  );
};

export default QuestionContent;
