import { createContext, useCallback, useContext, useState } from 'react';
import { getAllSessions } from '../api/recheckFunctions';
import useFilter from '../hooks/useFilter';
import usePagination from '../hooks/usePagination';
import useSort from '../hooks/useSort';

const RecheckContext = createContext();

export const useRecheck = () => {
  return useContext(RecheckContext);
};

export const RecheckProvider = ({ children }) => {
  const [sessions, setSessions] = useState([]);
  const { currentPage, itemsPerPage, handlePageChange, handleRowsPerPageChange, resetPagination } = usePagination(1, 10);
  const { filter, handleFilterChange, dateTimeFilter, handleDateTimeFilterChange, resetFilters } = useFilter();
  const { sortField, sortDirection, handleSort, resetSort } = useSort('created', 'desc');
  const [filtersObject, setFiltersObject] = useState({});


  const resetAllFilters = () => {
    resetPagination();
    resetFilters();
    resetSort();
  };

  const fetchSessions = useCallback(async () => {
    const res = await getAllSessions(filtersObject);
    setSessions(res);
  }, [filtersObject]);

  return (
    <RecheckContext.Provider
      value={{
        sessions,
        setSessions,
        currentPage,
        itemsPerPage,
        handlePageChange,
        handleRowsPerPageChange,
        resetPagination,
        filter,
        handleFilterChange,
        dateTimeFilter,
        handleDateTimeFilterChange,
        resetFilters,
        sortField,
        sortDirection,
        handleSort,
        resetSort,
        resetAllFilters,
        filtersObject,
        setFiltersObject,
        fetchSessions
      }}
    >
      {children}
    </RecheckContext.Provider>
  );
};
