import { useState } from 'react';

const CompanyTestSelect = ({ availableTests, selectedTests, setAvailableTests, setSelectedTests }) => {
  const [highlightedTests, setHighlightedTests] = useState([]);
  const [currentTestList, setCurrentTestList] = useState(null);

  const toggleTestHighlight = (test, testList) => {
    if (currentTestList !== testList) {
      setCurrentTestList(testList);
      setHighlightedTests([]);
      document.querySelectorAll('.highlighted').forEach((highlightedListItem) => {
        return highlightedListItem.classList.remove('highlighted');
      });
    }

    if (highlightedTests.includes(test)) {
      setHighlightedTests(highlightedTests.filter((highlightedTest) => highlightedTest.id !== test.id));
    } else {
      setHighlightedTests((prevState) => [...prevState, test]);
    }

    document.getElementById(test.id).classList.toggle('highlighted');
  };

  const addTestsToSelected = () => {
    setSelectedTests([...selectedTests, ...highlightedTests]);
    setAvailableTests(availableTests.filter((test) => !highlightedTests.includes(test)));
    setHighlightedTests([]);
  };

  const removeTestsFromSelected = () => {
    setAvailableTests([...availableTests, ...highlightedTests]);
    setSelectedTests(selectedTests.filter((test) => !highlightedTests.includes(test)));
    setHighlightedTests([]);
  };

  const addAllToSelected = () => {
    setSelectedTests([...selectedTests, ...availableTests]);
    setAvailableTests([]);
    setHighlightedTests([]);
  };

  const removeAllFromSelected = () => {
    setAvailableTests([...selectedTests, ...availableTests]);
    setSelectedTests([]);
    setHighlightedTests([]);
  };

  return (
    <div className='d-flex justify-content-center align-items-center' style={{ maxWidth: '950px', width: '65vw' }}>
      <div className='col-sm-4'>
        <div className='card shadow mb-4' style={{ height: '18rem' }}>
          <div className='card-header bg-primary-ibis py-3'>
            <h6 className='m-0 font-weight-bold text-white'>Available Tests</h6>
          </div>
          <div className='card-body' style={{ padding: `${availableTests.length ? 0 : '10px'}`, overflow: 'auto' }}>
            <ul style={{ listStyle: 'none', margin: 0, padding: 0 }} tabIndex='1'>
              {!availableTests.length
                ? 'No tests available.'
                : (availableTests.sort((a, b) => (a.id > b.id ? 1 : b.id > a.id ? -1 : 0)),
                  availableTests.map((test) => {
                    return (
                      <li
                        key={`available-test-${test.id}`}
                        id={test.id}
                        className='test-list-item'
                        onClick={() => toggleTestHighlight(test, 'available')}
                      >
                        {test.title}
                      </li>
                    );
                  }))}
            </ul>
          </div>
        </div>
      </div>
      <div className='d-flex flex-column align-items-center col-sm-2'>
        <button
          id='select-btn'
          className='btn-ibis company-test-btn mb-2'
          disabled={currentTestList === 'selected' || !highlightedTests.length}
          onClick={() => addTestsToSelected()}
        >
          {'>'}
        </button>
        <button
          id='remove-btn'
          className='btn-ibis company-test-btn mb-2'
          disabled={currentTestList === 'available' || !highlightedTests.length}
          onClick={() => removeTestsFromSelected()}
        >
          {'<'}
        </button>
        <button id='select-all-btn' className='btn-ibis company-test-btn mb-2' disabled={!availableTests.length} onClick={() => addAllToSelected()}>
          {'>>'}
        </button>
        <button
          id='remove-all-btn'
          className='btn-ibis company-test-btn mb-2'
          disabled={!selectedTests.length}
          onClick={() => removeAllFromSelected()}
        >
          {'<<'}
        </button>
      </div>
      <div className='col-sm-4'>
        <div className='card shadow mb-4' style={{ height: '18rem' }}>
          <div className='card-header bg-primary-ibis py-3'>
            <h6 className='m-0 font-weight-bold text-white'>Selected Tests</h6>
          </div>
          <div className='card-body' style={{ padding: `${selectedTests.length ? 0 : '10px'}`, overflow: 'auto' }}>
            <ul style={{ listStyle: 'none', margin: 0, padding: 0 }}>
              {!selectedTests.length
                ? 'No tests selected.'
                : (selectedTests.sort((a, b) => (a.id > b.id ? 1 : b.id > a.id ? -1 : 0)),
                  selectedTests.map((test) => {
                    return (
                      <li
                        key={`selected-test-${test.id}`}
                        id={test.id}
                        className='test-list-item'
                        onClick={() => toggleTestHighlight(test, 'selected')}
                      >
                        {test.title}
                      </li>
                    );
                  }))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CompanyTestSelect;
