import { Route, Switch } from 'react-router-dom';
import { useCompany } from '../context/companyContext';
import Question from '../components/Questionnaire/Question';
import Questionnaire from '../components/Questionnaire/Questionnaire';
import PageNotFound from '../pages/PageNotFound';

const QuestionnaireRoutes = () => {
  const { currentCompany } = useCompany();
  return !currentCompany?.questionnaires_enabled ? null : (
    <Switch>
      <Route path={`${process.env.PUBLIC_URL}/questionnaire`}>
        <Questionnaire />
      </Route>
      <Route path={`${process.env.PUBLIC_URL}/questions/:questionnaireId`} exact>
        <Question />
      </Route>
      <Route path={`${process.env.PUBLIC_URL}/questions`} exact>
        <Question />
      </Route>
      <Route path='*'>
        <PageNotFound />
      </Route>
    </Switch>
  );
};

export default QuestionnaireRoutes;
