import React from 'react';
import VisualFieldTestResult from '../TestResults/VisualFieldTestResult';
import { LineChart, Line, Legend, Tooltip, CartesianGrid, XAxis, YAxis } from 'recharts';
import { formattedRefractionScore } from '../../helper/refraction';
import PrintVisualField from '../TestResultsForPrinting/VisualField/PrintVisualField';
import PrintStrokeSuite from '../TestResultsForPrinting/StrokeSuite/PrintStrokeSuite';

export const AppointmentResultsContent = React.forwardRef((props, ref) => {
  const getLogmarResultsTable = (testResult) => {
    const resultData = JSON.parse(testResult.result_data);
    return (
      <table className='w-100 bg-white table table-bordered'>
        <thead>
          <tr>
            <th colSpan='3'>{testResult.test_title}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>Right Eye</th>
            <th>Left Eye</th>
            <th>Binocular</th>
          </tr>
          <tr>
            <td>Logmar: {resultData?.rightEye ? resultData.rightEye : 'N/A'}</td>
            <td>Logmar: {resultData?.leftEye ? resultData.leftEye : 'N/A'}</td>
            <td>Logmar: {resultData?.bothEyes ? resultData.bothEyes : 'N/A'}</td>
          </tr>
          <tr>
            <td>Snellen: {resultData?.rightEyeSnellen ? resultData.rightEyeSnellen : 'N/A'}</td>
            <td>Snellen: {resultData?.leftEyeSnellen ? resultData.leftEyeSnellen : 'N/A'}</td>
            <td>Snellen: {resultData?.bothEyesSnellen ? resultData.bothEyesSnellen : 'N/A'}</td>
          </tr>
        </tbody>
      </table>
    );
  };

  const getRefractionResultsTable = (testResult) => {
    const resultData = JSON.parse(testResult.result_data);
    return (
      <table className='w-100 bg-white table table-bordered'>
        <thead>
          <tr>
            <th colSpan='12'>{testResult.test_title}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>Calculated Distance</th>
            <th>Right Eye</th>
            <th>Left Eye</th>
            <th>Binocular</th>
          </tr>
          <tr>
            <td>Sphere</td>
            <td>{resultData.rightEye?.score?.sphere !== undefined ? formattedRefractionScore(resultData.rightEye.score.sphere, 2) : 'N/A'}</td>
            <td>{resultData.leftEye?.score?.sphere !== undefined ? formattedRefractionScore(resultData.leftEye.score.sphere, 2) : 'N/A'}</td>
            <td>{resultData.bothEyes?.score?.sphere !== undefined ? formattedRefractionScore(resultData.bothEyes.sphere, 2) : 'N/A'}</td>
          </tr>
          <tr>
            <td>Cylinder</td>
            <td>{resultData.rightEye?.score?.cylinder !== undefined ? formattedRefractionScore(resultData.rightEye.score.cylinder, 2) : 'N/A'}</td>
            <td>{resultData.leftEye?.score?.cylinder !== undefined ? formattedRefractionScore(resultData.leftEye.score.cylinder, 2) : 'N/A'}</td>
            <td>{resultData.bothEyes?.score?.cylinder !== undefined ? formattedRefractionScore(resultData.bothEyes.cylinder, 2) : 'N/A'}</td>
          </tr>
          <tr>
            <td>Axis</td>
            <td>{resultData.rightEye?.score?.axis !== undefined ? formattedRefractionScore(resultData.rightEye.score.axis) : 'N/A'}</td>
            <td>{resultData.leftEye?.score?.axis !== undefined ? formattedRefractionScore(resultData.leftEye.score.axis) : 'N/A'}</td>
            <td>{resultData.bothEyes?.score?.axis !== undefined ? formattedRefractionScore(resultData.bothEyes.axis) : 'N/A'}</td>
          </tr>
        </tbody>
      </table>
    );
  };

  const getGraphsData = (resultsData) => {
    let parsedResults = [];
    let filteredResults = [];
    resultsData?.forEach((result) => {
      const { logmarTested, leftEye, rightEye, bothEyes } = result;
      parsedResults.push({
        name: logmarTested,
        Left: parseFloat(leftEye?.toFixed(2)),
        Right: parseFloat(rightEye?.toFixed(2)),
        Binocular: parseFloat(bothEyes?.toFixed(2)),
      });
    });

    parsedResults.forEach((result) => {
      filteredResults.push(
        Object.entries(result).reduce((resultObj, [eyeTested, value]) => (value ? ((resultObj[eyeTested] = value), resultObj) : resultObj), {})
      );
    });

    return filteredResults;
  };

  const getContrastSensitivityResultsTable = (testResult) => {
    const resultData = JSON.parse(testResult.result_data);
    return (
      <table className='w-100 bg-white table table-bordered'>
        <thead>
          <tr>
            <th colSpan='4'>{testResult.test_title}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>Logmar</th>
            <th>Left Eye</th>
            <th>Right Eye</th>
            <th>Binocular</th>
          </tr>
          {Array.isArray(resultData) &&
            resultData?.map((result) => {
              const { logmarTested, leftEye, rightEye, bothEyes } = result;
              return (
                <tr key={`${logmarTested}`}>
                  <td>{logmarTested}</td>
                  <td>{leftEye?.toFixed(2) || '-'}</td>
                  <td>{rightEye?.toFixed(2) || '-'}</td>
                  <td>{bothEyes?.toFixed(2) || '-'}</td>
                </tr>
              );
            })}
          <tr>
            <th colSpan='4'>Graphs</th>
          </tr>
          {Array.isArray(resultData) ? (
            <tr>
              <td colSpan='4'>
                <LineChart width={700} height={350} data={getGraphsData(resultData)} margin={{ top: 5, right: 30, left: 10, bottom: 25 }}>
                  <CartesianGrid vertical={false} />
                  <XAxis dataKey='name' />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line type='monotone' dataKey='Left' stroke='#a6a6a6' strokeWidth={2} connectNulls animationDuration={1000} />
                  <Line type='monotone' dataKey='Right' stroke='#808080' strokeWidth={2} connectNulls />
                  <Line type='monotone' dataKey='Binocular' stroke='#000000' strokeWidth={2} connectNulls />
                </LineChart>
              </td>
            </tr>
          ) : (
            ''
          )}
        </tbody>
      </table>
    );
  };

  const getICNVisionResultsTable = (testResult) => {
    const resultData = JSON.parse(testResult.result_data);
    return (
      <table className='w-100 bg-white table table-bordered'>
        <thead>
          <tr>
            <th colSpan='3'>{testResult.test_title}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>Right Eye</th>
            <th>Left Eye</th>
            <th>Binocular</th>
          </tr>
          <tr>
            <td>{resultData?.rightEye?.textSizeRead ? 'Text size read: ' + resultData.rightEye.textSizeRead : ''}</td>
            <td>{resultData?.leftEye?.textSizeRead ? 'Text size read: ' + resultData.leftEye.textSizeRead : ''}</td>
            <td>{resultData?.bothEyes?.textSizeRead ? 'Text size read: ' + resultData.bothEyes?.textSizeRead : 'N/A'}</td>
          </tr>
          <tr>
            <td>{resultData?.rightEye?.magnification ? 'Suggested magnification: ' + resultData.rightEye.magnification : ''}</td>
            <td>{resultData?.leftEye?.magnification ? 'Suggested magnification: ' + resultData.leftEye.magnification : ''}</td>
            <td>{resultData?.bothEyes?.magnification ? 'Suggested magnification: ' + resultData.bothEyes?.magnification : 'N/A'}</td>
          </tr>
        </tbody>
      </table>
    );
  };

  const getVisualFieldResultsTable = (testResult) => {
    const resultData = JSON.parse(testResult.result_data);
    return (
      <table className='w-100 bg-white table table-bordered'>
        <thead>
          <tr>
            <th>{testResult.test_title}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <VisualFieldTestResult results={resultData} testInfo={testResult} print={true} />
            </td>
          </tr>
        </tbody>
      </table>
    );
  };

  const getTestResult = (testResult) => {
    const resultData = JSON.parse(testResult.result_data);
    switch (testResult.test_key) {
      case 'crowdedLogmar':
      case 'openLogmar':
      case 'randomE':
      case 'kaysMultiple':
        return getLogmarResultsTable(testResult);
      case 'Refraction':
      case 'RefractionTwo':
        return getRefractionResultsTable(testResult);
      case 'contrastSensitivity':
        return getContrastSensitivityResultsTable(testResult);
      case 'optimaChart':
        return getICNVisionResultsTable(testResult);
      case 'visualFieldTest':
        return getVisualFieldResultsTable(testResult);
      case 'visualFieldTwo':
        return <PrintVisualField results={resultData} />;
      case 'visualFieldForthValley':
        return <PrintStrokeSuite results={resultData?.visualFieldForthValley?.results} />;
      default:
        break;
    }
  };

  return (
    <div ref={ref}>
      <div className='row mt-5'>
        <div className='col'></div>
        <div className='col-8'>
          <table className='table table-bordered bg-white'>
            <thead>
              <tr>
                <th colSpan='4'>
                  <img src='/img/rebrand-logo.svg' width='50px' alt='ibisvision logo' className='rounded mx-auto d-block' />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th colSpan='4' className='text-center'>
                  IBIS-Engage Teleoptometry session results
                </th>
              </tr>
              <tr>
                <th colSpan='4'>Patient details:</th>
              </tr>
              <tr>
                <th>Surname</th>
                <td>{props?.appointment?.patient_full_name?.substring(props.appointment.patient_full_name.indexOf(' ', 0))}</td>
                <th>Date of birth</th>
                <td>{props.patient?.profile?.date_of_birth ? props.patient.profile.date_of_birth : ''}</td>
              </tr>
              <tr>
                <th>Forename(s)</th>
                <td>{props?.appointment?.patient_full_name?.substring(0, props.appointment.patient_full_name.indexOf(' ', 0))}</td>
                <th>Patient identifier</th>
                <td>{props?.patient?.profile?.patient_identifier ? props.patient.profile.patient_identifier : ''}</td>
              </tr>
              <tr>
                <th>Previous surname</th>
                <td colSpan='3'>{props?.patient?.profile?.previous_surname ? props.patient.profile.previous_surname : ''}</td>
              </tr>
              <tr>
                <th>Address</th>
                <td colSpan='3'>{props?.patient?.profile?.address ? props.patient.profile.address : ''}</td>
              </tr>
              <tr>
                <th>Postcode</th>
                <td colSpan='3'>{props?.patient?.profile?.postcode ? props.patient.profile.postcode : ''}</td>
              </tr>
              <tr>
                <td colSpan='4'>&nbsp;</td>
              </tr>
              <tr>
                <th colSpan='4'>Notes</th>
              </tr>
              <tr>
                <td colSpan='4'>{props?.appointment?.notes ? props.appointment.notes : ''}</td>
              </tr>
              <tr>
                <th colSpan='4'>Outcome</th>
              </tr>
              <tr>
                <td colSpan='4'>
                  Refer to GP &nbsp;
                  <input type='checkbox' />
                </td>
              </tr>
              <tr>
                <td colSpan='4'>
                  Refer to clinician &nbsp;
                  <input type='checkbox' />
                </td>
              </tr>
              <tr>
                <td colSpan='4'>
                  Refer to other &nbsp;
                  <input type='checkbox' />
                </td>
              </tr>
              <tr>
                <td colSpan='4'>
                  Invite for follow up &nbsp;
                  <input type='checkbox' />
                </td>
              </tr>
              <tr>
                <td colSpan='4'>
                  Invite for prescription &nbsp;
                  <input type='checkbox' />
                </td>
              </tr>
              <tr>
                <td colSpan='4'>
                  Schedule review &nbsp;
                  <input type='checkbox' />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className='col'></div>
      </div>
      {props.appointment !== null && props.appointment.tests_results?.length ? (
        props.appointment.tests_results.map((test_result) => {
          return (
            <div className='row' style={{ pageBreakInside: 'avoid' }} key={`test-result-${test_result.id}`}>
              <div className='col'></div>
              <div className='col-8'>{getTestResult(test_result)}</div>
              <div className='col'></div>
            </div>
          );
        })
      ) : (
        <h4 className='text-center'>No tests results</h4>
      )}
    </div>
  );
});
