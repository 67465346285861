export const customStyles = {
  table: {
    style: {
      marginTop: '6px',
      marginBottom: '6px',
    },
  },
  headRow: {
    style: {},
  },
  headCells: {
    style: {
      '& > div': {
        padding: '12px',
      },
      padding: 0,
      border: '1px solid #e3e6f0',
      fontSize: '16px',
      color: '#858796',
      fontWeight: 'bold',
    },
  },
  cells: {
    style: {
      '& > div': { padding: '12px' },
      padding: 0,
      border: '1px solid #e3e6f0',
      borderTop: 0,
      fontSize: '16px',
      color: '#858796',
    },
  },
  rows: {
    style: {
      '&:not(:last-of-type)': { border: 0 },
    },
  },
  pagination: {
    style: {
      borderTop: 0,
    },
  },
};
