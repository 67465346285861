import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

const WaitingRoomIshiharaResults = ({ apptDetails }) => {
  const [results, setResults] = useState({});
  const history = useHistory();

  useEffect(() => {
    const { tests_results } = apptDetails;
    const ishiharaTest = tests_results.find(({ test_key }) => test_key === 'ishihara');
    if (!ishiharaTest || !Object.keys(ishiharaTest).length) return;
    const resultsObj = JSON.parse(ishiharaTest?.result_data || '{}');
    setResults(resultsObj);
  }, [apptDetails]);

  return (
    <div className='w-100 d-flex mt-4 align-items-center' style={{ gap: '1rem' }}>
      <button
        className='btn btn-ibis btn-md w-100'
        onClick={() => history.push(`/pre-screening/${apptDetails.id}`)}
        disabled={!Object.keys(results).length}
      >
        {Object.keys(results).length ? 'View Patient Answers' : 'Not Completed'}
      </button>
    </div>
  );
};

export default WaitingRoomIshiharaResults;
