const PlusOrMinus = ({ pwr, update, keyPrefix }) => {
  return (
    <div className='d-flex align-items-center mr-2 h-75' style={{ cursor: 'pointer' }}>
      {['-', '+'].map((sign) => {
        const selected = pwr === sign;

        return (
          <div
            key={`${keyPrefix}-power${sign}`}
            className={`d-flex align-items-center justify-content-center rounded-${sign === '-' ? 'left' : 'right'}`}
            style={{
              borderRight: sign === '-' ? '1px solid' : null,
              borderLeft: sign === '+' ? '1px solid' : null,
              borderColor: pwr ? '#1cc88a' : '#e3e6f0',
              backgroundColor: selected ? '#1cc88a' : 'lightgrey',
              transform: `scale(${selected ? 1.1 : 1})`,
              zIndex: selected ? 1 : 0,
              boxShadow: selected ? 'rgba(0, 0, 0, 0.35) 0px 5px 15px' : null,
              width: '20px',
              height: '20px',
            }}
            onClick={() => update({ pwr: sign })}
          >
            <p className={`m-0 ${selected ? 'font-weight-bold' : ''}`} style={{ color: '#000' }}>
              {sign}
            </p>
          </div>
        );
      })}
    </div>
  );
};

export default PlusOrMinus;
