import { useState, useEffect } from 'react';

const PrismSelect = ({ eye, category, value, setValue, readOnly }) => {
  const allNumbers = Array.from({ length: 20 }, (_, i) => (i + 1) / 2);
  const [dioptre, setDioptre] = useState('');
  const [baseDirection, setBaseDirection] = useState('');

  useEffect(() => {
    if (dioptre?.length || baseDirection?.length) return;
    const existingValue = value;
    if (!existingValue?.length) return;
    const numberLetterRegex = /([^A-Za-z]*)([A-Za-z]*)/;
    const [, num, direction] = existingValue.match(numberLetterRegex);
    if (!num || !direction) return;
    setDioptre(num);
    setBaseDirection(direction);
  }, [value, baseDirection, dioptre]);

  useEffect(() => {
    setValue({ eye, [category]: dioptre + baseDirection });
  }, [eye, category, dioptre, baseDirection, setValue]);

  return (
    <div className='h-100' style={{ width: '100%' }}>
      {readOnly ? (
        <div className='d-flex justify-content-center align-items-center h-100 text-dark' tabIndex={-1}>{`${dioptre + baseDirection}`}</div>
      ) : (
        <>
          <select
            value={dioptre}
            onChange={(e) => setDioptre(e.target.value)}
            style={{
              appearance: 'none',
              opacity: dioptre === '' ? '0' : '1',
              border: 0,
              textAlign: 'center',
              width: !dioptre.length ? '100%' : '50%',
              height: '100%',
            }}
          >
            <option value=''>None</option>
            {allNumbers.map((num) => {
              return (
                <option key={`${eye}-prism-${num}`} value={num}>
                  {num}
                </option>
              );
            })}
          </select>
          {dioptre !== '' && (
            <select
              value={baseDirection?.length ? baseDirection : category.includes('vertical') ? 'BU' : 'BI'}
              onChange={(e) => setBaseDirection(e.target.value)}
              style={{ border: 0, fontWeight: 'bold', width: '50%' }}
            >
              {category.includes('vertical') && (
                <>
                  <option value='BU'>{`BU`}</option>
                  <option value='BD'>{`BD`}</option>
                </>
              )}
              {category.includes('horizontal') && (
                <>
                  <option value='BI'>{`BI`}</option>
                  <option value='BO'>{`BO`}</option>
                </>
              )}
            </select>
          )}
        </>
      )}
    </div>
  );
};

export default PrismSelect;
