import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getAppointment } from '../api/appointmentFunctions';
import Questionnaire from '../components/PreScreening/Questionnaire';
import Ishihara from '../components/PreScreening/Ishihara';
import Prescription from '../components/PreScreening/Prescription';
import LoadingSpinner from '../components/Misc/LoadingSpinner';

const PreScreening = () => {
  const { apptId } = useParams();
  const [questionnaireId, setQuestionnaireId] = useState(0);
  const [prescriptionId, setPrescriptionId] = useState(0);
  const [prescriptionData, setPrescriptionData] = useState({});
  const [preScreeningTests, setPreScreeningTests] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);

  useEffect(() => {
    getAppointment(apptId).then((response) => {
      const {
        questionnaire_id,
        prescreening: { tests, prescription },
      } = response;
      setPrescriptionData(JSON.parse(prescription?.prescription_data || false));
      setPrescriptionId(prescription?.id);
      setPreScreeningTests(tests);
      setQuestionnaireId(questionnaire_id);
      return setDataFetched(true);
    });
  }, [apptId]);

  return !dataFetched ? (
    <LoadingSpinner message={'Loading pre-screening...'} />
  ) : (
    <div className='container-fluid'>
      <div className='d-flex flex-column'>
        {questionnaireId ? <Questionnaire apptId={apptId} questionnaireId={questionnaireId} /> : null}
        {preScreeningTests?.length ? <Ishihara apptId={apptId} /> : null}
        {Object.keys(prescriptionData)?.length ? <Prescription prescriptionFromServer={prescriptionData} id={prescriptionId} /> : null}
      </div>
    </div>
  );
};

export default PreScreening;
