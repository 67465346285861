import EditCompanyForm from '../components/Company/EditCompanyForm';
import { useParams, useLocation } from 'react-router-dom';
import EditCompanyFeatures from '../components/Company/EditCompanyFeatures';

const EditCompany = () => {
  const { companyId } = useParams();
  const { pathname } = useLocation();

  const detailsToEdit = pathname.split('/')[1].split('-')[2];

  return (
    <div className='d-flex flex-column justify-content-center align-items-center'>
      {detailsToEdit === 'info' ? <EditCompanyForm companyId={companyId} /> : <EditCompanyFeatures />}
    </div>
  );
};

export default EditCompany;
