import { useCallback, useEffect, useState } from 'react';
import {
  fisherYatesShuffle,
  ishiharaPlates,
  ishiharaFamiliarisationPlate,
  ishiharaAdditionalPlates,
  fixedOrderPlates,
} from '../helper/ishihara-helpers';
import { saveTestResults } from '../api/testFunctions';
import { useParams, useHistory } from 'react-router-dom';
import { getAppointment } from '../api/appointmentFunctions';

const IshiharaTest = () => {
  const [activePlate, setActivePlate] = useState({});
  const [activePlateIndex, setActivePlateIndex] = useState(0);
  const [shuffledPlates, setShuffledPlates] = useState([]);
  const [answerOptions, setAnswerOptions] = useState([]);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [testId, setTestId] = useState(0);
  const { apptId } = useParams();
  const history = useHistory();
  const [patientAnswers, setPatientAnswers] = useState({});

  const handlePatientAnswer = () => {
    if (selectedAnswer === activePlate.answer) {
      return setPatientAnswers((prev) => {
        return { ...prev, [activePlate.id]: { plate: activePlate, result: 'Correct', selected: selectedAnswer } };
      });
    }

    if (selectedAnswer === activePlate.penalty_answer) {
      return setPatientAnswers((prev) => {
        return { ...prev, [activePlate.id]: { plate: activePlate, result: 'Incorrect', selected: selectedAnswer } };
      });
    }

    return setPatientAnswers((prev) => {
      return { ...prev, [activePlate.id]: { plate: activePlate, result: '', selected: selectedAnswer } };
    });
  };

  const handleNextPlate = () => {
    setSelectedAnswer(null);
    return setActivePlateIndex((current) => current + 1);
  };

  const submitAnswer = (e) => {
    e.preventDefault();
    handlePatientAnswer();
  };

  const endTest = useCallback(() => {
    saveTestResults({ appointment: apptId, appointment_eye_test: testId, result_data: JSON.stringify(patientAnswers) }).then(() =>
      history.push(`${process.env.PUBLIC_URL}/pre-screening/${apptId}`)
    );
  }, [patientAnswers, apptId, history, testId]);

  useEffect(() => {
    if (!shuffledPlates.length) {
      return setShuffledPlates([
        ishiharaFamiliarisationPlate,
        ...fisherYatesShuffle(ishiharaPlates),
        ...fixedOrderPlates,
        ...ishiharaAdditionalPlates,
      ]);
    }
    if (activePlateIndex === shuffledPlates.length) {
      return endTest();
    }
    setActivePlate(shuffledPlates[activePlateIndex]);
  }, [shuffledPlates, activePlateIndex, endTest]);

  useEffect(() => {
    setAnswerOptions(activePlate?.options || []);
  }, [activePlate]);

  useEffect(() => {
    getAppointment(apptId).then((response) => {
      const { tests_results } = response;
      const { result_data } = tests_results?.find((result) => result.test_key === 'ishihara') || {};
      if (result_data) {
        return history.replace('/404');
      }

      if (response.detail === 'Not found.') return history.replace('/404');
      const {
        prescreening: { tests },
      } = response;

      const { test_id } = tests?.find((test) => test.key === 'ishihara') || {};

      if (test_id === undefined) {
        return history.replace('/404');
      }

      setTestId(test_id);
    });
  }, [apptId, history]);

  useEffect(() => {
    const shouldEndTest = () => {
      return getCorrectAnswersCount() >= 8 && getIncorrectAnswersCount() < 2;
    };

    const getCorrectAnswersCount = () => {
      return Object.values(patientAnswers).filter((answer) => answer.result === 'Correct').length;
    };

    const getIncorrectAnswersCount = () => {
      return Object.values(patientAnswers).filter((answer) => answer.result === 'Incorrect').length;
    };

    if (Object.keys(patientAnswers).length) {
      if (shouldEndTest()) {
        return endTest();
      }
      handleNextPlate();
    }
  }, [patientAnswers, endTest, shuffledPlates]);

  return (
    <div className='container-fluid d-flex justify-content-center'>
      <div className='col-xl-6 col-lg-10 col-md-10 mb-4'>
        <div className='card shadow mb-4' id='waiting-room-appointments-list'>
          <div className='card-header bg-primary-ibis py-3'>
            <h5 className='m-0 font-weight-bold text-white'>Ishihara Test</h5>
          </div>
          <div className='card-body d-flex flex-column align-items-center'>
            <p>
              This test is to assess your colour vision and some plates may just be random patterns. There is no right or wrong answer so answer with
              what you see.
            </p>
            <div className='d-flex' style={{ height: '80px' }}>
              <p className='center'>
                {activePlate?.custom_instructions || 'Please click on the number you see or click on "Unclear/nothing" if you don\'t see a number.'}
              </p>
            </div>
            <img src={`/img/ishihara/testplate_${activePlate.id}.png`} alt='ishihara plate' style={{ width: '400px' }} />
            <form className='w-100 d-flex flex-column align-items-center' onSubmit={submitAnswer}>
              <div className='form-row d-flex flex-column mb-3'>
                {answerOptions.map((num, index) => {
                  return (
                    <div key={`answer-${num}`} className='form-check'>
                      <input
                        className='form-check-input'
                        type='radio'
                        name='ishihara-options'
                        id={`answer${index}`}
                        value={num}
                        checked={selectedAnswer === num}
                        onChange={() => setSelectedAnswer(num)}
                      />
                      <label className='form-check-label font-weight-bold' htmlFor={`answer${index}`}>
                        {num}
                      </label>
                    </div>
                  );
                })}
                <div className='form-check'>
                  <input
                    className='form-check-input'
                    type='radio'
                    name='ishihara-options'
                    id='answer3'
                    value='unclear'
                    checked={selectedAnswer === 'unclear'}
                    onChange={() => setSelectedAnswer('unclear')}
                  />
                  <label className='form-check-label font-weight-bold' htmlFor='answer3'>
                    Unclear/nothing
                  </label>
                </div>
              </div>
              <button className='btn btn-ibis w-50' style={{ minWidth: '100px', maxWidth: '250px' }} disabled={!selectedAnswer}>
                Next
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IshiharaTest;
