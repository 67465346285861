import { SESSION_ACTIONS } from '../../../helper/recheck';
import { useRecheckActions, useSnackbar } from '../../../hooks';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import Snackbar from '../../Misc/Snackbar';

const Actions = ({ session, setSession }) => {
  const history = useHistory();
  const { showSnackbar, snackbarState } = useSnackbar();
  const { handleStatusUpdate, handleEmailSend } = useRecheckActions(showSnackbar);

  const shouldDisplay = (action, status) => {
    if (action.type === 'status') {
      return status !== action.payload;
    }

    return action.payload !== 'sessions';
  };

  const handleClick = async (action) => {
    switch (action.type) {
      case 'navigate':
        history.push(`/recheck/${action.payload}/${session.id}`);
        break;
      case 'email':
        await handleEmailSend(session.id, action.payload);
        break;
      case 'status':
        await handleStatusUpdate(session.id, action.payload);
        setSession({ ...session, status: action.payload });
        break;
      default:
        break;
    }
  };

  return (
    <div className='flex'>
      {SESSION_ACTIONS.map(({ icon, label, action }, idx) => {
        return (
          shouldDisplay(action, session.status) && (
            <button className='btn btn-default btn-ibis m-2 text-capitalize' type='button' key={`button-` + idx} onClick={() => handleClick(action)}>
              <i className={`mr-2 fas fa-fw ${icon}`} id={`icon-` + idx} />
              {label}
            </button>
          )
        );
      })}
      <Snackbar showSnackbar={showSnackbar} snackbarState={snackbarState} />
    </div>
  );
};

export default Actions;
