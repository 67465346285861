import React, { Fragment, useEffect } from 'react';
import { createPopper } from '@popperjs/core';
import { useHistory } from 'react-router-dom';
import Snackbar from '../Misc/Snackbar';
import { useRecheckActions, useSnackbar } from '../../hooks';
import { SESSION_ACTIONS } from '../../helper/recheck';

const RecheckActionsPopover = ({ session, showPopover, setShowPopover }) => {
  const history = useHistory();
  const { showSnackbar, snackbarState } = useSnackbar();
  const { handleStatusUpdate, handleEmailSend } = useRecheckActions(showSnackbar);

  const handleClick = (action) => {
    switch (action.type) {
      case 'navigate':
        history.push(`/recheck/${action.payload}/${session.id}`);
        break;
      case 'email':
        handleEmailSend(session.id, action.payload, true);
        break;
      case 'status':
        handleStatusUpdate(session.id, action.payload, true);
        break;
      default:
        break;
    }
    return setShowPopover(false);
  };

  useEffect(() => {
    const popover = document.querySelector(`#action-panel-${session.id}`);
    if (showPopover) {
      const actionsBtn = document.querySelector(`#action-btn-${session.id}`);
      createPopper(actionsBtn, popover, {
        placement: 'left',
        strategy: 'fixed',
      });

      popover.style.display = 'block';

      popover.addEventListener('mousedown', function preventActionBtnBlur(e) {
        return e.preventDefault();
      });
    } else {
      popover.style.display = 'none';
    }
  }, [session.id, showPopover]);

  return (
    <dialog id={`action-panel-${session.id}`} className='popover' style={{ padding: 0, minWidth: '270px' }}>
      <Snackbar showSnackbar={showSnackbar} snackbarState={snackbarState} />
      <ul>
        <div className='popover-list-container'>
          {SESSION_ACTIONS.map(({ icon, label, action }, idx) => {
            if (action.type === 'status' && session.status === action.payload) return null;
            return (
              <Fragment key={label}>
                <li className='popover-link text-capitalize' onClick={() => handleClick(action)}>
                  <i className={`mr-2 fas fa-fw ${icon}`} />
                  {label}
                </li>
                {idx < SESSION_ACTIONS.length - 1 && <hr />}
              </Fragment>
            );
          })}
        </div>
      </ul>
    </dialog>
  );
};

export default RecheckActionsPopover;
