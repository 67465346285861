const WaitingRoomPrescriptionTable = ({ prescriptionFromServer }) => {
  const prescriptionData = JSON.parse(prescriptionFromServer.prescription_data || '{}');
  const categories = ['Sphere', 'Cylinder', 'Axis', 'Horizontal Prism', 'Vertical Prism', 'Add', 'Distance PD', 'Near PD'];

  return (
    <table className='table table-bordered'>
      <thead>
        <tr>
          <th scope='col'>{/* intentionally blank - corner cell*/}</th>
          {categories.map((heading) => {
            if (heading.includes('Horizontal')) heading = 'Prism (H)';
            if (heading.includes('Vertical')) heading = 'Prism (V)';
            if (heading.includes('Distance')) heading = 'Dist. PD';
            return (
              <th key={`heading-${heading}`} scope='col' className='p-1'>
                <div className='d-flex flex-column'>
                  <p className='m-0 text-center' style={{ fontSize: '0.75rem', minWidth: heading.includes('Prism') ? '60px' : null }}>
                    {heading}
                  </p>
                </div>
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {['right', 'left'].map((eye) => {
          return (
            <tr key={`${eye}-eye-row`}>
              <th scope='row' className='p-0 text-center'>{`${eye.charAt(0).toUpperCase()}`}</th>
              {categories.map((cat) => {
                const catId = cat.replace(/\s+/, '-').toLowerCase();
                return (
                  <td key={`${eye}-${catId}`} className='p-0 px-1' style={{ verticalAlign: 'middle', textAlign: 'center', fontSize: '0.825rem' }}>
                    {prescriptionData[eye]?.[catId]}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default WaitingRoomPrescriptionTable;
