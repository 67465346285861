import { useHistory } from 'react-router-dom';
import './PageNotFound.css';

const PageNotFound = () => {
  const history = useHistory();

  return (
    <div className='container-fluid'>
      <div className='container-404 pl-4 d-flex'>
        <div className='d-flex flex-column'>
          <h1 className='heading-404'>404</h1>
          <h2 className='subheading-404'>Page Not Found. A little short-sightedness on our part.</h2>
          <p className='body-404'>
            Don't worry, our focus is sharp when it comes to eyecare. Let's get you back on track to seamless remote vision testing!
          </p>
          <div className='d-flex'>
            <button className='mr-1 btn-404 primary' onClick={() => history.push('/')}>
              Take Me Home
            </button>
            <button className='btn-404 white' onClick={() => history.goBack()}>
              Go Back
            </button>
          </div>
        </div>
        <div className='img-container'>
          <div className='color-img-container'>
            <div className='color-overlay' />
            <img src='img/splash-bg-404.png' alt='404' />
          </div>
          <div className='laptop-img-container'>
            <img src='img/laptop-404.png' alt='ibisvision on laptop' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
