import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { patchData } from '../../api/apiFunctions';
import { deleteCompany } from '../../api/companyFunctions';
import { useModal } from '../../hooks';
import Modal from 'react-modal';

const CompanyDangerZone = ({ companyId, companyName, companyActive, setCompanyActive }) => {
  const { modalIsOpen, toggle } = useModal();
  const [typedName, setTypedName] = useState('');
  const history = useHistory();

  const toggleCompanyActivation = async () => {
    if (
      window.confirm(
        `Are you sure you want to ${companyActive ? 'de' : 're'}activate this company? ${
          companyActive ? 'All users will be blocked from accessing the platform.' : 'All users will regain access as normal.'
        }`
      )
    ) {
      await patchData(`/api/companies/${companyId}/`, { is_active: !companyActive });
      return setCompanyActive(!companyActive);
    }
  };

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={toggle}
        role='document'
        appElement={document.getElementById('wrapper')}
        closeTimeoutMS={300}
        className='Modal__Bootstrap modal-dialog'
      >
        <div className='modal-content'>
          <div className='modal-header bg-primary-ibis'>
            <h5 className='modal-title'>Delete Company</h5>
            <button className='close' type='button' aria-label='Close' onClick={toggle}>
              <span aria-hidden='true'>&times;</span>
            </button>
          </div>
          <div className='modal-body modal-lg d-flex flex-column align-items-center p-5'>
            <h4 style={{ color: 'red' }}>WARNING</h4>
            <p>You are about to permanently delete this company from the system. This action cannot be undone.</p>
            <p>
              Please be sure you do not want to <strong>deactivate</strong> the company instead.
            </p>
            <p className='mb-4 p-3 border border-dark'>
              Type <strong>{companyName}</strong> below to proceed with deletion.
            </p>
            <input className='form-control' value={typedName} onChange={(e) => setTypedName(e.target.value)} />
          </div>
          <div className='modal-footer'>
            <button className='btn btn-secondary' type='button' onClick={toggle}>
              Cancel
            </button>
            <button
              className='btn btn-danger'
              type='button'
              disabled={typedName !== companyName}
              onClick={() => {
                deleteCompany(companyId).then(() => history.replace(`${process.env.PUBLIC_URL}/companies/`));
              }}
            >
              Delete
            </button>
          </div>
        </div>
      </Modal>
      <div className='card shadow-sm'>
        <div className='list-group-item border border-danger'>
          <div className='d-flex align-items-center justify-content-between'>
            <h4 className='m-0'>Company Status</h4>
            <span className={`badge badge-${companyActive ? 'success' : 'danger'}`} style={{ fontSize: '1rem' }}>
              {companyActive ? 'ACTIVE' : 'INACTIVE'}
            </span>
          </div>
          <p className='m-0'>Change status or delete this company.</p>
          <div className='w-100 d-flex flex-column align-items-center mt-4' style={{ gap: '1rem' }}>
            <button className='btn btn-warning w-50' style={{ color: '#000' }} onClick={toggleCompanyActivation}>{`Make Company ${
              companyActive ? 'Inactive' : 'Active'
            }`}</button>
            <button className='btn btn-danger w-50 font-weight-bold' onClick={toggle}>
              DELETE COMPANY
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanyDangerZone;
