import { useEffect, useState } from 'react';

const AdditionalPrivilegesCheckbox = ({ invitedUserRole, additionalPermissions, setAdditionalPermissions }) => {
  const [possibleExtendedRole, setPossibleExtendedRole] = useState('');

  useEffect(() => {
    if (invitedUserRole === 'optician') return setPossibleExtendedRole('Administrator');
    if (invitedUserRole === 'administrator') return setPossibleExtendedRole('Clinician');
  }, [invitedUserRole]);

  return (
    <div style={{ visibility: invitedUserRole.length && invitedUserRole !== 'patient' ? 'visible' : 'hidden' }}>
      <div className='dropdown-divider' />
      <div className='d-flex justify-content-center'>
        <div className='form-check'>
          <input
            className='form-check-input'
            type='checkbox'
            id='additional-permissions-checkbox'
            value={additionalPermissions}
            onChange={() => setAdditionalPermissions(!additionalPermissions)}
          />
          <label className='form-check-label' htmlFor='additional-permissions-checkbox'>
            {`${invitedUserRole === 'optician' ? 'Clinician' : 'Administrator'} is also ${possibleExtendedRole}`}
          </label>
        </div>
      </div>
    </div>
  );
};

export default AdditionalPrivilegesCheckbox;
