import CompanyForm from '../Company/CompanyForm';

import IbisModal from './IbisModal';

const CompanyModal = ({ modalIsOpen, toggle }) => {
  return (
    <IbisModal title={`Add Company`} modalIsOpen={modalIsOpen} toggle={toggle} className='Modal__Bootstrap modal-dialog modal-lg'>
      <CompanyForm toggle={toggle} />
    </IbisModal>
  );
};

export default CompanyModal;
