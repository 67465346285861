import { useState, useEffect } from 'react';
import { sendNewInvitation, getInvitations } from '../../api/invitationFunctions';
import { useCompany } from '../../context/companyContext';
import { getCompanies } from '../../api/companyFunctions';
import LoadingSpinner from './LoadingSpinner';
import AdditionalPrivilegesCheckbox from '../Invitations/AdditionalPrivilegesCheckbox';

const InvitationsForm = ({ userType, setInvitations, superuser, hide, showSnackbar }) => {
  const { companies, setCompanies } = useCompany();
  const [email, setEmail] = useState('');
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [invitedUserRole, setInvitedUserRole] = useState('');
  const [invitationPending, setInvitationPending] = useState(false);
  const [possibleExtendedRole, setPossibleExtendedRole] = useState(false);

  useEffect(() => {
    if (invitedUserRole === '' && userType === 'optician') {
      setInvitedUserRole('patient');
    }

    if (superuser) {
      getCompanies().then((response) => setCompanies(response));
    }
  }, [userType, invitedUserRole, superuser, setCompanies]);

  const sendInvitation = () => {
    const delay = setInterval(() => setInvitationPending(true), 300);

    const invitationData = {
      invited_user_email: email,
      invited_user_role: possibleExtendedRole ? 'optician_administrator' : invitedUserRole,
      company: selectedCompany,
    };

    sendNewInvitation(invitationData).then(async (response) => {
      clearInterval(delay);
      setInvitationPending(false);
      if (response) {
        setInvitations(await getInvitations());
        showSnackbar();
        closeModal();
      }
    });
  };

  const clearFormErrors = () => {
    Array.from(document.querySelectorAll('.is-invalid')).forEach((el) => el.classList.remove('is-invalid'));
  };

  const closeModal = () => {
    hide();
    clearFormErrors();
    setEmail('');
    setInvitedUserRole('');
  };

  return (
    <>
      {userType === 'patient' ? null : (
        <>
          {invitationPending ? <LoadingSpinner message={'Sending invitation...'} /> : null}
          <div className='modal-content'>
            <div className='modal-header bg-primary-ibis'>
              <h5 className='modal-title' id={'sendInvitationModalLabel'}>
                Invite User
              </h5>
              <button className='close' type='button' onClick={() => closeModal()} aria-label='Close'>
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-body'>
              <form className='user user-form' name='user-form' id='invitations-form'>
                {superuser ? (
                  <div className='form-group row justify-content-center'>
                    <>
                      <select
                        className='form-control form-control company form-select'
                        aria-label='Select a company'
                        id='field_company'
                        onChange={(e) => setSelectedCompany(e.target.value)}
                        value={selectedCompany || ''}
                        style={{ width: '60%' }}
                      >
                        {!selectedCompany ? <option value=''>Select a company...</option> : null}
                        {companies?.map((companyOption) => {
                          const { id, name } = companyOption;
                          return (
                            <option key={`company-${id}`} value={id}>
                              {name}
                            </option>
                          );
                        })}
                      </select>
                      <div id='validationfield_company' className='invalid-feedback text-center'>
                        <span id='invalid_company_message'></span>
                      </div>
                    </>
                  </div>
                ) : null}
                <div className='form-group row justify-content-center' style={{ marginBottom: 0 }}>
                  <div className='col-sm-8'>
                    <input
                      type='email'
                      className='form-control email'
                      id='field_invited_user_email'
                      placeholder='Email Address'
                      value={email}
                      onChange={(e) => {
                        clearFormErrors();
                        setEmail(e.target.value);
                      }}
                    />
                    <div id='validationfield_invited_user_email' className='invalid-feedback'>
                      <span id='invalid_invited_user_email_message'></span>
                    </div>
                    {userType === 'admin' ? (
                      <>
                        <div
                          id='field_invited_user_role'
                          style={{
                            display: 'flex',
                            flexDirection: `${superuser ? 'column' : 'row'}`,
                            justifyContent: 'space-evenly',
                            alignItems: 'center',
                            marginTop: '1rem',
                          }}
                          onClick={() => clearFormErrors()}
                        >
                          <div className='form-check' style={{ width: '80px' }} name='invitation-radio-btns'>
                            <input
                              className='form-check-input'
                              type='radio'
                              name='invitation-radio-btn'
                              id='optician-radio-btn'
                              value='optician'
                              checked={invitedUserRole === 'optician'}
                              onChange={(e) => setInvitedUserRole(e.target.value)}
                            />
                            <label className='form-check-label' htmlFor='optician-radio-btn'>
                              Clinician
                            </label>
                          </div>
                          <div className='form-check' style={{ width: '80px' }}>
                            <input
                              className='form-check-input'
                              type='radio'
                              name='invitation-radio-btn'
                              id='patient-radio-btn'
                              value='patient'
                              checked={invitedUserRole === 'patient'}
                              onChange={(e) => setInvitedUserRole(e.target.value)}
                            />
                            <label className='form-check-label' htmlFor='patient-radio-btn'>
                              Patient
                            </label>
                          </div>
                          {superuser ? (
                            <>
                              <div className='form-check' style={{ width: '80px' }}>
                                <input
                                  className='form-check-input'
                                  type='radio'
                                  name='invitation-radio-btn'
                                  id='admin-radio-btn'
                                  value='administrator'
                                  checked={invitedUserRole === 'administrator'}
                                  onChange={(e) => setInvitedUserRole(e.target.value)}
                                />
                                <label className='form-check-label' htmlFor='admin-radio-btn'>
                                  Admin
                                </label>
                              </div>
                            </>
                          ) : null}
                        </div>
                        <div id='validationfield_invited_user_role' className='invalid-feedback'>
                          <span id='invalid_invited_user_role_message'></span>
                        </div>
                      </>
                    ) : null}
                    {superuser && (
                      <AdditionalPrivilegesCheckbox
                        invitedUserRole={invitedUserRole}
                        additionalPermissions={possibleExtendedRole}
                        setAdditionalPermissions={setPossibleExtendedRole}
                      />
                    )}
                  </div>
                </div>
              </form>
            </div>
            <div className='modal-footer'>
              <button
                className='btn btn-secondary'
                type='button'
                data-dismiss='modal'
                onClick={() => {
                  closeModal();
                }}
              >
                Cancel
              </button>
              <button
                className='btn btn-ibis'
                type='button'
                id='sendButton'
                onClick={() => {
                  sendInvitation();
                }}
              >
                Send
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default InvitationsForm;
