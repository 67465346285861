import QuestionnairesList from './QuestionnairesList';
import { useModal } from '../../hooks';
import QuestionnaireModal from '../Modals/QuestionnaireModal';

const QuestionnaireContent = () => {
  const { modalIsOpen, toggle } = useModal();

  return (
    <>
      <QuestionnaireModal modalIsOpen={modalIsOpen} toggle={toggle} />
      <div className='container-fluid'>
        <div className='d-sm-flex align-items-center justify-content-end mb-4'>
          <button className='d-none d-sm-inline-block btn btn-sm btn-ibis shadow-sm' type='button' onClick={toggle}>
            <i className='fas fa-plus fa-sm text-white-50'></i>&nbsp;Add
          </button>
        </div>
        <QuestionnairesList />
      </div>
    </>
  );
};

export default QuestionnaireContent;
