const GeneralInfo = ({ session }) => {
  return (
    <table className='table table-bordered table-striped w-75'>
      <thead>
        <tr>
          <th colSpan={2} className='text-center bg-primary-ibis'>
            General Info
          </th>
        </tr>
      </thead>
      <tbody>
        {!session?.id ? (
          <tr>
            <td colSpan={2}>No info found.</td>
          </tr>
        ) : (
          <>
            <tr>
              <td className='font-weight-bold w-25'>Patient</td>
              <td>{session.user}</td>
            </tr>
            <tr>
              <td className='font-weight-bold'>DOB</td>
              <td>{session.user_data?.profile?.date_of_birth || 'Not informed'}</td>
            </tr>
            <tr>
              <td className='font-weight-bold'>Session Date</td>
              <td>{session.created.split(' ')[0]}</td>
            </tr>
            {/* <tr>
              <td className='font-weight-bold'>Session Type</td>
              <td className='text-capitalize'>{session?.session_type === 'full' ? 'Full session' : 'Partial session'}</td>
            </tr> */}
            <tr>
              <td className='font-weight-bold'>Session Status</td>
              <td className='text-capitalize'>{session.status_label}</td>
            </tr>
          </>
        )}
      </tbody>
    </table>
  );
};

export default GeneralInfo;
