import { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useQuestionnaire } from '../context/questionnaireContext';
import { getAppointmentQuestionnaire } from '../api/questionnaireFunctions';
import QuestionAnswer from '../components/Questionnaire/QuestionAnswer';

const CompleteQuestionnaire = () => {
  const { apptId, questionnaireId } = useParams();
  const { addQuestionnaireAnswer } = useQuestionnaire();
  const [questionnaireQuestions, setQuestionnaireQuestions] = useState([]);
  const [activeQuestion, setActiveQuestion] = useState({});
  const [activeResponse, setActiveResponse] = useState('');
  const [finalQuestion, setFinalQuestion] = useState(false);
  const [savingInProgress, setSavingInProgress] = useState(false);
  const history = useHistory();

  const saveAnswer = async () => {
    setSavingInProgress(true);
    await saveResponse();
    if (finalQuestion) return history.push(`/pre-screening/${apptId}`);
    return goToNextQuestion();
  };

  const goToNextQuestion = () => {
    setActiveResponse('');

    let nextQuestionId;

    if (activeQuestion.question_type === 'boolean') {
      nextQuestionId = activeQuestion[`next_question_${activeResponse}`];
    }
    if (activeQuestion.question_type === 'text' || activeQuestion.question_type === 'checkbox') {
      nextQuestionId = activeQuestion.next_question_text;
    }

    const nextQuestion = questionnaireQuestions.find((question) => question.id === nextQuestionId);
    setActiveQuestion(nextQuestion);
    setSavingInProgress(false);
    if (nextQuestion.answer !== '') return setActiveResponse(nextQuestion.answer);
  };

  const saveResponse = async () => {
    const answerData = {
      appointment_questionnaire_question: activeQuestion.id,
      answer: activeResponse,
      ...(activeQuestion.answer_id && { id: activeQuestion.answer_id }),
    };

    const answerFromServer = await addQuestionnaireAnswer(answerData, activeQuestion.question_type);
    return answerFromServer;
  };

  useEffect(() => {
    const getQuestionnaireFromServer = async () => {
      const questionnaireFromServer = await getAppointmentQuestionnaire(questionnaireId);
      const { questions, initial_question } = questionnaireFromServer;
      const initialQuestion = questions.find((q) => q.id === initial_question.id);
      setQuestionnaireQuestions(questions);
      setActiveQuestion(initialQuestion);
      if (initial_question.answer !== '') setActiveResponse(initial_question.answer);
    };

    getQuestionnaireFromServer();
  }, [questionnaireId]);

  useEffect(() => {
    const { question_type, next_question_text, next_question_false, next_question_true } = activeQuestion;

    if ((question_type === 'text' || question_type === 'checkbox') && !next_question_text) return setFinalQuestion(true);
    if (question_type === 'boolean') {
      if ((activeResponse === true && !next_question_true) || (activeResponse === false && !next_question_false)) return setFinalQuestion(true);
      return setFinalQuestion(false);
    }
  }, [activeQuestion, activeResponse]);

  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-xl-12 col-md-6 mb-4'>
          {activeQuestion && (
            <div className='card shadow mb-4'>
              <div className='card-header bg-primary-ibis py-3'>
                <h6 className='m-0 font-weight-bold text-white'>{activeQuestion.text}</h6>
              </div>
              <QuestionAnswer question={activeQuestion} activeResponse={activeResponse} setActiveResponse={setActiveResponse} />
            </div>
          )}
        </div>
      </div>
      <button
        id='saveButton'
        className='btn btn-ibis m-2'
        onClick={saveAnswer}
        disabled={savingInProgress || (activeQuestion.question_type !== 'checkbox' && activeResponse === '')}
      >
        {finalQuestion ? 'Save & Finish' : 'Save & Continue'}
      </button>
    </div>
  );
};

export default CompleteQuestionnaire;
