import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getAppointmentQuestionnaire } from '../../api/questionnaireFunctions';
import AppointmentQuestionnaireQuestions from '../Appointment/AppointmentQuestionnaireQuestions';

const Questionnaire = ({ apptId, questionnaireId }) => {
  const [questionnaireDetails, setQuestionnaireDetails] = useState({});
  const loggedUser = JSON.parse(localStorage.getItem('loggedUser'));
  const history = useHistory();

  useEffect(() => {
    if (!questionnaireId) return;
    const getQuestions = async () => {
      const appointmentQuestionnaireFromServer = await getAppointmentQuestionnaire(questionnaireId);
      setQuestionnaireDetails(appointmentQuestionnaireFromServer);
    };

    getQuestions();
  }, [questionnaireId]);

  return (
    <div className='border border-dark rounded p-3 mb-4 bg-white'>
      <div className='d-sm-flex align-items-center justify-content-between mb-4'>
        <div className='col-sm-6' style={{ paddingLeft: '0.3rem' }}>
          <h3 className='h3 mb-0 text-gray-800'>{`Questionnaire: ${questionnaireDetails.name || 'Loading...'}`}</h3>
          <i className='fas fa-clock' style={{ marginRight: '0.35rem', color: 'var(--primary-color)' }} />
          {questionnaireDetails.average_duration &&
            `${questionnaireDetails.average_duration / 60} minute${questionnaireDetails.average_duration > 60 ? 's' : ''} `}
        </div>
        {loggedUser.role === 'patient' ? (
          <div className='d-flex justify-content-center'>
            <button
              className='btn-ibis btn-lg shadow-sm'
              onClick={() => history.push(`${process.env.PUBLIC_URL}/${apptId}/questionnaire-${questionnaireId}`)}
            >
              Fill Questionnaire
            </button>
          </div>
        ) : null}
      </div>
      <div className='mb-3' style={{ width: '50vw' }}>
        {questionnaireDetails.description?.match(/[A-Z0-9]/i) ? questionnaireDetails.description : null}
      </div>
      <div className='row'>
        <div className='col-xl-12 col-md-6 mb-4'>
          <div className='card shadow mb-4'>
            <a
              href='#collapsePatientAnswers'
              className='d-block card-header bg-primary-ibis py-3'
              data-toggle='collapse'
              role='button'
              aria-expanded='false'
              aria-controls='collapsePatientAnswers'
            >
              <h6 className='m-0 font-weight-bold text-white'>{`${loggedUser.role === 'patient' ? 'Your' : 'Patient'} Answers`}</h6>
            </a>
            <div className='collapse' id='collapsePatientAnswers'>
              <div className='card-body'>
                <div className='table-responsive'>
                  <table className='table table-bordered' id='dataTable' width='100%' cellSpacing='0'>
                    <thead>
                      <tr>
                        <th>Question</th>
                        <th>Answer</th>
                      </tr>
                    </thead>
                    <tfoot>
                      <tr>
                        <th>Question</th>
                        <th>Answer</th>
                      </tr>
                    </tfoot>
                    <tbody>
                      <AppointmentQuestionnaireQuestions appointmentQuestionnaireId={questionnaireId} answers />
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Questionnaire;
