import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getAppointmentQuestionnaire } from '../../api/questionnaireFunctions';

const WaitingRoomQuestionnairesResults = ({ apptDetails }) => {
  const [questionnaireTitle, setQuestionnaireTitle] = useState('');
  const [patientHasAnswered, setPatientHasAnswered] = useState(false);
  const history = useHistory();

  useEffect(() => {
    getAppointmentQuestionnaire(apptDetails.questionnaire_id).then(({ name, filtered_questions }) => {
      setQuestionnaireTitle(name);
      if (filtered_questions?.length) return setPatientHasAnswered(true);
    });
  }, [apptDetails.questionnaire_id]);

  return (
    <div className='w-75 d-flex mt-4 align-items-center' style={{ gap: '1rem' }}>
      <strong className='m-0'>{questionnaireTitle}</strong>
      <button className='btn btn-ibis btn-md w-50' onClick={() => history.push(`/pre-screening/${apptDetails.id}`)} disabled={!patientHasAnswered}>
        {patientHasAnswered ? 'View Patient Answers' : 'Not Completed'}
      </button>
    </div>
  );
};

export default WaitingRoomQuestionnairesResults;
