import { createContext, useContext } from 'react';

import { useAuth } from '../context/authenticationContext';

const ApiContext = createContext();

export const useApi = () => {
  return useContext(ApiContext);
};

export const ApiProvider = ({ children }) => {
  const { getToken, destroySession } = useAuth();

  const apiUrl = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_PROD_API_URL : process.env.REACT_APP_DEV_API_URL;

  const customRequest = async (path, options) => {
    let response = await fetch(apiUrl + path, options);

    if (response.status === 401 && response.redirected) {
      response = await fetch(response.url, options);
    }

    if (response.status === 401) {
      destroySession();
      window.location.reload();
    }

    return await response;
  };

  const getAuthorisedHeader = (method) => {
    if (!method) {
      method = 'GET';
    }
    const authorisedHeader = {
      Authorization: 'Token ' + getToken(),
    };
    if (method === 'POST' || method === 'PATCH') {
      authorisedHeader['Content-type'] = 'application/json';
    }

    return authorisedHeader;
  };

  const postData = async (url = '', data = {}, unauthorised) => {
    const postHeader = getAuthorisedHeader('POST');
    if (unauthorised) {
      delete postHeader.Authorization;
    }
    const response = await customRequest(url, {
      method: 'POST',
      headers: postHeader,
      body: JSON.stringify(data),
    });

    return response.json();
  };

  const patchData = async (url = '', data = {}) => {
    const response = await customRequest(url, {
      method: 'PATCH',
      headers: getAuthorisedHeader('PATCH'),
      body: JSON.stringify(data),
    });

    return response.json();
  };

  return <ApiContext.Provider value={{ postData, customRequest, getAuthorisedHeader, patchData }}>{children}</ApiContext.Provider>;
};
