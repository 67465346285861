import { useEffect, useState } from 'react';
import { formattedRefractionScore } from '../../helper/refraction';

const RefractionEyeScore = ({ eye, results }) => {
  const [displayData, setDisplayData] = useState([]);
  const title = eye.charAt(0).toUpperCase() + eye.slice(1) + `${eye !== 'binocular' ? ' Eye' : ''}`;

  useEffect(() => {
    if (eye !== 'binocular') {
      const eyeResults = results[`${eye}Eye`];
      if (typeof eyeResults === 'object') {
        setDisplayData([
          `Sphere: ${formattedRefractionScore(eyeResults.score.sphere, 2)}`,
          `Cylinder: ${formattedRefractionScore(eyeResults?.score?.cylinder, 2)}`,
          `Axis: ${formattedRefractionScore(eyeResults?.score?.axis)}`,
        ]);
      } else setDisplayData([eyeResults, eyeResults, eyeResults]);
    } else {
      setDisplayData([
        `Sphere: ${formattedRefractionScore(results.bothEyes.score.sphere, 2)}`,
        `Cylinder: ${formattedRefractionScore(results.bothEyes.score.cylinder, 2)}`,
        `Axis: ${results.bothEyes.score.axis}`,
      ]);
    }
  }, [eye, results]);

  return (
    <div className='d-flex flex-column' style={{ minWidth: '350px', margin: '10px' }}>
      <div
        style={{ width: '100%', height: '50px', backgroundColor: 'var(--primary-color)', color: 'white' }}
        className='d-flex justify-content-center align-items-center'
      >
        <span>{title}</span>
      </div>
      <div style={{ width: '100%', height: '80px' }} className='d-flex justify-content-center align-items-center'>
        <span style={{ fontSize: '18px', padding: '15px' }}>{displayData[0]}</span>
      </div>
      <div
        style={{ backgroundColor: '#e4e4e4', width: '100%', height: '110px' }}
        className='d-flex flex-column justify-content-center align-items-center'
      >
        <span style={{ marginBottom: '10px', fontSize: '18px', padding: '15px' }}>{displayData[1]}</span>
      </div>
      <div style={{ width: '100%', height: '80px' }} className='d-flex justify-content-center align-items-center'>
        <span style={{ fontSize: '18px', padding: '15px' }}>{displayData[2]}</span>
      </div>
    </div>
  );
};

export default RefractionEyeScore;
