import UserForm from './UserForm';
import EditUserForm from './EditUserForm';
import OpticiansList from './OpticiansList';
import { useUser } from '../../context/userContext';
import { IbisOptician } from '../../helper/IbisUser';
import { useState } from 'react';

const OpticianContent = () => {
  const { addUser, saveUser } = useUser();
  const [showInactiveOpticians, setShowInactiveOpticians] = useState(false);
  const loggedUser = JSON.parse(localStorage.getItem('loggedUser'));

  return (
    <>
      <div className='container-fluid'>
        <div className='d-sm-flex align-items-center justify-content-between'>
          <div className='custom-control custom-switch'>
            <input
              type='checkbox'
              className='custom-control-input'
              id='showInactiveOpticiansSwitch'
              checked={showInactiveOpticians}
              onChange={() => setShowInactiveOpticians(!showInactiveOpticians)}
            />
            <label className='custom-control-label' style={{ color: 'black' }} htmlFor='showInactiveOpticiansSwitch'>
              <h4>Include Inactive Clinicians</h4>
            </label>
          </div>
          {loggedUser?.superuser && (
            <button
              className='d-none d-sm-inline-block btn btn-sm btn-ibis shadow-sm'
              type='button'
              data-toggle='modal'
              data-target='#addOpticianModal'
            >
              <i className='fas fa-plus fa-sm text-white-50'></i>&nbsp;Add
            </button>
          )}
        </div>
        <div className='row'>
          <div className='col-xl-12 col-md-6'>
            <OpticiansList showInactiveOpticians={showInactiveOpticians} />
          </div>
        </div>
      </div>
      <UserForm userType='Optician' addUser={addUser} ibisUserType={new IbisOptician()} />
      <EditUserForm userType='Optician' saveUser={saveUser} ibisUserType={new IbisOptician()} />
    </>
  );
};
export default OpticianContent;
