const CompanyTimeZoneSelect = ({ value, onChange, id, className, options }) => {
  return (
    <select
      name='timezone'
      className={className}
      id={id}
      value={value}
      onChange={onChange}
      style={{ color: value ? '#6e707e' : '#858796', paddingLeft: '0.375rem' }}
    >
      <option value='|0' hidden disabled>
        Select timezone...
      </option>
      {options?.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

export default CompanyTimeZoneSelect;
