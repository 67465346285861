import { useEffect, useState } from 'react';
import { useQuestionnaire } from '../../context/questionnaireContext';
import { fetchObjects } from '../../api/crudFunctions';

const EditQuestionnaireForm = ({ hide, questionnaireId }) => {
  const { saveQuestionnaire } = useQuestionnaire();
  const [questionnaireObject, setQuestionnaireObject] = useState({});
  const [completionTimes, setCompletionTimes] = useState([]);

  const { name = '', average_duration = '300', description = '' } = questionnaireObject;

  const getQuestionnaireData = () => {
    let questionnaireData = {
      name: name,
      average_duration: average_duration,
      description: description,
    };

    return questionnaireData;
  };

  const customSaveQuestionnaire = async () => {
    const existentQuestionnaire = await saveQuestionnaire(questionnaireId, getQuestionnaireData());
    if (typeof existentQuestionnaire == 'object') {
      hide();
    }
  };

  useEffect(() => {
    fetchObjects(`/api/company-questionnaires/${questionnaireId}`).then((response) => setQuestionnaireObject(response));
  }, [questionnaireId]);

  useEffect(() => {
    let timeValuesArray = [];
    for (let time = 5; time < 65; time += 5) {
      timeValuesArray.push(time);
    }
    setCompletionTimes(timeValuesArray);
  }, []);

  return (
    <div className='modal-content'>
      <div className='modal-header bg-primary-ibis'>
        <h5 className='modal-title' id={'editQuestionnaireModalLabel'}>
          Edit Questionnaire
        </h5>
        <button className='close' type='button' aria-label='Close' onClick={hide}>
          <span aria-hidden='true'>&times;</span>
        </button>
      </div>
      <div className='modal-body'>
        <form className='questionnaire' name='edit-questionnaire-form' autoComplete='off'>
          <div className='form-group row'>
            <div className='col-sm'>
              <label htmlFor='edit_field_name'>Questionnaire name</label>
              <input
                type='text'
                className='form-control form-control-questionnaire name'
                id='edit_field_name'
                value={name}
                onChange={(e) =>
                  setQuestionnaireObject((obj) => {
                    return { ...obj, name: e.target.value };
                  })
                }
              />
              <div id='validationedit_field_name' className='invalid-feedback'>
                <span id='edit_invalid_name_message'></span>
              </div>
            </div>
          </div>
          <div className='form-group row'>
            <div className='col-sm'>
              <label htmlFor='edit_field_average_duration'>Average Time to Complete</label>
              <select
                className='form-control form-control-questionnaire average_duration form-select'
                aria-label='Average time needed to complete questionnaire'
                id='edit_field_average_duration'
                value={average_duration}
                onChange={(e) =>
                  setQuestionnaireObject((obj) => {
                    return { ...obj, average_duration: e.target.value };
                  })
                }
              >
                {completionTimes.map((time) => {
                  const timeInSeconds = time * 60;
                  return (
                    <option key={`completion-time-${time}`} value={timeInSeconds}>
                      {`${time} minutes`}
                    </option>
                  );
                })}
              </select>
              <div id='validationedit_field_average_duration' className='invalid-feedback'>
                <span id='edit_invalid_name_message'></span>
              </div>
            </div>
          </div>
          <div className='form-group row'>
            <div className='col-sm'>
              <label htmlFor='edit_field_description'>Description</label>
              <textarea
                className='form-control form-control-questionnaire description'
                id='edit_field_description'
                rows={3}
                value={description}
                placeholder='Enter your description...'
                onChange={(e) =>
                  setQuestionnaireObject((obj) => {
                    return { ...obj, description: e.target.value };
                  })
                }
              />
              <div id='validationedit_field_description' className='invalid-feedback'>
                <span id='edit_invalid_name_message'></span>
              </div>
            </div>
          </div>
          <input type='hidden' className='form-control form-control-appointment id' id='edit_field_id' />
        </form>
      </div>
      <div className='modal-footer'>
        <button className='btn btn-secondary' type='button' onClick={hide}>
          Cancel
        </button>
        <button
          className='btn btn-ibis'
          type='button'
          onClick={() => {
            customSaveQuestionnaire();
          }}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default EditQuestionnaireForm;
