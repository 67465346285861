import { useEffect, useState } from 'react';

const OptimaEyeScore = ({ eye, results }) => {
  const [displayData, setDisplayData] = useState([]);
  const title = eye.charAt(0).toUpperCase() + eye.slice(1) + `${eye !== 'binocular' ? ' Eye' : ''}`;

  useEffect(() => {
    if (eye !== 'binocular') {
      const eyeResults = results[`${eye}Eye`];
      if (typeof eyeResults === 'object') {
        setDisplayData([`Text size read: ${eyeResults.textSizeRead}`, `${eyeResults.magnification}`]);
      } else setDisplayData([eyeResults, eyeResults]);
    } else {
      setDisplayData([`Text size read: ${results.bothEyes.textSizeRead}`, `${results.bothEyes.magnification}`]);
    }
  }, [eye, results]);

  return (
    <div className='d-flex flex-column' style={{ minWidth: '350px', margin: '10px' }}>
      <div
        style={{ width: '100%', height: '50px', backgroundColor: 'var(--primary-color)', color: 'white' }}
        className='d-flex justify-content-center align-items-center'
      >
        <span>{title}</span>
      </div>
      <div style={{ width: '100%', height: '80px' }} className='d-flex justify-content-center align-items-center'>
        <span style={{ fontSize: '18px', padding: '15px' }}>{displayData[0]}</span>
      </div>
      <div
        style={{ backgroundColor: '#e4e4e4', width: '100%', height: '110px' }}
        className='d-flex flex-column justify-content-center align-items-center'
      >
        <div className='d-flex flex-column align-items-center' style={{ fontSize: '18px' }}>
          <p className='mb-1' style={{ textAlign: 'center' }}>{`Suggested magnification:`}</p>
          <p className='mb-1'>{displayData[1]}</p>
        </div>
        <span style={{ fontSize: '0.825rem', visibility: displayData[1]?.startsWith('+') ? 'visible' : 'hidden' }}>{`(to read small print)`}</span>
      </div>
    </div>
  );
};

export default OptimaEyeScore;
