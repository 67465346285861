import { useQuestionnaire } from '../../context/questionnaireContext';
import IbisDataTable from '../DataTable/IbisDataTable';

const QuestionnaireQuestionList = ({ setOriginQuestionnaireQuestion, setQuestionPath, questionnaire, loggedUser }) => {
  const { deleteQuestionnaireQuestion, updateQuestionsOrder, questionnaireQuestions, getQuestionPath } = useQuestionnaire();
  const editQuestionPath = async (oriQuestionnaireQuestion) => {
    setOriginQuestionnaireQuestion(oriQuestionnaireQuestion);
    const questionPathResults = await getQuestionPath(oriQuestionnaireQuestion);
    setQuestionPath(questionPathResults);
    let questionnaireTruePathElement = document.getElementById('field_destination_questionnaire_boolean_true_question');
    let questionnaireFalsePathElement = document.getElementById('field_destination_questionnaire_boolean_false_question');
    let questionnaireTextPathElement = document.getElementById('field_destination_questionnaire_text_question');

    if (questionnaireTruePathElement) {
      questionnaireTruePathElement.value = '';
    }

    if (questionnaireFalsePathElement) {
      questionnaireFalsePathElement.value = '';
    }

    if (questionnaireTextPathElement) {
      questionnaireTextPathElement.value = '';
    }

    questionPathResults.forEach((question_path_result) => {
      let pathElement;

      if (oriQuestionnaireQuestion.question_type === 'boolean') {
        if (question_path_result.answer === true) {
          pathElement = questionnaireTruePathElement;
        } else if (question_path_result.answer === false) {
          pathElement = questionnaireFalsePathElement;
        }
      } else if (oriQuestionnaireQuestion.question_type === 'text' || oriQuestionnaireQuestion.question_type === 'checkbox') {
        pathElement = questionnaireTextPathElement;
      }

      if (pathElement) {
        pathElement.value = question_path_result.destination_questionnaire_question;
      }
    });
  };

  const getQuestionType = (question_type) => {
    if (question_type === 'boolean') {
      return 'Yes or No';
    } else if (question_type === 'text') {
      return 'Text';
    } else if (question_type === 'checkbox') {
      return 'Checkbox';
    }
  };

  const hasEditPrivileges = (questionnaire) => {
    if (!loggedUser.superuser) {
      if (!questionnaire.company) {
        return false;
      }
    }
    return true;
  };

  const customDeleteQuestionnaireQuestion = async (questionnaireQuestionId) => {
    await deleteQuestionnaireQuestion(questionnaireQuestionId);
    await updateQuestionsOrder(questionnaire.id);
    window.location.reload();
  };

  const getEditBtn = (question) => {
    if (!hasEditPrivileges(questionnaire)) return;
    return (
      <button className='btn btn-ibis btn-circle' data-toggle='modal' data-target='#editQuestionPathModal' onClick={() => editQuestionPath(question)}>
        <i className='fas fa-random' />
      </button>
    );
  };

  const getDeleteBtn = (question) => {
    if (!hasEditPrivileges(questionnaire)) return;
    if (!question.initial) {
      return (
        <button className='btn btn-danger btn-circle' onClick={() => customDeleteQuestionnaireQuestion(question.id)}>
          <i className='fas fa-trash' />
        </button>
      );
    } else return 'Initial';
  };

  const questionnaireQuestionColumns = [
    { name: 'ID', selector: (question) => question.id, sortable: true, filter_field: 'id' },
    { name: 'Text', selector: (question) => question.question_text },
    { name: 'Type', selector: (question) => getQuestionType(question.question_type) },
    { name: 'Question Path', selector: (question) => getEditBtn(question), button: true, minWidth: '150px' },
    { name: 'Delete', selector: (question) => getDeleteBtn(question), button: true },
  ];

  return <IbisDataTable title='Selected Questions' data={questionnaireQuestions} columns={questionnaireQuestionColumns} />;
};

export default QuestionnaireQuestionList;
