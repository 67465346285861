import { useEffect, useState } from 'react';
import IbisDataTable from '../components/DataTable/IbisDataTable';
import TestResultsModal from '../components/Modals/TestResultsModal';
import { useModal } from '../hooks';
import { fetchObjects, detailObject } from '../api/crudFunctions';
import parseDateAndSort from '../components/DataTable/parseDateAndSort';

const TestResults = () => {
  const loggedUser = JSON.parse(localStorage.getItem('loggedUser'));
  const [data, setData] = useState(null);
  const [selectedTest, setSelectedTest] = useState(null);
  const { modalIsOpen, toggle } = useModal();
  const [showAsyncResults, setShowAsyncResults] = useState(false);
  const [resultsForDisplay, setResultsForDisplay] = useState(null);

  useEffect(() => {
    const getTestResults = async () => {
      const { results } = await fetchObjects('/api/appointment-test-result');
      setData(results);
    };

    getTestResults();
  }, []);

  useEffect(() => {
    if (data && data.length) {
      if (showAsyncResults) {
        setResultsForDisplay(data);
      } else {
        setResultsForDisplay(
          data.filter((result) => {
            return result.async_test === false;
          })
        );
      }
    }
  }, [data, showAsyncResults]);

  const deleteTestResult = async (testResult) => {
    if (window.confirm('Are you sure you want to delete this test result? This action cannot be undone.') === true) {
      const response = await detailObject(`/api/appointment-test-result/${testResult}`, 'DELETE');
      if (response.ok) {
        const { results } = await fetchObjects('/api/appointment-test-result/');
        setData(results);
      } else {
        document.getElementById('general_error').classList.add('show');
        document.getElementById('general_error_message').innerHTML = 'There was a problem deleting these test results.';
      }
    }
  };

  const getDeleteButton = (testResult) => {
    return (
      <div>
        <button type='button' id={`delete-btn-${testResult}`} className='btn btn-danger btn-circle' onClick={() => deleteTestResult(testResult)}>
          <i className='fas fa-trash'></i>
        </button>
      </div>
    );
  };

  const getColumns = () => {
    return [
      {
        name: 'Submission Date',
        selector: (testResult) => testResult.submission_date,
        grow: 1.5,
        sortable: true,
        sortFunction: (appt1, appt2) => parseDateAndSort(appt1.submission_date_timestamp, appt2.submission_date_timestamp),
        filter_field: 'submission_date',
      },
      {
        name: 'Appointment Date',
        selector: (testResult) => testResult.appointment_date,
        grow: 1.5,
        sortable: true,
        sortFunction: (appt1, appt2) => parseDateAndSort(appt1.appointment_date_timestamp, appt2.appointment_date_timestamp),
        filter_field: 'appointment_date',
      },
      {
        name: 'Type of Test',
        selector: (testResult) => testResult.test_title,
        sortable: true,
        filter_field: 'test_title',
      },
      {
        name: 'Clinician',
        selector: (testResult) => testResult.optician,
        sortable: true,
        omit: loggedUser.role === 'optician',
        filter_field: 'optician',
      },
      {
        name: 'Patient',
        selector: (testResult) => testResult.patient,
        grow: 1.25,
        sortable: true,
        omit: loggedUser.role === 'patient',
        filter_field: 'patient',
      },
      { name: 'Delete', cell: (testResult) => getDeleteButton(testResult.id), omit: !loggedUser?.superuser, button: true },
    ];
  };

  const showTestResults = (clickedTest) => {
    setSelectedTest(clickedTest);
    if (clickedTest.async_test === false) {
      return toggle();
    }
  };

  useEffect(() => {
    if (selectedTest?.async_test === true) {
      window.location.href = `${process.env.PUBLIC_URL}/pre-screening/${selectedTest.appointment}`;
    }
  }, [selectedTest]);

  return (
    <>
      <TestResultsModal modalIsOpen={modalIsOpen} toggle={toggle} selectedTest={selectedTest} />
      <div className='container-fluid'>
        <div className='custom-control custom-switch'>
          <input
            type='checkbox'
            className='custom-control-input'
            id='showAsyncResultsSwitch'
            checked={showAsyncResults}
            onChange={() => setShowAsyncResults(!showAsyncResults)}
          />
          <label className='custom-control-label' style={{ color: 'black' }} htmlFor='showAsyncResultsSwitch'>
            <h4 className='m-0'>Include Async Test Results</h4>
          </label>
        </div>
        <IbisDataTable
          title='Test Results'
          data={resultsForDisplay}
          columns={getColumns()}
          rowClickFunction={(clickedTest) => showTestResults(clickedTest)}
          defaultSortFieldId={1}
          defaultSortAsc={false}
        />
      </div>
    </>
  );
};

export default TestResults;
