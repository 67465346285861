import IbisModal from './IbisModal';
import EditQuestionnaireForm from '../Questionnaire/EditQuestionnaireForm';
import QuestionnaireForm from '../Questionnaire/QuestionnaireForm';

const QuestionnaireModal = ({ modalIsOpen, toggle, questionnaireId }) => {
  return (
    <IbisModal
      title={`${!questionnaireId ? 'Add' : 'Edit'} Questionnaire`}
      modalIsOpen={modalIsOpen}
      toggle={toggle}
      className='Modal__Bootstrap modal-dialog'
    >
      {!questionnaireId ? <QuestionnaireForm toggle={toggle} /> : <EditQuestionnaireForm toggle={toggle} questionnaireId={questionnaireId} />}
    </IbisModal>
  );
};

export default QuestionnaireModal;
