export const SESSION_STATUSES = {
  OPEN: 'open',
  PRESCRIPTION: 'prescription',
  COMPLETE: 'complete',
  CANCELLED: 'cancelled',
  INVALID: 'invalid',
  INCOMPLETE: 'incomplete',
  REVIEW: 'review',
  CONTACT: 'contact',
  CREATED: 'created',
  CLOSED: 'closed',
};

//TODO: GET THE VALUE OF THE STATUS FROM THE API
export const STATUS_LABELS = {
  complete: SESSION_STATUSES.COMPLETE,
  open: SESSION_STATUSES.OPEN,
  prescription: 'awaiting prescription',
  cancelled: SESSION_STATUSES.CANCELLED,
  invalid: SESSION_STATUSES.INVALID,
  book: 'Book Appointment',
  shop: 'Shop Glasses',
  retake: 'Re-take Test',
  incomplete: 'missing information',
  review: 'awaiting review',
  contact: 'contact patient',
  created: 'created',
  closed: SESSION_STATUSES.CLOSED,
};

export const SESSION_ACTIONS = [
  { label: 'show details', action: { type: 'navigate', payload: 'sessions' }, icon: 'fa-info-circle' },
  { label: 'send booking email', action: { type: 'email', payload: 'book' }, icon: 'fa-calendar-alt' },
  { label: 'send shop email', action: { type: 'email', payload: 'shop' }, icon: 'fa-shopping-cart' },
  { label: 'send prescription email', action: { type: 'email', payload: 'prescription' }, icon: 'fa-comment-medical' },
  { label: 'send re-take test email', action: { type: 'email', payload: 'retake' }, icon: 'fa-redo' },
  // { label: 'mark as open', action: { type: 'status', payload: SESSION_STATUSES.OPEN }, icon: 'fa-check' },
  { label: 'mark as awaiting review', action: { type: 'status', payload: SESSION_STATUSES.REVIEW }, icon: 'fa-hourglass-half' },
  // { label: 'mark as awaiting prescription', action: { type: 'status', payload: SESSION_STATUSES.PRESCRIPTION }, icon: 'fa-hourglass-half' },
  { label: 'mark as complete', action: { type: 'status', payload: SESSION_STATUSES.COMPLETE }, icon: 'fa-calendar-check' },
  // { label: 'mark as cancelled', action: { type: 'status', payload: SESSION_STATUSES.CANCELLED }, icon: 'fa-times' },
  // { label: 'mark as invalid', action: { type: 'status', payload: SESSION_STATUSES.INVALID }, icon: 'fa-trash' },
  { label: 'mark as missing information', action: { type: 'status', payload: SESSION_STATUSES.INCOMPLETE }, icon: 'fa-info' },
  { label: 'mark as contact patient', action: { type: 'status', payload: SESSION_STATUSES.CONTACT }, icon: 'fa-user' },
  { label: 'mark as closed', action: { type: 'status', payload: SESSION_STATUSES.CLOSED }, icon: 'fa-calendar-times' },
  { label: 'check activity', action: { type: 'navigate', payload: 'activity' }, icon: 'fa-list-ul' },
];

export const formatActivityLogTimestamp = (timestamp) => {
  const [date, time] = timestamp.split(' ');
  return `${date} at ${time}`;
};

export const formatActivityLogDescription = (type, value) => {
  const titleCase = (str) => str.charAt(0).toUpperCase() + str.slice(1);

  const action = titleCase(STATUS_LABELS[value]);
  switch (type) {
    case 'status':
      return `Status changed to ${action}`;
    case 'email':
      return `${action} email sent`;
    default:
      return 'Unknown activity';
  }
};

export const formatResult = (test, passed) => {
  return passed ? `${test} that is consistent with normal vision.` : `${test} that is not consistent with normal vision.`;
};
