import UserForm from './UserForm';
import EditUserForm from './EditUserForm';

import AdministratorsList from './AdministratorsList';
import { useUser } from '../../context/userContext';
import { IbisAdministrator } from '../../helper/IbisUser';

const AdministratorContent = () => {
  const { addUser, saveUser } = useUser();

  return (
    <>
      <div className='container-fluid'>
        <div className='d-sm-flex align-items-center justify-content-end'>
          <button
            className='d-none d-sm-inline-block btn btn-sm btn-ibis shadow-sm'
            type='button'
            data-toggle='modal'
            data-target='#addAdministratorModal'
          >
            <i className='fas fa-plus fa-sm text-white-50'></i>&nbsp;Add
          </button>
        </div>
        <div className='row'>
          <div className='col-xl-12 col-md-6 mb-4'>
            <AdministratorsList />
          </div>
        </div>
      </div>
      <UserForm userType='Administrator' addUser={addUser} ibisUserType={new IbisAdministrator()} />
      <EditUserForm userType='Administrator' saveUser={saveUser} ibisUserType={new IbisAdministrator()} />
    </>
  );
};

export default AdministratorContent;
