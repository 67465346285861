import './ResponsiveLoadingPage.css';

const ResponsiveLoadingPage = () => {
  return (
    <div style={{ width: '80vw', height: '80vh' }} className='d-flex flex-column justify-content-center align-items-center'>
      <div style={{ position: 'relative' }} className='d-flex flex-column align-items-center'>
        <img
          src='/img/rebrand-logo.svg'
          style={{ marginRight: '1rem', zIndex: '1', width: '50vw', height: '50vh', maxWidth: '150px', maxHeight: '150px' }}
          alt='loading...'
        />
        <div className='shine-overlay' />
      </div>
    </div>
  );
};

export default ResponsiveLoadingPage;
