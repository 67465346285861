import { useEffect, useState } from 'react';
import { useUser } from '../../context/userContext';
import { IbisPatient } from '../../helper/IbisUser';
import IbisDataTable from '../DataTable/IbisDataTable';

const PatientsList = ({ showInactivePatients }) => {
  const [patientsToDisplay, setPatientsToDisplay] = useState(null);
  const { patients, deleteUser, editUser } = useUser();
  const loggedUser = JSON.parse(localStorage.getItem('loggedUser'));

  useEffect(() => {
    if (!patients) return;
    if (showInactivePatients) {
      setPatientsToDisplay(patients);
    } else {
      setPatientsToDisplay(
        patients.filter((patient) => {
          return patient.is_active;
        })
      );
    }
  }, [patients, showInactivePatients]);

  const getEditButton = (patient) => {
    return patient.is_active ? (
      <button
        className='btn btn-ibis btn-circle'
        data-toggle='modal'
        data-target='#editPatientModal'
        onClick={() => editUser(new IbisPatient(), patient.id)}
      >
        <i className='fas fa-edit'></i>
      </button>
    ) : (
      <button className='btn btn-ibis btn-circle' disabled style={{ cursor: 'not-allowed' }}>
        <i className='fas fa-ban'></i>
      </button>
    );
  };

  const getDeleteButton = (patient) => {
    return patient.is_active ? (
      <button className='btn btn-danger btn-circle' onClick={() => deleteUser(new IbisPatient(), patient.id)}>
        <i className='fas fa-trash'></i>
      </button>
    ) : (
      <button className='btn btn-danger btn-circle' disabled style={{ cursor: 'not-allowed' }}>
        <i className='fas fa-ban'></i>
      </button>
    );
  };

  const getColumns = () => {
    return [
      {
        name: 'First Name',
        selector: (patient) => patient.first_name,
        sortable: true,
        filter_field: 'first_name',
      },
      {
        name: 'Last Name',
        selector: (patient) => patient.last_name,
        sortable: true,
        filter_field: 'last_name',
      },
      {
        name: 'Email',
        selector: (patient) => patient.email,
        sortable: true,
        filter_field: 'email',
      },
      {
        name: 'Username',
        selector: (patient) => patient.username,
        sortable: true,
        filter_field: 'username',
      },
      {
        name: 'Company',
        selector: (patient) => patient.company,
        sortable: true,
        filter_field: 'company',
        omit: !loggedUser?.superuser,
      },
      {
        name: 'Status',
        selector: (patient) => (patient.is_active ? 'Active' : 'Inactive'),
        sortable: true,
        omit: !showInactivePatients,
        center: true,
      },
      {
        name: 'Edit',
        selector: (patient) => getEditButton(patient),
        button: true,
        center: true,
      },
      {
        name: 'Remove',
        selector: (patient) => getDeleteButton(patient),
        button: true,
        center: true,
      },
    ];
  };

  return <IbisDataTable title='Patients' data={patientsToDisplay} columns={getColumns()} conditionalRowStyles={inactivePatientStyles} />;
};

export default PatientsList;

const inactivePatientStyles = [{ when: (row) => !row.is_active, style: { backgroundColor: 'rgba(255, 163, 164, 0.6)' } }];
