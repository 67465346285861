const ResetCategoryButton = ({ category, updatePrescription, setActiveCategory }) => {
  const handleReset = () => {
    updatePrescription({ eye: 'right', [category]: '00.00' });
    updatePrescription({ eye: 'left', [category]: '00.00' });
    return setActiveCategory(null);
  };

  return (
    <button
      className='position-absolute btn btn-danger font-weight-bold px-3 py-0'
      style={{ bottom: 1, left: '50%', transform: 'translateX(-50%)', fontSize: '0.625rem' }}
      onClick={() => {
        handleReset();
      }}
    >
      <i className='fa fa-times' aria-hidden='true'></i>
    </button>
  );
};

export default ResetCategoryButton;
