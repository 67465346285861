export const PatientCopy = () => {
  return (
    <>
      <p>{`Your eye test appointment is being carried out by IbisVision using our IbisEngage software.`}</p>
      <p>
        {` In order to carry out your eye tests, we need to collect & store some of your information as detailed in our Privacy Policy below. Please read the document and confirm your consent by ticking the box below then clicking "Confirm".`}
      </p>
      <p>{`Please also review our terms in the End User Licence Agreement (EULA) and confirm you agree to these.`}</p>
      <p>{`Alternatively, click "Cancel" to be logged out and close the session. Please note that we are unable to carry out your eye test without your consent.`}</p>
    </>
  );
};

export const ClinicianCopy = () => {
  return (
    <>
      <p>{`Welcome to IbisEngage.`}</p>
      <p>{`Before accessing our system for the first time, please confirm that you accept our End User Licence Agreement (EULA) and our Privacy Policy.`}</p>
      <p>{`Alternatively, click "Cancel" to be logged out and close the session. You will not be able to use the system.`}</p>
    </>
  );
};

export const PatientRefusalCopy = () => {
  return (
    <>
      <p>{`You have refused consent and therefore we are unable to proceed with your appointment.`}</p>
      <p>{`Your email address will now be removed from the platform.`}</p>
      <p>{`Please contact your clinician if you have refused in error and would like to schedule another appointment.`}</p>
    </>
  );
};

export const ClinicianRefusalCopy = () => {
  return (
    <>
      <p>{`You have refused consent and therefore we are unable to grant access to the platform.`}</p>
      <p>{`Please return to the login page and try again if you have arrived here in error.`}</p>
    </>
  );
};
