import { useCallback } from 'react';
import DioptreSelect from './DioptreSelect';
import PrismSelect from './PrismSelect';
import ResetCategoryButton from './ResetCategoryButton';

const PrescriptionEntryTable = ({ prescriptionData, updatePrescriptionData, activeCategory, setActiveCategory, userCanEdit }) => {
  const categories = ['Sphere', 'Cylinder', 'Axis', 'Horizontal Prism', 'Vertical Prism', 'Add', 'Distance PD', 'Near PD'];

  const updatePrescription = useCallback(
    (newData) => {
      const { eye, ...payload } = newData;
      if (payload.add) return updatePrescriptionData({ type: 'UPDATE_ADD_VALUE', payload: payload });
      return updatePrescriptionData({ type: `UPDATE_${eye.toUpperCase()}_EYE_PRESCRIPTION`, payload: payload });
    },
    [updatePrescriptionData]
  );

  const renderCell = (eye, catId) => {
    const readOnly = !userCanEdit;

    if (catId.includes('axis') || catId.includes('pd'))
      return (
        <input
          id={`${eye}-${catId}`}
          value={prescriptionData[eye][catId]}
          onChange={(e) => updatePrescription({ eye, [catId]: e.target.value })}
          style={{ width: '100%', height: '100%', border: 0, padding: '0 1rem', textAlign: 'center' }}
          {...(readOnly && { tabIndex: -1 })}
        />
      );

    if (catId.includes('prism'))
      return <PrismSelect eye={eye} category={catId} value={prescriptionData[eye][catId]} setValue={updatePrescription} readOnly={readOnly} />;

    return (
      <DioptreSelect
        eye={eye}
        category={catId}
        externalState={prescriptionData[eye][catId]}
        setValue={updatePrescription}
        readOnly={readOnly}
        setActiveCategory={setActiveCategory}
      />
    );
  };

  return (
    <table className='table table-bordered'>
      <thead>
        <tr>
          <th scope='col'>{/* intentionally blank - corner cell*/}</th>
          {categories.map((heading) => {
            return (
              <th
                key={`heading-${heading}`}
                scope='col'
                className='px-1 py-3 position-relative'
                style={{ textAlign: 'center', whiteSpace: 'nowrap', minWidth: heading.includes('Prism') ? '160px' : '' }}
              >
                <div className='d-flex flex-column'>
                  <p className='m-0'>{heading}</p>
                  {activeCategory === heading.toLowerCase() && (
                    <ResetCategoryButton
                      category={heading.toLowerCase()}
                      updatePrescription={updatePrescription}
                      setActiveCategory={setActiveCategory}
                    />
                  )}
                </div>
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {['right', 'left'].map((eye) => {
          return (
            <tr key={`${eye}-eye-row`}>
              <th scope='row' style={{ textAlign: 'right', whiteSpace: 'nowrap' }}>
                {`${eye.toUpperCase()} EYE`}
              </th>
              {categories.map((cat) => {
                const catId = cat.replace(/\s+/, '-').toLowerCase();
                return (
                  <td key={`${eye}-${catId}`} className='p-0' style={{ height: '40px', pointerEvents: !userCanEdit ? 'none' : 'all' }}>
                    {renderCell(eye, catId)}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default PrescriptionEntryTable;
