import { useState } from 'react';
import { useQuestionnaire } from '../../context/questionnaireContext';
import LoadingSpinner from '../Misc/LoadingSpinner';
import CheckboxQuestionForm from './CheckboxQuestionForm';

const QuestionForm = () => {
  const { addQuestion } = useQuestionnaire();
  const [text, setText] = useState('');
  const [questionType, setQuestionType] = useState('');
  const [questionCreationPending, setQuestionCreationPending] = useState(false);
  const [checkboxOptions, setCheckboxOptions] = useState([
    { position: 1, text: '' },
    { position: 2, text: '' },
  ]);

  const questionObject = {
    text: text,
    question_type: questionType,
    ...(questionType === 'checkbox' && { options: checkboxOptions }),
  };

  const validateTextInputs = () => {
    const questionTextExists = text.trim().length;
    if (!questionTextExists) return alert('Please enter the question text.');
    const optionTextExists = checkboxOptions.every((opt) => opt.text.trim().length);
    if (!optionTextExists) return alert('One or more options have been left blank. Please fill them in or delete them.');
    return true;
  };

  const createQuestion = () => {
    if (questionType !== 'checkbox' || validateTextInputs()) {
      const delay = setInterval(() => setQuestionCreationPending(true), 300);
      addQuestion(questionObject).then((response) => {
        clearInterval(delay);
        setQuestionCreationPending(false);
        if (typeof response == 'object') {
          document.querySelector('#addQuestionModal .close').click();
        }
      });
    }
  };

  return (
    <>
      {questionCreationPending ? <LoadingSpinner message={'Adding question...'} /> : null}
      <div className='modal fade' id={'addQuestionModal'} tabIndex='-1' role='dialog' aria-labelledby={'addQuestionModalLabel'} aria-hidden='true'>
        <div className='modal-dialog' role='document'>
          <div className='modal-content'>
            <div className='modal-header bg-primary-ibis'>
              <h5 className='modal-title' id={'addQuestionModalLabel'}>
                Add Question
              </h5>
              <button className='close' type='button' data-dismiss='modal' aria-label='Close'>
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <div className='modal-body'>
              <form className='' name='user-form'>
                <div className='form-group row'>
                  <div className='col mb-3 mb-sm-0'>
                    <input
                      type='text'
                      className='form-control form-control-question text'
                      id='field_text'
                      placeholder='Question text'
                      value={text}
                      onChange={(e) => setText(e.target.value)}
                    />
                    <div id='validationfield_text' className='invalid-feedback'>
                      <span id='invalid_text_message'></span>
                    </div>
                  </div>
                </div>
                <div className='form-group row'>
                  <div className='col mb-3 mb-sm-0'>
                    <select
                      className='form-control form-control-question question_type form-select'
                      aria-label='Select a question type'
                      id='field_question_type'
                      onChange={(e) => setQuestionType(e.target.value)}
                      value={questionType}
                    >
                      <option value='' hidden>
                        Select a question type
                      </option>
                      <option value='text'>Text</option>
                      <option value='boolean'>Yes or No</option>
                      <option value='checkbox'>Checkbox</option>
                    </select>
                    <div id='validationfield_question_type' className='invalid-feedback'>
                      <span id='invalid_question_type_message'></span>
                    </div>
                  </div>
                </div>
                {questionType === 'checkbox' && <CheckboxQuestionForm options={checkboxOptions} setOptions={setCheckboxOptions} />}
              </form>
            </div>
            <div className='modal-footer'>
              <button className='btn btn-secondary' type='button' data-dismiss='modal'>
                Cancel
              </button>
              <button
                className='btn btn-ibis'
                type='button'
                onClick={() => {
                  createQuestion();
                }}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuestionForm;
