import { useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom';

const Snackbar = ({ showSnackbar, snackbarState, ...props }) => {
  const { message, show, variant } = snackbarState;

  const snackbar = useMemo(
    () => (
      <div id={`snackbar-${message}`} className={`snackbar ${variant}`} {...props}>
        {message}
      </div>
    ),
    [message, variant, props]
  );

  useEffect(() => {
    const snackbarClasses = document.getElementById(`snackbar-${message}`).classList;
    if (show) {
      snackbarClasses.add('show');
      setTimeout(() => {
        snackbarClasses.remove('show');
        showSnackbar(false);
      }, 3000);
    }
  }, [show, message, showSnackbar]);

  return ReactDOM.createPortal(snackbar, document.getElementById('wrapper'));
};

export default Snackbar;
