import AdministratorContent from './AdministratorContent';
import { useEffect } from 'react';
import { useUser } from '../../context/userContext';
import { IbisAdministrator } from '../../helper/IbisUser';
import { useCompany } from '../../context/companyContext';

const Administrator = () => {
  const { getUsers } = useUser();
  const { getCompanies } = useCompany();
  const userType = new IbisAdministrator();

  useEffect(() => {
    getUsers(userType);
    getCompanies();
  }, []);

  return <AdministratorContent />;
};

export default Administrator;
