import { useEffect, useState } from 'react';
import WaitingRoomPrescriptionTable from './WaitingRoomPrescriptionTable';
import WaitingRoomIshiharaResults from './WaitingRoomIshiharaResults';
import WaitingRoomQuestionnairesResults from './WaitingRoomQuestionnairesResults';

const PreScreeningDetails = ({ categories, details }) => {
  const [selectedView, setSelectedView] = useState('');

  const categoryMap = {
    prescription: <WaitingRoomPrescriptionTable prescriptionFromServer={details.prescreening.prescription} />,
    ishihara: <WaitingRoomIshiharaResults apptDetails={details} />,
    questionnaires: <WaitingRoomQuestionnairesResults apptDetails={details} />,
  };

  useEffect(() => {
    setSelectedView(categories[0]);
  }, [categories]);

  return (
    <div className='tab-pane fade' id='prescreening' role='tabpanel' aria-labelledby='prescreening-tab'>
      <div className='row justify-content-around align-items-center'>
        <label className='m-0' htmlFor='select-view'>
          Category
        </label>
        <select
          className='custom-select w-75'
          id='select-view'
          disabled={categories.length < 2}
          value={selectedView}
          onChange={(e) => setSelectedView(e.target.value)}
        >
          {categories.map((category) => {
            return (
              <option key={`opt-${category}`} value={category}>
                {category.charAt(0).toUpperCase() + category.slice(1)}
              </option>
            );
          })}
        </select>
      </div>
      <div className='mt-4 d-flex justify-content-center'>{selectedView?.length ? categoryMap[selectedView] : null}</div>
    </div>
  );
};

export default PreScreeningDetails;
