import { useState, useRef, useEffect } from 'react';
import LoginForm from '../User/LoginForm';
import ForgotPassword from './ForgotPassword';
import { getTitle } from '../../helper/general';

const Login = () => {
  const [forgotPassword, setForgotPassword] = useState(false);
  const [smallScreen, setSmallScreen] = useState(false);
  const initialRender = useRef(true);

  const handleForgotPassword = () => {
    setForgotPassword(true);
  };

  const handleBackToLogin = () => {
    setForgotPassword(false);
  };

  useEffect(() => {
    if (initialRender) {
      initialRender.current = false;
    }
  }, []);

  useEffect(() => {
    const checkMatch = () => {
      return deviceWidthQuery.matches ? setSmallScreen(true) : setSmallScreen(false);
    };
    const deviceWidthQuery = window.matchMedia('(max-width: 450px)');
    checkMatch();

    deviceWidthQuery.addEventListener('change', checkMatch);
    return () => deviceWidthQuery.removeEventListener('change', checkMatch);
  }, []);

  return (
    <div className='container'>
      <div className='row justify-content-center'>
        <div className='col-xl-10 col-lg-12 col-md-9'>
          <div className='card o-hidden border-0 shadow-lg my-5'>
            <div className='card-body p-5'>
              <div className='row'>
                <div className='col-lg-6 d-none d-lg-block bg-login-image' role='img' />
                <div className='col-lg-6 mb-4 d-flex justify-content-center'>
                  <div className={smallScreen ? 'p-1' : 'p-5'} style={{ height: '24.5rem' }}>
                    <div className='text-center'>
                      <h1 className={`mb-4 text-ibis ${smallScreen ? 'h5' : 'h4'}`}>{getTitle()}</h1>
                    </div>
                    {!forgotPassword ? (
                      <LoginForm transitionComplete={handleForgotPassword} initialRender={initialRender.current} />
                    ) : (
                      <ForgotPassword transitionComplete={handleBackToLogin} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
