import { useEffect, useState } from 'react';

const PrintMatrix = ({ size: lineLength, rawDotDataSet, testProgressData, localOutOfBoundariesDots, setLocalOutOfBoundariesDots }) => {
  const [allDots, setAllDots] = useState([]);
  //for stroke suite - OOB dots are not saved as part of results.
  const { notSeenDots, missedTwiceDots, outOfBoundariesDots, orientationCorrect, orientationIncorrect } = testProgressData || {};

  useEffect(() => {
    const getActualPointOnGraph = (degreeX, degreeY) => {
      const getMaxValue = () => {
        const allCoords = rawDotDataSet.flatMap((coordObj) => {
          return Object.values(coordObj).filter((coord) => {
            return Math.abs(coord);
          });
        });
        return Math.max(...allCoords);
      };
      const maximumDegree = getMaxValue();
      const halfLineLength = lineLength / 2;
      const sections = halfLineLength / maximumDegree;
      let x = Math.round(Math.abs((maximumDegree + degreeX) * sections));
      let y = Math.round(Math.abs((maximumDegree + degreeY) * sections));

      [x, y] = [x, y].map((coord) => {
        //add padding to extreme coordinates
        if (coord === 0) return (coord += lineLength * 0.01);
        if (coord === lineLength) return (coord -= lineLength * 0.01);
        return coord;
      });
      return { x, y };
    };

    const renderCoords = () => {
      const withoutDisabledBlindSpot = rawDotDataSet.filter((dot) => !dot.blindspot || (dot.blindspot && !dot.disabled));
      const parsedDots = withoutDisabledBlindSpot?.map((coordSet) => {
        const newMatrixId = `coord${coordSet.degreeX}${coordSet.degreeY}`;
        const invertedY = coordSet.degreeY * -1;
        //invert because CSS Y positions & actual graph Y positions are opposite
        const { x, y } = getActualPointOnGraph(coordSet.degreeX, invertedY);
        if (coordSet.outOfBoundaries) {
          //for stroke suite - OOB dots are not saved as part of results.
          setLocalOutOfBoundariesDots((existingDots) => {
            return [...existingDots, coordSet];
          });
        }
        return { id: newMatrixId, left: x, top: y };
      });
      return parsedDots;
    };
    setAllDots(renderCoords());
  }, [rawDotDataSet, lineLength, setLocalOutOfBoundariesDots]);

  const parseDotForPrinting = (dot) => {
    const dotWasMissed = notSeenDots?.some((missedDot) => missedDot.id === dot.id) || missedTwiceDots?.some((missedDot) => missedDot.id === dot.id);
    const dotIsBlindspot = dot.id === 'coord15-1.5' || dot.id === 'coord-15-1.5';
    const dotOrientationCorrect = orientationCorrect?.some((seenDot) => seenDot.id === dot.id);
    const dotOrientationIncorrect = orientationIncorrect?.some((seenDot) => seenDot.id === dot.id);
    const dotIsOutOfBounds =
      outOfBoundariesDots?.some((outOfBoundsDot) => outOfBoundsDot.id === dot.id) ||
      localOutOfBoundariesDots?.some((outOfBoundsDot) => outOfBoundsDot.id === dot.id);

    const dotShouldNotHaveBg = dotIsBlindspot || dotIsOutOfBounds || dotWasMissed || dotOrientationIncorrect;
    const dotShouldHaveBorder = dotWasMissed || dotOrientationIncorrect;
    const dotIsSquare = dotIsOutOfBounds || dotOrientationCorrect || dotOrientationIncorrect;

    return (
      <div
        key={dot.id}
        style={{
          position: 'absolute',
          top: dot.top,
          left: dot.left,
          transform: 'translate(-50%, -50%)',
          height: `${lineLength * 0.03}px`,
          width: `${lineLength * 0.03}px`,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: dotIsSquare ? null : '50%',
          border: `1px solid ${dotShouldHaveBorder ? '#000' : 'transparent'}`,
          backgroundColor: dotShouldNotHaveBg ? '#fff' : '#000',
          color: dotIsBlindspot ? '#000' : 'lightgrey',
          fontSize: dotIsBlindspot ? '1.5rem' : '1rem',
        }}
      >
        {dotIsBlindspot ? '\u22c7' : dotIsOutOfBounds ? '\u2a09' : null}
      </div>
    );
  };

  return (
    <div
      className='d-flex align-items-center justify-content-center'
      style={{
        height: `${lineLength * 1.05}px`,
        width: `${lineLength * 1.05}px`,
        //this is a container so slightly bigger than the actual matrix
        border: '3px solid grey',
        borderRadius: '12px',
      }}
    >
      <div
        className='d-flex align-items-center justify-content-center'
        style={{ height: `${lineLength}px`, width: `${lineLength}px`, position: 'relative' }}
      >
        <GraphLine horizontal />
        <GraphLine vertical />
        {allDots?.map(parseDotForPrinting)}
      </div>
    </div>
  );
};

export default PrintMatrix;

const GraphLine = ({ horizontal }) => {
  return (
    <div
      style={{
        width: horizontal ? '100%' : '1px',
        backgroundColor: '#000',
        height: horizontal ? '1px' : '100%',
        position: 'absolute',
        left: horizontal ? 0 : '50%',
        top: horizontal ? '50%' : 0,
        transform: horizontal ? 'translateY(-50%)' : 'translateX(-50%)',
      }}
    ></div>
  );
};
