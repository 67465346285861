import { useEffect, useLayoutEffect, useState } from 'react';
import { getCurrentAppointment } from '../../api/appointmentFunctions';

const PatientNextApptCard = () => {
  const [loading, setLoading] = useState(true);
  const [currentAppointment, setCurrentAppointment] = useState(null);
  const [showPreScreening, setShowPreScreening] = useState(false);
  const loggedUser = JSON.parse(localStorage.getItem('loggedUser'));

  useLayoutEffect(() => {
    getCurrentAppointment().then((res) => {
      setLoading(false);
      if (res.id) return setCurrentAppointment(res);
    });
  }, []);

  useEffect(() => {
    const { questionnaire_id, prescreening } = currentAppointment || {};
    if (prescreening) {
      const shouldPreScreen =
        questionnaire_id ||
        Boolean(prescreening?.questionnaires?.length) ||
        Boolean(prescreening?.tests?.length) ||
        Boolean(Object.keys(prescreening?.prescription).length);

      setShowPreScreening(shouldPreScreen);
    }
  }, [currentAppointment]);

  return (
    <div className='row w-100 d-flex justify-content-center mx-0 mb-5'>
      <div className='col-6 col-md-6'>
        <div className='card shadow'>
          <div className='card-header bg-primary-ibis text-white bg-ibis text-center'>
            <h4 className='m-0'>Your Next Appointment</h4>
          </div>
          <div className='card-body'>
            {currentAppointment ? (
              <>
                <div className='row border mb-2 p-2'>
                  <div className='col-3'>
                    <strong>Starts:</strong>
                  </div>
                  <div className='col'>{currentAppointment.start}</div>
                </div>
                <div className='row border mb-2 p-2'>
                  <div className='col-3'>
                    <strong>Clinician:</strong>
                  </div>
                  <div className='col'>{currentAppointment.optician_full_name}</div>
                </div>
                {showPreScreening && (
                  <div className='row'>
                    <div className='col text-center'>
                      <a href={`/pre-screening/${currentAppointment.id}`} className='btn btn-primary mt-3'>
                        <i className='fas fa-list mr-2'></i>
                        <span className='text'>Complete pre-screening</span>
                      </a>
                      <p className='mt-3'>Please make sure you have completed pre-screening before you start the session. </p>
                    </div>
                  </div>
                )}
                <div className='row'>
                  <div className='col text-center'>
                    <a
                      href={`/health/?userID=${loggedUser.id}&userName=${loggedUser.username}&userEmail=${loggedUser.email}&roomName=${currentAppointment.room_name}`}
                      className='btn btn-ibis btn-lg btn-block mt-2'
                      target='_blank'
                      rel='noreferrer'
                    >
                      <i className='fas fa-sign-in-alt mr-3'></i>
                      <span className='text'>Start Appointment</span>
                    </a>
                  </div>
                </div>
              </>
            ) : (
              <div className='d-flex align-items-center justify-content-center w-100' style={{ height: showPreScreening ? '290px' : '176px' }}>
                {loading ? <div className='spinner-border text-success'></div> : 'You have no upcoming appointments.'}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientNextApptCard;
