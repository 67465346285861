import { useReducer } from 'react';

export const dioptreRange = [...Array.from(Array(9)).keys()].map((num) => '0' + num);

export const prescriptionReducer = (existingState, action) => {
  const { left, right } = existingState;
  switch (action.type) {
    case 'UPDATE_LEFT_EYE_PRESCRIPTION':
      return { ...existingState, left: { ...left, ...action.payload } };
    case 'UPDATE_RIGHT_EYE_PRESCRIPTION':
      return { ...existingState, right: { ...right, ...action.payload } };
    case 'UPDATE_ADD_VALUE':
      return { ...existingState, right: { ...right, ...action.payload }, left: { ...left, ...action.payload } };
    case 'SET_ALL_VALUES':
      return { ...existingState, ...action.payload };
    default:
      return existingState;
  }
};

const initialPrescriptionState = {
  //probably replace this with API in future?
  left: { sphere: '00.00', cylinder: '00.00', axis: '', 'horizontal-prism': '', 'vertical-prism': '', add: '00.00', 'dist-pd': '', 'near-pd': '' },
  right: {
    sphere: '00.00',
    cylinder: '00.00',
    axis: '',
    'horizontal-prism': '',
    'vertical-prism': '',
    add: '+00.00',
    'dist-pd': '',
    'near-pd': '',
  },
};

export const usePrescription = () => {
  const [prescriptionData, updatePrescriptionData] = useReducer(prescriptionReducer, initialPrescriptionState);
  return { prescriptionData, updatePrescriptionData };
};

export const validatePrescriptionInput = (input) => {
  const { right, left } = input;
  let errorMsg;

  [right, left].forEach((eye, idx) => {
    for (const field in eye) {
      if ((field === 'sphere' || field === 'cylinder') && eye[field] !== '00.00') {
        const category = field.charAt(0).toUpperCase() + field.slice(1);
        const value = eye[field];
        const powerWithNoDioptres = /^[+-]00\.00$/;
        const dioptresWithNoPower = !value.includes('+') && !value.includes('-');

        if (powerWithNoDioptres.test(value)) {
          return (errorMsg = `${category} in ${
            !idx ? 'right' : 'left'
          } eye needs dioptre value. Alternatively, reset the category to remove the power notation.`);
        }

        if (dioptresWithNoPower) {
          return (errorMsg = `${category} in ${
            !idx ? 'right' : 'left'
          } eye needs power notation. Alternatively, set the dioptres to 00.00 or reset the entire category.`);
        }
      }
    }
  });
  return errorMsg;
};
