import { useState, useRef, useEffect } from 'react';
import { sendResetPasswordEmail } from '../../api/userFunctions';
import { useSnackbar } from '../../hooks';
import Snackbar from '../Misc/Snackbar';

const ForgotPassword = ({ transitionComplete }) => {
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [emailSentSuccessfully, setEmailSentSuccessfully] = useState(false);
  const forgotPasswordRef = useRef(null);
  const { showSnackbar, snackbarState } = useSnackbar();

  const componentWillExit = () => {
    forgotPasswordRef.current.style.transform = 'translateX(150%)';
    setTimeout(() => {
      forgotPasswordRef.current.ontransitionend = transitionComplete;
    }, 100);
  };

  useEffect(() => {
    forgotPasswordRef.current.style.transform = 'translateX(0)';
  }, []);

  const requestResetPasswordEmail = async () => {
    const response = await sendResetPasswordEmail(email);
    if (response.status) {
      setEmailSentSuccessfully(true);
      showSnackbar('Email sent. Check your inbox!');
    } else {
      setErrorMessage(response.email);
      document.querySelector('.forgot-password-alert').classList.remove('d-none');
      document.getElementById('send-email-btn').blur();
    }
  };

  return (
    <>
      <Snackbar showSnackbar={showSnackbar} snackbarState={snackbarState} style={{ transform: 'translateX(-50%)' }} />
      <div className='d-flex flex-column justify-content-center'>
        {emailSentSuccessfully ? (
          <>
            <div>Please check your inbox for a link to reset your password.</div>
            <span className='small text-ibis text-center' style={{ cursor: 'pointer', marginTop: '25px' }} onClick={() => window.location.reload()}>
              Back to Login
            </span>
          </>
        ) : (
          <form
            ref={forgotPasswordRef}
            style={{ transform: `translateX(150%)`, transition: 'all 0.6s ease' }}
            className='user'
            onSubmit={(e) => {
              e.preventDefault();
              requestResetPasswordEmail();
            }}
          >
            <div className='form-group' style={{ minWidth: '200px', maxWidth: '300px', width: '30vw' }}>
              <input
                type='email'
                className='form-control form-control-user email'
                id='forgot_password_email'
                placeholder='Enter your email...'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <button className='btn btn-ibis btn-user btn-block' id='send-email-btn' style={{ borderRadius: '0.35rem' }} type='submit'>
              Request Password Reset
            </button>
            <div className='alert alert-warning d-none forgot-password-alert' role='alert'>
              {errorMessage}
            </div>
            <div className='text-center' style={{ marginTop: '30px' }}>
              <button
                className='small text-ibis'
                style={{ border: 0, backgroundColor: 'transparent' }}
                onClick={() => componentWillExit()}
                type='button'
              >
                Back to Login
              </button>
            </div>
          </form>
        )}
      </div>
    </>
  );
};

export default ForgotPassword;
