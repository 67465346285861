import { formatResult } from '../../../helper/recheck';

const TestResult = ({ test, result }) => {
  const getFormattedResult = (eyeResult) => {
    if (!eyeResult?.length) {
      return 'No results';
    }
    switch (test) {
      case 'Amsler Grid':
        return eyeResult !== 'no' ? formatResult(test, true) : formatResult(test, false);
      case 'Astigmatism':
        return eyeResult !== 'no' ? formatResult('Answer', true) : formatResult('Answer', false);
      default:
        return 'No results';
    }
  };
  return (
    <table className='table table-bordered w-75'>
      <thead>
        <tr>
          <th colSpan='2' className='text-center bg-primary-ibis'>
            {test}
          </th>
        </tr>
      </thead>
      <tbody>
        {!result ? (
          <tr>
            <td colSpan={2}>No results</td>
          </tr>
        ) : (
          <>
            <tr>
              <td className='font-weight-bold w-25'>OD</td>
              <td>{getFormattedResult(result.testResultData['right-eye'])}</td>
            </tr>
            <tr>
              <td className='font-weight-bold'>OS</td>
              <td>{getFormattedResult(result.testResultData['left-eye'])}</td>
            </tr>
          </>
        )}
      </tbody>
    </table>
  );
};

export default TestResult;
