import QuestionsList from './QuestionsList';
import QuestionForm from './QuestionForm';
import EditQuestionForm from './EditQuestionForm';

const AllQuestionContent = () => {
  const clearFormErrors = () => {
    Array.from(document.querySelectorAll('.is-invalid')).forEach((el) => el.classList.remove('is-invalid'));
  };

  return (
    <>
      <div className='container-fluid'>
        <div className='d-sm-flex align-items-center justify-content-between mb-4'>
          <h1 className='h3 mb-0 text-gray-800'>&nbsp;</h1>
          <button
            className='d-none d-sm-inline-block btn btn-sm btn-ibis shadow-sm'
            type='button'
            data-toggle='modal'
            data-target='#addQuestionModal'
            onClick={clearFormErrors}
          >
            <i className='fas fa-plus fa-sm text-white-50'></i> Create a new question
          </button>
        </div>
        <QuestionsList />
      </div>
      <QuestionForm />
      <EditQuestionForm />
    </>
  );
};

export default AllQuestionContent;
