import { useCompany } from '../../context/companyContext';

const PreScreeningSelection = ({ selectedTests, setSelectedTests, prescriptionCheck, setPrescriptionCheck }) => {
  const {
    currentCompany: { enabled_tests },
  } = useCompany();
  const asyncTests = Object.values(enabled_tests)?.filter((test) => test?.async_test);

  const handleSelection = (testId) => {
    const alreadySelected = selectedTests.indexOf(testId) !== -1;
    if (alreadySelected) return setSelectedTests((prev) => prev.filter((id) => id !== testId));
    if (!alreadySelected) return setSelectedTests((prev) => [...prev, testId]);
  };

  return (
    <div className='flex flex-column form-check'>
      <input
        className='form-check-input'
        type='checkbox'
        id='prescription-checkbox'
        checked={prescriptionCheck}
        onChange={() => setPrescriptionCheck(!prescriptionCheck)}
      />
      <label className='form-check-label' htmlFor='prescription-checkbox'>
        {`Ask for patient prescription`}
      </label>
      {!asyncTests?.length
        ? null
        : asyncTests.map((test) => {
            return (
              <div key={`checkbox-${test.id}`}>
                <input
                  className='form-check-input'
                  type='checkbox'
                  id={test.key}
                  value={test.id}
                  checked={selectedTests?.includes(test.id)}
                  onChange={() => handleSelection(test.id)}
                />
                <label className='form-check-label' htmlFor={test.key}>
                  {`Pre-screen with ${test.title}`}
                </label>
              </div>
            );
          })}
    </div>
  );
};

export default PreScreeningSelection;
