import AppointmentContent from './AppointmentContent';
import { useAppointment } from '../../context/appointmentContext';
import { getAppointments } from '../../api/appointmentFunctions';
import { useEffect } from 'react';

const Appointment = () => {
  const { setAppointments } = useAppointment();

  useEffect(() => {
    getAppointments().then((response) => setAppointments(response));
  }, [setAppointments]);

  return <AppointmentContent />;
};

export default Appointment;
