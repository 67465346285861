import { Link, useParams } from 'react-router-dom';
import IbisDataTable from '../DataTable/IbisDataTable';
import { useEffect, useState } from 'react';
import { getSession } from '../../api/recheckFunctions';
import { formatActivityLogDescription, formatActivityLogTimestamp } from '../../helper/recheck';

const RecheckActivityLog = () => {
  const { sessionId } = useParams();
  const [sessionActivity, setSessionActivity] = useState([]);
  const parsedLog = sessionActivity.map((activity) => {
    const { created: dateStr, created_timestamp: timestamp, type, value } = activity;
    return {
      dateStr: formatActivityLogTimestamp(dateStr),
      timestamp,
      activity: formatActivityLogDescription(type, value),
    };
  });

  useEffect(() => {
    getSession(sessionId).then((session) => setSessionActivity(session.activity));
  }, [sessionId]);

  const columns = [
    {
      name: 'Date',
      selector: (session) => session.dateStr,
      sortable: true,
      filter_field: 'dateStr',
      sortFunction: (a, b) => a.timestamp - b.timestamp,
    },
    {
      name: 'Activity',
      selector: (session) => session.activity,
      sortable: true,
      filter_field: 'activity',
    },
  ];

  return (
    <div className='container-fluid'>
      <IbisDataTable
        title={`Recheck Session ${sessionId}`}
        columns={columns}
        data={parsedLog}
        emptyRecordsMessage={`Couldn't get the activity log for this session.`}
      />
      <Link to='/recheck/sessions'>
        <button className='btn btn-lg font-weight-bold btn-ibis' style={{ width: '150px' }}>
          Back
        </button>
      </Link>
    </div>
  );
};

export default RecheckActivityLog;
